import React, { useEffect, useState } from 'react'
import './login.scss'
import almatin_img from '../../img/almatin_group.png'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/UserSlice/userSlice';
import { useNavigate } from 'react-router-dom';
import { IoEye,IoEyeOff } from "react-icons/io5";
import { LineWave, RotatingLines } from "react-loader-spinner";
import { fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';




function Login() {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, status, error } = useSelector((state) => state.authme);

  const [jobId, setJobId] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading,setLoading]= useState(false);
  const [errorMessage, setErrorMessage] = useState('');

 const [loginSuccessed,setLoginSuccessed] = useState(false)

////////////


const [showPassword, setShowPassword] = useState(false);


useEffect(()=>{
  console.log("page is rerendering:")
},[])



const handleClickShowPassword = () => {
setShowPassword(prev =>(!prev))
};

// const handleMouseDownPassword = (event) => {
//   event.preventDefault();
// };

// const handlePasswordChange = (prop) => (event) => {
//   setValues({
//       ...values,
//       [prop]: event.target.value,
//   });
// };



////////////


const handelSuccedLogin = ()=>{
  if(loginSuccessed && status==="succeeded" ){
    navigate("/emp");
 
  }
}

// useEffect(()=>{
//   console.log("checking the authme")
//   if(loginSuccessed && status==="succeeded" ){
//     navigate("/emp");
//     setLoading(false);
//   }
// },[status,loginSuccessed])




  const handleLogin = async () => {
    console.log("the button have been clicked")
  
    console.log("the type of the pass:",password)
    console.log("the type of the jobid:",jobId)


    try {
      setLoading(true);
      const response = await axios.post('https://emp.almatingroup.com/api/V1/auth/login', {
        job_id: jobId,
        password: password
      }, {
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data) {
        // Handle successful login
        console.log('Login successful:', response.data.data);

        const userInfo = response.data.data.user;  // Adjust according to your backend response
        console.log("the userinfo is:",userInfo)
        const token = response.data.data.token;    // Adjust according to your backend response
        console.log("the token is:",token)
        dispatch(setUser({ userInfo, token ,rememberMe }));
        setLoginSuccessed(true)
        // dispatch(fetchAuthMe());
        navigate("/emp");

      }
    } catch (error) {

      setLoading(false);

      
      // Handle error
      if (error.response && (error.response.status === 401 || error.response.status === 422)) {
        setErrorMessage('بيانات الاعتماد غير صحيحة');
      } else if (error.response && error.response.data && error.response.data.message) {
        // Specific error message from the backend
        setErrorMessage(error.response.data.message);
      } else {
        // General error message
        setErrorMessage('Login failed. Please check your credentials.');
      }
      console.error('There was an error!', error);
    }
  }

  const handelChenkbox = ()=>{
    console.log("the check box is clicked the remeber me:", )
    setRememberMe(prev =>(!prev))
  }


useEffect(()=>{
  console.log("the loading is :",loading)
},[loading])




  const isButtonDisabled = !jobId || !password;


  return (
    <div className='login'>
      <div className="login_header">
        <span>تسجيل الدخول</span>
      </div>
      <div className="login_container">
        <img src={almatin_img} alt="an image about al matin" className='img_login'/>

        {
          errorMessage ? 
        <span className='login_page_errorMessage'>{errorMessage}</span>:
        <></>
        }
        
        <div className="login_input_container">
        <div className='login_input'>
          <input type="text"className='input_field_login' dir="rtl"  value={jobId} onChange={(e) => setJobId(e.target.value)} />
          <label htmlFor="">الرقم الوظيفي</label>
        </div>

        <div className='login_input'>
        {
          showPassword ?<IoEyeOff onClick={()=>handleClickShowPassword()} />
          :<IoEye  onClick={()=>handleClickShowPassword()} />

        }
        

          <input type={showPassword ? "text" : "password"   }
           className='input_field_login input_field_login_password' dir="rtl"  value={password} onChange={(e) => setPassword(e.target.value)}/>

          {/* <Input className='input_forLoginPage' dir='rtl'
                type={
                    values.showPassword
                        ? "text"
                        : "password"
                }
                onChange={handlePasswordChange("password")}
                value={values.password}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={
                                handleClickShowPassword
                            }
                            onMouseDown={
                                handleMouseDownPassword
                            }
                        >
                            {values.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            /> */}

          <label htmlFor="">كلمة المرور</label>
        </div>

        <div className='login_input_btn_container'>

        {
          loading ? 
        //   <RotatingLines
        //   strokeColor="grey"
        //   strokeWidth="5"
        //   animationDuration="0.75"
        //   width="10"
        //   ariaLabel="loading"
        //   visible={true}
        // />
        <LineWave
  
  visible={true}
  height="15rem"
  width="15rem"
  color="#4fa94d"
  ariaLabel="line-wave-loading"
  wrapperStyle={{}}
  wrapperClass="live_btn_wave"
  firstLineColor=""
  middleLineColor=""
  lastLineColor=""
  />
        : 
        <button className={(isButtonDisabled || loading)?"btn_for_login btn_for_login_disabled":"btn_for_login"}  disabled={isButtonDisabled || loading}  onClick={()=> handleLogin()}>تسجيل الدخول</button>
          }


        <div className='login_input_checkbox'> 
        <label htmlFor="">تذكرني</label>
        <input type="checkbox" onClick={()=>handelChenkbox()} />
        </div>

       </div>

        </div>


      </div>
      <div className="login_footer">
      <div className='text_footer' dir='rtl'>
      <div className='login_text_footer_name_section' dir='rtl'>
          <span dir='rtl'>تصميم و برمجة م. صالح الحايك و م.علاء الدين عاصي </span>
          <span dir='rtl'>من قسم التقانة لمجموعة المتين</span>
          </div>

          <span><a href="#">Almatin.com</a>&copy; 2024</span>

        </div>
      </div>
    </div>
  )
}

export default Login