import React, { useState } from 'react'
import { BsCloudPlusFill } from "react-icons/bs";
import './uploadFilesAddEmps.scss'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { Spa } from '@mui/icons-material';

function UploadFilesAddEmps() {

  const token = useSelector(state => state.user.token);

  const [file, setFile] = useState(null);

  const [fileName, setFileName] = useState('');

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  
  const [missingHeaders, setMissingHeaders] = useState([]);

  const [requiredHeaders, setRequiredHeaders] = useState([]);

  const [successUpload,setSuccessUpload] = useState(false)

  let handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
      setError(null);
      setSuccessUpload(false)
    }
  };




  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      setSuccessUpload(false)
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await axios.post(
        'https://emp.almatingroup.com/api/V1/users/import/excel',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Handle successful response if needed
      setLoading(false);
      setSuccessUpload(true)
      console.log(response.data);
    } catch (error) {
      setLoading(false);

      if (axios.isAxiosError(error) && error.response) { 
        const statusCode = error.response.status;
        const errorData = error.response.data?.errors;
        
        if (statusCode === 500) {
          // Handle Internal Server Error (500)
          setError("هناك خطأ في الخادم");
        } else if (statusCode === 422) {
          // Handle validation errors
          console.error("Axios error: ", error.response.data);
          setError("خطأ في ملف الاكسل : تأكد من تضمين الاعمدة المفقودة في الاسفل");
          setMissingHeaders(errorData.missing_headers || []);
          setRequiredHeaders(errorData.required_headers || []);
        } else {
          // Handle other types of errors
          setError("An unexpected error occurred. Please try again.");
        }
      } else {
        console.error("Unexpected error: ", error);
        setError("An unexpected error occurred. Please try again.");
      }
      setSuccessUpload(false)
    }
  };




  return (
    <div className="uploadFiles_addemps">
    <form  className='uploadFiles_container_form' onSubmit={handleUpload}>
    <div className="uploadFiles_container_form_uploadInfo">

      <div className='uploadFiles_container_form_title'>
      <span className='uploadFiles_container_form_title_content' dir="rtl">اضافة موظفين</span>
      </div>

      <span className='uploadFiles_container_form_note' dir="rtl">أقصى حجم ممكن 2 ميغا</span>

    <div className='uploadFiles_container_form_showFiles_container'>
     
        <div className='uploadFiles_container_form_showFiles_container_icon'>
        <label htmlFor="write-enter-files" className="uploadFiles_container_form_UploadIcon_contaienr"><BsCloudPlusFill className='uploadFiles_container_form_UploadIcon' /></label>
        <input 
        type="file" 
        className="write-input-files"
         id='write-enter-files'
          style={{display:"none"}}
          onChange={(e)=>{handleFileChange(e)}}
          />
        </div>
       
       <span dir="rtl">{fileName ? fileName : 'اضغط لعرض ملفاتك'}</span>

       </div>

    </div>
      <button type='submit' className='uploadFiles_container_form_btn' dir="rtl"  disabled={loading}>
      {loading ? (
        <>
        <TailSpin
          visible={true}
          height="26"
          width="26"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <span dir='rtl' style={{color:"white" , marginRight:".4rem"}}>يتم الرفع الان...</span>
        </>
      )
    :
    <>رفع الملف و تحديث البيانات</>
    }
        
    </button>


     {
      successUpload &&
      <span style={{color:"green"}}>تم رفم الملف بنجاح </span>
     }

      {error && (
        <div className='error_part_ofThePage'>
          <p dir='rtl' style={{color:"red"}}>{error}</p>
          {missingHeaders.length > 0 && (
            <div>
              <h4 dir='rtl' >الاعمدة المفقودة</h4>
              <ul dir='rtl'>
                {missingHeaders.map((header, index) => (
                  <li key={index} dir='rtl'>{header}</li>
                ))}
              </ul>
            </div>
          )}

        </div>
      )}

    </form>
    
    </div>
  )
}

export default UploadFilesAddEmps