import React, { useEffect, useState } from 'react'

import "./empInfoPersonalData.scss"
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BsPersonCheck } from 'react-icons/bs';

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';
import {addToCreateUserData, createUserCompNum} from '../../../store/CreateUserSlice/createUserSlice'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";




function EmpInfoPersonalData() {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)





    const CreateUserData = useSelector(state => state.createUser.userData);

    const CreateCompNum = useSelector(state => state.createUser.compNum);


  
  const [fields,setFields] = useState({
    job_id : CreateUserData.job_id ?  CreateUserData.job_id : "",
    name : CreateUserData.name ?  CreateUserData.name : "",
    password : CreateUserData.password ?  CreateUserData.password : "",
    address : CreateUserData.address ?  CreateUserData.address : "",
    father_name : CreateUserData.father_name ?  CreateUserData.father_name : "",
    mother_name: CreateUserData.mother_name ?  CreateUserData.mother_name : "",
    national_id : CreateUserData.national_id ?  CreateUserData.national_id : "",
    birth_place : CreateUserData.birth_place ?  CreateUserData.birth_place : "",
    birth_date: CreateUserData.birth_date ?  CreateUserData.birth_date : "",
    gender: CreateUserData.gender ?  CreateUserData.gender : "MALE",
    blood_type: CreateUserData.blood_type ?  CreateUserData.blood_type : "A+",
    constraint: CreateUserData.constraint ?  CreateUserData.constraint : "",
    passport_number: CreateUserData.passport_number ?  CreateUserData.passport_number : "",
    marital_status : CreateUserData.marital_status ?  CreateUserData.marital_status : "UNKNOWN",
    kids_count : CreateUserData.kids_count ?  CreateUserData.kids_count : 0,
    religion :  CreateUserData.religion ?  CreateUserData.religion : "",
    nationality: CreateUserData.nationality ?  CreateUserData.nationality : "",
    civilian_record : CreateUserData.civilian_record ?  CreateUserData.civilian_record : "",
    military_service :  CreateUserData.military_service ?  CreateUserData.military_service : "UNKNOWN",
    qualification :  CreateUserData.qualification ?  CreateUserData.qualification : "",
  })




  const [loading,setLoading] = useState(false)
 
  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)

  const [error,setError] = useState(null)




 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };




  const handelMoveForword = (e)=>{
    e.preventDefault()
    if(fields.job_id === "" || fields.name === "" || fields.password === "" ||
      fields.national_id === "" || fields.nationality === "" || fields.marital_status === "" ||
     fields.religion === "" || fields.civilian_record === "" ||  fields.military_service === "" 
     || fields.kids_count === "" 
     ){
      console.log("the data is ",fields)
      setError("*يرجى التأكد من ملئ جميع الحقول الرفقة ب *")
     }
     else{
    console.log("the comp nuber is =",CreateCompNum)
   dispatch(addToCreateUserData(fields))
   dispatch(createUserCompNum(CreateCompNum + 1))
     }
    }
 
 
  const handelMoveBackword = (e)=>{
   e.preventDefault()
  dispatch(createUserCompNum(CreateCompNum - 1))
 }
 



    return (
    <div className='EmpInfoPersonalData_CreateNewUser'>



    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }


    <form onSubmit={handelMoveForword}>
        
   <div className="EmpsMangment_search_result_table_component_items">


   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الاسم <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.name} name='name' onChange={handleChange}/>
      </div>


   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الرقم الوظيفي <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl"  value={fields.job_id} name='job_id' onChange={handleChange} />
      </div>



   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">العنوان</label>
      <input type="text" dir="rtl"  value={fields.address} name='address' onChange={handleChange} />
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">كلمة المرور <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.password} name='password' onChange={handleChange}/>
      </div>

   

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الجواز</label>
      <input type="text" dir="rtl" value={fields.passport_number} name='passport_number' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الرقم الوطني <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.national_id} name='national_id' onChange={handleChange}/>
      </div>




      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">اسم الأب</label>
      <input type="text" dir="rtl" value={fields.father_name} name='father_name' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">اسم الأم</label>
      <input type="text" dir="rtl" value={fields.mother_name} name='mother_name' onChange={handleChange}/>
      </div>


     {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">زمرة الدم</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.blood_type} name='blood_type' onChange={handleChange}>
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="A+" dir="rtl">A+</option>
      <option value="A-" dir="rtl">A-</option>
      <option value="B+" dir="rtl">B+</option>
      <option value="B-" dir="rtl">B-</option>
      <option value="O+" dir="rtl">O+</option>
      <option value="O-" dir="rtl">O-</option>
      <option value="AB+" dir="rtl">AB+</option>
      <option value="AB-" dir="rtl">AB-</option>
     </select>
      </div>



     {/* need to be changed ......................... ..........*/}
     <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الجنس </label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.gender} name="gender" onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="MALE" dir="rtl">ذكر</option>
      <option value="FEMALE" dir="rtl">أنثى</option>
     </select>
      </div>




      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">مكان الولادة</label>
      <input type="text" dir="rtl" value={fields.birth_place} name='birth_place' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تاريخ الولادة</label>
      <input type="date" dir="rtl" value={fields.birth_date} name='birth_date' onChange={handleChange} />
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">سجل القيد</label>
      <input type="text" dir="rtl" value={fields.constraint} name='constraint' onChange={handleChange}/>
      </div>

      
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الجنسية<span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.nationality} name='nationality' onChange={handleChange}/>
      </div>
    
    
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">عدد الأولاد <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.kids_count} name='kids_count' onChange={handleChange}/>
      </div>

        {/* need to be changed ......................... ..........*/}
     <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الوضع الاجتماعي <span className='important_toFill'>*</span></label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.marital_status} name='marital_status' onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="SINGLE" dir="rtl">أعزب</option>
      <option value="MARRIED" dir="rtl">متزوج</option>
      <option value="DIVORCED" dir="rtl">مطلق</option>
      <option value="WIDOWED" dir="rtl">أرمل</option>

     </select>
      </div>

        {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الديانة <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.religion} name='religion' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">سجل مدني <span className='important_toFill'>*</span></label>
      <input type="text" dir="rtl" value={fields.civilian_record} name='civilian_record' onChange={handleChange}/>
      </div>

      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">شعبة التجنيد</label>
      <input type="text" dir="rtl" value={militrayPlaceField} onChange={(e)=>{setMilitrayPlaceField(e.target.value)}}/>
      </div> */}

      {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">خدمة العلم <span className='important_toFill'>*</span></label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.military_service} name='military_service' onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="PERFORMER" dir="rtl">مؤدي</option>
      <option value="EXEMPT" dir="rtl">معفي</option>
      <option value="ADMINISTRATIVE_POSTPONEMENT" dir="rtl">مؤجل اداري</option>
      <option value="STUDY_POSTPONEMENT" dir="rtl">مؤجل دراسيا</option>
      <option value="BREADWINNER_POSTPONEMENT" dir="rtl">مؤجل معيل</option>
      <option value="TEMPORARY_SOLE_PROVIDER" dir="rtl">وحيد مؤقت</option>
      <option value="PERMANENT_SOLE_PROVIDER" dir="rtl">وحيد دائم</option>
      <option value="UNDER_LEGAL_AGE" dir="rtl">تحت السن القانوني</option>
      <option value="CASH_REPLACEMENT" dir="rtl">بدل نقدي</option>

     </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">المؤهلات</label>
      <input type="text" dir="rtl" value={fields.qualification} name='qualification' onChange={handleChange} />
      </div>


      
      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صلاحية الموظف</label>
      <select dir="rtl" className='EmpsMangment_selection' value={empPermissionField} onChange={(e)=>{setEmpPermissionField(e.target.value)}} >
      <option value="Manger_Developer" dir="rtl">مطور النظام</option>
      <option value="General_Adminstration" dir="rtl">ادارة عامة</option>
      <option value="Head_Department_Hr" dir="rtl">رئيس قسم -Hr</option>
      <option value="Head_Department_IT" dir="rtl">رئيس قسم -IT</option>
      <option value="Head_Department" dir="rtl">رئيس قسم</option>
      <option value="Following_Admistrator" dir="rtl">مسؤول متابعة -Hr</option>
      <option value="Evaluation_Adminstartor" dir="rtl">مسؤول تقييم -Hr</option>
      <option value="Salary_Adminstartor" dir="rtl">مسؤول رواتب -Hr</option>
      <option value="Employing_Adminstrator" dir="rtl">مسؤول توظيف -Hr</option>
      <option value="Employee" dir="rtl">موظف</option>
     </select>
      </div> */}



    </div>

    {error &&  
      
      <span className='error_in_saving' style={{ color: 'red', marginRight: '3rem', marginTop: '1rem' }}  dir='rtl'>{error}</span>
      }


   <div className='buttons_toMoveBackAndForNext'>

   {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>
          <button dir="rtl" type='submit' className='forNextButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveForword(e)}} disabled={loading}>التالي <FaAngleLeft className='buttons_toMoveBackAndForNext_button_icon' /></button>
          <button dir="rtl" type='submit' className={ CreateCompNum === 1 ? 'BackButton buttons_toMoveBackAndForNext_button disabled_button' : 'BackButton buttons_toMoveBackAndForNext_button'} onClick={(e)=>{handelMoveBackword(e)}} disabled={loading || CreateCompNum === 1}>السابق <FaAngleRight className='buttons_toMoveBackAndForNext_button_icon' /></button>
       
        </>
        }
  

    </div>
   </form>
    </div>
  )
}

export default EmpInfoPersonalData