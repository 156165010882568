import React, { useEffect, useState } from 'react'

import './managmentDepartments.scss'
import Navbar from '../../../components/Navbar/Navbar'
import { FaPlus } from "react-icons/fa";

import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {  TailSpin } from 'react-loader-spinner';


import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';

import {errorHandeling} from "../../../errorHandeling"

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

function ManagmentDepartments() {

  const dispatch = useDispatch();

  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);

  const authMeStatus = useSelector(state => state.authme.status);


  const [depsArr,setDepsArr] = useState([])
  const [loadingResult,setLoadingResult] = useState(false)

  const [error,setError] = useState(null)



  useEffect(() => {
    if (authMeStatus === 'idle') {
      dispatch(fetchAuthMe());
    }
    else if (authMeStatus === 'failed') {
      setError("*خطأ في تحميل البيانات");
    }
  }, [dispatch, authMeStatus]);



   //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};




      useEffect(() => {
    
        const fetchDepartments = async()=> {
          try {
            setLoadingResult(true);
            const response = await axios.get(
              'https://emp.almatingroup.com/api/V1/departments',
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response:', response.data.data.data);
            setDepsArr(response.data.data.data)
            setLoadingResult(false);
            
            if(response.data.data.data.length === 0){
              setError("*لايوجد بيانات لعرضها")
            }
            else{
            setError(null)
            }

          } catch (error) {
            console.error('Error:', error);
            setLoadingResult(false);
            setError(errorHandeling(error))
          }
    
      };
    
        if(authMeStatus==="succeeded"){
            fetchDepartments();
        }

     }, [token,authMeStatus]);
    





     const navigate = useNavigate();

     let handelUpdating = (id)=>{
      if(checkPermissionAndThrow("update_departments")){
      navigate(`/emp/update_dep/${id}`);
       }
     }




     
  const  handelSearchChange = async(e)=>{

    console.log("the event is :",typeof (e.target.value))
    let searchVal = e.target.value

    
    if(searchVal && searchVal !== ""){
      try {
        setLoadingResult(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/departments/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data);
        setDepsArr(response.data.data.data)
        setLoadingResult(false);
        if(response.data.data.data.length === 0){
          setError("*لايوجد بيانات لعرضها")
        }
        else{
        setError(null)
        }

      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(errorHandeling(error))
      }
  
    }
    else{
      try {
        setLoadingResult(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/departments',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data);
        setDepsArr(response.data.data.data)
        setLoadingResult(false);

        if(response.data.data.data.length === 0){
          setError("*لايوجد بيانات لعرضها")
        }
        else{
        setError(null)
        }

      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(errorHandeling(error))
      }
    }

  
  }

  // useEffect(()=>{
  //   if(depsSearchArr.length === 0){
  //     setDepsArr(depsAllArr)
  //   }
  //   else{
  //     setDepsArr(depsSearchArr)
  //   }
  // },[depsSearchArr,depsAllArr])


  return (
    <div className='ManagmentDepartments'>
        <Navbar/>

        <div className='ManagmentDepartments_container'>
            <div className='ManagmentDepartments_container_top_part'>

              { //you have the premission to create a department

                checkPermissionAndThrow("create_departments") &&
                <Link to='/emp/create_dep'  dir="rtl"><FaPlus className='ManagmentDepartments_container_bottom_part_createbButtontext' /></Link>   
              }
       
                 <div className='ManagmentDepartments_container_top_part_searchBar'>
                    <input type="text" placeholder='ابحث عن قسم ما' dir="rtl"  onChange={(e)=>{handelSearchChange(e)}}/>
                 </div>
                <span className='ManagmentDepartments_container_top_part_header' dir="rtl">ادارة الاقسام</span>
            </div>
            <div className='ManagmentDepartments_container_bottom_part'>


          
            {
                  loadingResult ?
                  <>
                 <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                  </>
                  :

                  (error ?
                    <>
                     <span className='error_in_saving' style={{ color: 'red'} } dir='rtl'>{error}</span>
                    </>
                    :
                    <>
                
                <table className='VacationsForTodayAccepted_table'>
                  <thead>
                 <tr className='VacationsForTodayAccepted_header_row_table'>
                    <th>عدد الموظفين</th>
                    <th>مدير القسم</th>
                    <th>اسم القسم</th>              
                 </tr>
                 </thead>

                <tbody>
                 { depsArr.map(
                   obj =>(
                  <tr key={obj.id}  className='ManagmentDepartments_container_bottom_part_rowTable' onClick={()=>handelUpdating(obj.id)}>
                  <td dir="rtl">{obj.employees_count || 0}</td>
                  <td dir="rtl">{obj.manager?.name || ""}</td>
                  <td dir="rtl">{obj.title || ""}</td>
               </tr>
               )
                 )}
                   </tbody>

            </table>

                  </>

             
                  )
                  
                  }


            </div>

        </div>

    </div>
  )
}

export default ManagmentDepartments