import React from 'react'

import './cards.scss'


import { Link } from 'react-router-dom'
import { IoIosPeople } from 'react-icons/io'
import { IoPeople } from 'react-icons/io5'


function Cards({ cardTitle,requestsNum,requestPageLink,requestNumSuffix}) {
  return (
    <div className='CardsPage'>
    <div className="container">
    <div className="card__container">
       <article className="card__article">
         <span className='card__article_header' dir='rtl'>{cardTitle}</span>
          {/* <img src={Landscape1} alt="image" class="card__img"/> */}
          <IoPeople   className='card__img' />

          <div className="card__data" dir='rtl'>
             <span className="card__description" dir='rtl'>لديك</span>
             <h2 className="card__title" dir='rtl'>{requestsNum} {requestNumSuffix}</h2>
             <Link to={requestPageLink} className="card__button"  dir='rtl'>عرض الطلبات</Link>
          </div>
       </article>

    </div>
    </div>
    </div>
  )
}

export default Cards