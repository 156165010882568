import './App.css';
// import Footer from './components/Footer/Footer';
// import Navbar from './components/Navbar/Navbar';
import ChangePass from './pages/ChangePass/ChangePass';
import DepartmentEmps from './pages/DepartmentEmps/DepartmentEmps';
import EmpsMangment from './pages/EmpsMangment/EmpsMangment';
// import Login from './pages/Login/Login';
import MainPage  from './pages/Main_page/MainPage';
import { createBrowserRouter,RouterProvider,Navigate  } from 'react-router-dom';
import UploadFiles from './pages/UploadFiles/UploadFiles';
import SalaryPage from './pages/SalaryPage/SalaryPage';
import VacationRequestEmp from './pages/VacationRequestEmp/VacationRequestEmp';
import VacationManagerShow from './pages/VacationManagerShow/VacationManagerShow';
import VacationStatistics from './pages/VacationStatistics/VacationStatistics';
import EvaluationForEmployeeShowPage from './pages/EvaluationForEmployeeShowPage/EvaluationForEmployeeShowPage';


import ManagmentDepartments from './pages/MangeDepartmentsAllPagesRelated/ManagementDepartments/ManagmentDepartments';
import UpdateAnOldDepatment from './pages/MangeDepartmentsAllPagesRelated/UpdateAnOldDepartment/UpdateAnOldDepatment';
import CreateNewDepartment from './pages/MangeDepartmentsAllPagesRelated/CreateNewDepartment/CreateNewDepartment';
import MangmentJobPositions from './pages/MangeJobPositionsAllRelatedPages/MangmentJobPositions/MangmentJobPositions';
import CreateNewJobPosition from './pages/MangeJobPositionsAllRelatedPages/CreateNewJobPosition/CreateNewJobPosition';
import UpdateAnOldJobPosition from './pages/MangeJobPositionsAllRelatedPages/UpdateAnOldJobPosition/UpdateAnOldJobPosition';
import NoficationsPage from './pages/NoficationsPage/NoficationsPage';
import ManagmentPunshmentsByHRs from './pages/ManagmentPunshmentsByHRs/ManagmentPunshmentsByHRs';
import Login from './pages/Login/Login';
import CheckPrivateRouteAuth from './components/CheckPrivateRouteAuth/CheckPrivateRouteAuth';
import MainLayout from './Layouts/MainLayout';
import ManageRoles from './pages/ManageRoles/ManageRoles';
import PermissionWrapper from './checkPermissionsForPages';
import IsManagerWrapper from './checkIfUserIsManager';
import Unautherized from './pages/Unautherized/Unautherized';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthMe } from './store/AuthmeSlice/authmeSlice';
import { useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import AddNewPunshment from './components/PunshmentsComps/AddNewPunshment/AddNewPunshment';
import CreateNewUser from './pages/CreateNewUser/CreateNewUser';
import CompInternalNums from './pages/CompPhoneNumsManagment/CompInternalNumsComponents/CompInternalNums/CompInternalNums';
import CreateNewCompanyInternalNum from './pages/CompPhoneNumsManagment/CompInternalNumsComponents/CreateNewCompanyInternalNum/CreateNewCompanyInternalNum';
import ChangeCompanyInternalNum from './pages/CompPhoneNumsManagment/CompInternalNumsComponents/ChangeCompanyInternalNum/ChangeCompanyInternalNum';
import EvaluationEmpFromManager from './pages/EvaluationManagmentAllPagesRelated/EvaluationEmpFromManagerPages/EvaluationEmpFromManager/EvaluationEmpFromManager'
import EvaluateDraftPageForSixMonthes from './pages/EvaluationManagmentAllPagesRelated/EvaluationManagmentDraftsPages/EvaluateDraftPageForSixMonthes/EvaluateDraftPageForSixMonthes'
import EvaluationRequestsWatingforEvaluationofficer from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingforEvaluationofficer/EvaluationRequestsWatingforEvaluationofficer';
import EvaluationStatisticsMainPageShow from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationStatisticsMainPageShow/EvaluationStatisticsMainPageShow';
import EmpsWhoHaveBeenEvaluatedPage from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EmpsWhoHaveBeenEvaluatedPage/EmpsWhoHaveBeenEvaluatedPage';
import EvaluationRequestsWatingTheManagersDep from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingTheManagersDep/EvaluationRequestsWatingTheManagersDep';
import EvaluationRequestsReturnedBackToTheManagersDep from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsReturnedBackToTheManagersDep/EvaluationRequestsReturnedBackToTheManagersDep';
import EvaluationRequestsReturnedBackToTheEvaluationofficer from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsReturnedBackToTheEvaluationofficer/EvaluationRequestsReturnedBackToTheEvaluationofficer';
import EvaluationForHRDraftPage from './pages/EvaluationManagmentAllPagesRelated/EvaluationManagmentDraftsPages/EvaluationForHRDraftPage/EvaluationForHRDraftPage';
import EvaluationDraftMainComp from './pages/EvaluationManagmentAllPagesRelated/EvaluationManagmentDraftsPages/EvaluationDraftMainComp/EvaluationDraftMainComp';
import EvaluationSettingsManagment from './pages/EvaluationSettingsManagment/EvaluationSettingsManagment';
import EvaluationRequestsWatingforHRManager from './pages/EvaluationManagmentAllPagesRelated/EvaluationManageStatistics/EvaluationRequestsWatingforHRManager/EvaluationRequestsWatingforHRManager';
import EvaluationStartForTestPeriodEmps from './pages/EvaluationManagmentAllPagesRelated/EvaluationStartForTestPeriodEmps/EvaluationStartForTestPeriodEmps';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/emp" />
  },
  {
    path: '/emp',
    element: <MainLayout />,
  //   errorElement: <Error />,
    children: [
      {
        index: true,
        element:(
          <CheckPrivateRouteAuth>
          <MainPage />
        </CheckPrivateRouteAuth>
        )
      },
       {
        path: 'empmang',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_users']}>
            <EmpsMangment />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'change_pass',
        element: (
        <CheckPrivateRouteAuth>
            <ChangePass />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'uploadfiles',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['update_penalties', 'update_insurances', 'update_users']}>
            <UploadFiles />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'departmentemps',
        element: (
        <CheckPrivateRouteAuth>
          <IsManagerWrapper>
             <DepartmentEmps />
          </IsManagerWrapper>
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'salarypage',
        element: (
        <CheckPrivateRouteAuth>
           <SalaryPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'vacationreq',
        element: (
        <CheckPrivateRouteAuth>
             <VacationRequestEmp />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'vacationmang',
        element: (
          <CheckPrivateRouteAuth>
           <IsManagerWrapper>
              <VacationManagerShow />
           </IsManagerWrapper>
         </CheckPrivateRouteAuth>

        )
      },
      {
        path: 'vaca_stat',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_vacations']}>
             <VacationStatistics />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'eval_emp',
        element: (
        <CheckPrivateRouteAuth>
               <EvaluationForEmployeeShowPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'mang_deps',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_departments']}>
              <ManagmentDepartments />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_dep',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_departments']}>
              <CreateNewDepartment />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_dep/:id',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['update_departments']}>
               <UpdateAnOldDepatment />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'mang_job_positions',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_job_positions']}>
                <MangmentJobPositions />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_job_position',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_job_positions']}>
               <CreateNewJobPosition />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_job_position/:id',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['update_job_positions']}>
              <UpdateAnOldJobPosition />
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'nofications',
        element: (
        <CheckPrivateRouteAuth>
                <NoficationsPage />
       </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'manage_roles',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_roles']}>
                 <ManageRoles/> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'punshments_hr',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_penalties']}>
                <ManagmentPunshmentsByHRs /> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },

      {
        path: 'addPunshment',
        element: (
          <CheckPrivateRouteAuth>
           <PermissionWrapper requiredPermissions={['create_penalties']}>
                <AddNewPunshment /> 
           </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'addNewUser',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['create_users']}>
                <CreateNewUser /> 
          </PermissionWrapper>
        </CheckPrivateRouteAuth>
        )
      },
      ,
      {
        path: 'compInternalNums',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['read_penalties']}>
                <CompInternalNums /> 
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'create_internalNum',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['read_penalties']}>
                <CreateNewCompanyInternalNum /> 
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth>
        )
      },
      {
        path: 'update_interalNum/:id',
        element: (
          // <CheckPrivateRouteAuth>
          // <PermissionWrapper requiredPermissions={['update_job_positions']}>
              <ChangeCompanyInternalNum />
        //   </PermissionWrapper>
        // </CheckPrivateRouteAuth> 
        )
      },
      {
        path: 'eval_emp_manager',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluations']}>
              <EvaluationEmpFromManager />
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        )
      },
      {
        path: 'eval_emp_draft',
        element: (
        <CheckPrivateRouteAuth>
           <PermissionWrapper requiredPermissions={['create_evaluations']}>
              <EvaluationDraftMainComp />
          </PermissionWrapper>
      </CheckPrivateRouteAuth> 

        )
      },
      {
        path: 'eval_stat_main_page_show',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationStatisticsMainPageShow />
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },

      {
        path: 'eval_stat_evalOfficer',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationRequestsWatingforEvaluationofficer />
          </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_evalOfficer_returnedBack',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationRequestsReturnedBackToTheEvaluationofficer />
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_emps_evaluated_before',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EmpsWhoHaveBeenEvaluatedPage />
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4manger',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationRequestsWatingTheManagersDep />
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4manger_returnedBack',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationRequestsReturnedBackToTheManagersDep />
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_stat_requests4HRmanager',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationRequestsWatingforHRManager />
           </PermissionWrapper>
         </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_settings',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationSettingsManagment />
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },
      {
        path: 'eval_start_testperiod_requests',
        element: (
          <CheckPrivateRouteAuth>
          <PermissionWrapper requiredPermissions={['read_evaluation_settings']}>
              <EvaluationStartForTestPeriodEmps />
          </PermissionWrapper>
        </CheckPrivateRouteAuth> 
        
        )
      },

    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/not_authorized',
    element: <Unautherized/> // Or a proper "Not Authorized" component
  }
  // {
  //   path: '/register',
  //   element: <Register />
  // }
]);




function App() {

  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const { status, error } = useSelector((state) => state.authme);

  const state = localStorage.getItem('state');

  useEffect(() => {

      // dispatch(fetchAuthMe());
      // console.log("fetching the auth me")
    
  }, [dispatch, state]);

  // useEffect(() => {
  //   if (!state) {
  //     console.log('there is no state');
  //     navigate('/login');
  //   }
  // }, [state, navigate]);

  // if (status === "loading") {
  //   console.log("loading from the app.js")
  //   return  (<div  
  //            style={{
  //              display: 'flex',
  //              flexDirection: 'column',
  //              alignItems: 'center',
  //              justifyContent: 'center',

  //             }}>
  //            <TailSpin
  //            visible={true}
  //            height="40"
  //            width="40"
  //             color="rgb(246,229,56)"
  //            ariaLabel="tail-spin-loading"
  //            radius="1"
  //            wrapperStyle={{}}
  //            wrapperClass=""
  //            />
  //             </div>);
  // }




  
  // if(status === "failed" || status === "succeeded" ){
  return (
    <div className="App">

   {console.log("the status now is ",status)}
   <RouterProvider router={router} />

    </div>
  );
// }

}

export default App;
