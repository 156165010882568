import axios from "axios";

export const errorHandeling = (error)=>{
    if (axios.isAxiosError(error) && error.response) { 
        const statusCode = error.response.status;

        
        if (statusCode === 500) {
          // Handle Internal Server Error (500)
          return("هناك خطأ في الخادم");
        } else if (statusCode === 422) {
          // Handle validation errors
          console.error("Axios error: ", error.response.data);
          return("*خطأ في البيانات المرسلة");
  
        } else {
          // Handle other types of errors
          return("*خطأ غير متوقع يرجى المحاولة لاحقا");
        }
      } else {
        console.error("Unexpected error: ", error);
        return("*خطأ غير متوقع يرجى المحاولة لاحقا");
      }

}


