import { useState } from "react";
import "./evaluationDraftSixMonthesChange.scss"
import { useEffect } from "react";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import extractDataForTabelFromTheObject from "../../../../pages/EvaluationManagmentAllPagesRelated/EvaluationManagmentDraftsPages/extractDataForTabelFromTheObject";
import { useSelector } from "react-redux";
import SuccessedMessage from "../../../SuccessedMessage/SuccessedMessage";



const EvaluationDraftSixMonthesChange = ({tableLabels}) => {


const token = useSelector(state => state.user.token)

const [rows , setRows ] = useState([]);

const [loading , setLoading] = useState(false);

const [error , setError] = useState(false);

const [tabelId , setTabelId ] = useState(null);

const [containerClickedAccordion ,setContainerClickedAccordion] = useState(false)

const [marksTotalError, setMarksTotalError] = useState(null);

const [savingSuccessfully , setSavingSuccessfully ] = useState(false);


useEffect(()=>{
  const setDataForTable = ()=>{
  //get the semiAnnual table labels 
  let semiAnnual = tableLabels.find(item => item.type === "SEMI_ANNUAL");

  // console.log("the semmi annual is :",semiAnnual);

  setTabelId(semiAnnual.id);

  setRows(extractDataForTabelFromTheObject(semiAnnual));

 
  }

  if(tableLabels){
    setDataForTable();
  }



},[tableLabels])





  // Helper function to get the ordinal suffix for keys
  const ordinalSuffix = (i) => {
    const suffixes = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth"];
    return suffixes[i - 1];
  };

  // Handle change in any input field
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [name]: value,
    };
    setRows(updatedRows);
  };

  // Add a new row by finding the first missing entry
  const addNewRow = () => {
    const newRows = [...rows];
    const nextId = rows.length + 1;

    if (nextId <= 10) {
      newRows.push({
        id: nextId,
        title: '',
        description: '',
        mark: '',
      });
      setRows(newRows);
    }
  };

  // Handle row deletion and shifting remaining rows up
  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);

    // Adjust IDs after deletion
    for (let i = index; i < updatedRows.length; i++) {
      updatedRows[i].id = i + 1;
    }

    setRows(updatedRows);
  };


  //check if there any field in the row is empty 
  const validateForm = () => {
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (!row.title || !row.description || !row.mark) {
            return false;
        }
    }
    return true;
   };




  // Save changes to the backend
  const saveChanges = async () => {

    setError(null);
    setMarksTotalError(null);
    setSavingSuccessfully(false);

 // Check if the form is valid
 if (!validateForm()) {
  setError("*تأكد من ملىء جميع الحقول قبل الحفظ");
  console.log("we have an error")
  return;
}


    if(tabelId) { 

    const payload = {
      id : tabelId , 
      type : "SEMI_ANNUAL" ,
    };


  // Iterate through rows and add to payload, sending null for empty fields

  rows.forEach((row, index) => {
    const keyIndex = ordinalSuffix(index + 1);

    payload[`${keyIndex}_title`] = row.title || null;
    payload[`${keyIndex}_description`] = row.description || null;
    payload[`${keyIndex}_mark`] = row.mark || null;
  });


  
  for(let i = rows.length ; i<10 ; i++){

    const keyIndex = ordinalSuffix(i + 1);

    payload[`${keyIndex}_title`] =  null;
    payload[`${keyIndex}_description`] =  null;
    payload[`${keyIndex}_mark`] = null;

  }


   
    try {
      setLoading(true);
      const response = await axios.put(
        `https://emp.almatingroup.com/api/V1/evaluation-labels/${tabelId}`,
        
        payload
        ,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      setSavingSuccessfully(true);
      setError(null);
    } catch (error) {
      setLoading(false);
      setSavingSuccessfully(false);
      setError(error.message);

      // Check if the error response contains the 'marks_total' error
      if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          if (errors.marks_total) {
              setMarksTotalError(errors.marks_total[0]);
          }
      }
  }


    console.log("we are submiting some data for rows : ",payload)
  };

  }


  return (
    <div className="EvaluationDraftSixMonthesChange">
        <span className="EvaluationDraftSixMonthesChange_sub_header" dir="rtl" onClick={()=>{setContainerClickedAccordion(prev => !prev)}}>1- نموذح تقييم ال 6 أشهر:</span>
    
      <div className={containerClickedAccordion ? "EvaluationDraftSixMonthesChange_container EvaluationDraftSixMonthesChange_container_clicked_inAccordion" : "EvaluationDraftSixMonthesChange_container"} >
        
        <div className="EvaluationDraftSixMonthesChange_rows">

          {
            rows.map((row , index)=>(

              <div className="EvaluationDraftSixMonthesChange_row" key={index}>

              <span className="EvaluationDraftSixMonthesChange_row_header" dir="rtl">الحقل {index+1} :</span>
           
           <div className="EvaluationDraftSixMonthesChange_row_items">
            
  

           <div className="EvaluationDraftSixMonthesChange_row_item">
             <span className="EvaluationDraftSixMonthesChange_row_item_label" dir="rtl">الوصف الخاص بالحقل :</span>
             <textarea className="EvaluationDraftSixMonthesChange_row_item_textarea" 
             rows={5}  color={6}  dir="rtl"
             name="description"
             value={row.description}
             onChange={(event) => handleInputChange(index, event)} ></textarea>
          </div>


          <div className="EvaluationDraftSixMonthesChange_row_item">
             <span className="EvaluationDraftSixMonthesChange_row_item_label" dir="rtl"> الدرجة العظمى : </span>
             <input type="number" min={0} className="EvaluationDraftSixMonthesChange_row_item_input" 
             dir="rtl" 
             name="mark"
             value={row.mark}
             onChange={(event) => handleInputChange(index, event)} />
          </div>

          <div className="EvaluationDraftSixMonthesChange_row_item">
             <span className="EvaluationDraftSixMonthesChange_row_item_label" dir="rtl">اسم الحقل : </span>
             <input type="text" className="EvaluationDraftSixMonthesChange_row_item_input"
              dir="rtl"
              name="title"
              value={row.title}
              onChange={(event) => handleInputChange(index, event)} />
          </div>

           </div>

           <button onClick={() => deleteRow(index)} className="EvaluationDraftSixMonthesChange_row_tash"><FaTrash className="EvaluationDraftSixMonthesChange_row_tash_icon" /></button>

          </div>

              
            ))
          }


        </div>

        <button onClick={addNewRow} disabled={rows.length >= 10} className="EvaluationDraftSixMonthesChange_add_newRow_button" dir="rtl" >اضافة سطر جديد</button>
       
        {
          error &&
          (
      
          marksTotalError ? (
                 <span dir="rtl" style={{color:"red"}}>{marksTotalError}</span>
         )  
          :
          (
            <span dir="rtl" style={{color:"red"}}>{error}</span>
          )
        )  
        }

        {
          savingSuccessfully &&
          <SuccessedMessage/>
        }


       <button className={loading ? "EvaluationDraftSixMonthesChange_submit_button button_saving_evalSettings_loading":"EvaluationDraftSixMonthesChange_submit_button"} dir="rtl" onClick={()=>{saveChanges()}}  disabled={loading} >
        {
          loading 
          ?
           <>يتم الحفظ ...</>
          :
          <>حفظ التعديلات</>
        }
    
       </button>

      </div>

    </div>
  )
}

export default EvaluationDraftSixMonthesChange