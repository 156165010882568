import React from 'react'
import './salaryPage.scss'
import Navbar from '../../components/Navbar/Navbar'

function SalaryPage() {
  return (
    <div className='salaryPage'>
        <Navbar/>
        <div className='salaryPage_container'>
        
     <div className="salaryShow_container">
        <span className='salaryShow_container_title'>الصافي</span>
        <span className="salaryShow_number" dir="rtl">691,5000 <span className="salaryShow_number_CountryCurrency" >ل.س</span> </span>
        <div className="salaryShow_lastTimeUpdate_container">
            <span > 2024-05-12</span>
            <span dir="rtl">تاريخ التحديث :</span>
        </div>
     </div>

     <div className='salaryShow_info_container'>

        <div className="salaryShow_info_left">

            <span className="salaryShow_info_left_title">معلومات الحسم</span>

            <div className="salaryShow_info_left_items">
           
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">حسم الغياب :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">3</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">بلا راتب :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">40,000</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">حسم بلا راتب :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">حسم التأخر :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">فاتورة الجوال :</span>  
            </div>
            <div className="salaryShow_info_left_item salaryShow_info_left_item_sanc">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">العقوبات :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">سلفة عادية :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">جوب :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">21,000</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">تأمينات اجتماعية :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">ضريبة الدخل :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">7,600</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">صندوق التعاون :</span>  
            </div>
            <div className="salaryShow_info_left_item">
                <span className="salaryShow_info_left_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_left_item_title" dir="rtl">راتب الانقطاع عن العمل :</span>  
            </div>

          
            </div>
        </div>



        <div className='salaryShow_info_right'>
            <span className="salaryShow_info_right_title">معلومات الراتب</span>

            <div className="salaryShow_info_right_items">
           
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">5955</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">الرقم الوظيفي : </span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">400,000</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">الراتب المقطوع :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">360,000</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">الحوافز : </span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">المبلغ الاضافي : </span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">بدل الطعام :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">الحوافز الإنتاجية :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">طبيعة العمل :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">تعويضات متغيرة :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">تعويضات ثابتة :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">760,000</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">مجموع الاستحقاق :</span>  
            </div>
            <div className="salaryShow_info_right_item">
                <span className="salaryShow_info_right_item_info" dir="rtl">0</span>
                <span className="salaryShow_info_right_item_title" dir="rtl">الغياب :</span>  
            </div>
                           
            </div>
        </div>
     </div>

     </div>
    </div>
  )
}

export default SalaryPage