import React, { useEffect, useState } from 'react'

import Navbar from "../../../../components/Navbar/Navbar"
import Cards from '../../Cards/Cards'

import './evaluationStatisticsMainPageShow.scss'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { errorHandeling } from '../../../../errorHandeling'

function EvaluationStatisticsMainPageShow() {

  const token = useSelector(state => state.user.token)

  const [statistics , setStatistics] = useState({});

  const [error , setError] = useState(null);

  const [loading, setLoading] = useState(false);

  


  //fetching the basic statistics to show it 



  useEffect(()=>{
    const fetchDraftTableLabel = async() => {

      try {
        setLoading(true);
        const response = await axios.get(
          `https://emp.almatingroup.com/api/V1/evaluations/statistics`,
       
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log('Response statistics :', response.data.data);
        setStatistics(response.data.data);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        
        setError(errorHandeling(error))
    
      } 
    }

    fetchDraftTableLabel();

  },[token])



  const arrCards  =[
    { 
      cardTitle:"عدد الموظفين",
      requestsNum: statistics?.employees_count,
      requestNumSuffix:"موظف",
      requestPageLink:"/emp/empmang"
    },
    {
      cardTitle:"عدد الموظفين الذين تم تقييمهم",
      requestsNum: statistics?.finished_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_emps_evaluated_before"
    },
    {
      cardTitle:"عدد الطلبات المنتظرة عند رؤساء الاقسام",
      requestsNum: statistics?.manager_wait_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_requests4manger"
    },
    {
      cardTitle:"عدد الطلبات المنتظرة التي تم إعادتها إلى رؤساء الاقسام ",
      requestsNum: statistics?.manager_return_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_requests4manger_returnedBack"
    },    {
      cardTitle:"عدد الطلبات المنتظرة عند موظف التقييم ",
      requestsNum: statistics?.hr_wait_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_evalOfficer"
    },
    {
      cardTitle:"عدد الطلبات المنتظرة التي تم إعادتها إلى موظف التقييم ",
      requestsNum: statistics?.hr_return_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_evalOfficer_returnedBack"
    },
    {
      cardTitle:"عدد الطلبات المنتظرة عند مدير HR",
      requestsNum: statistics?.hr_manager_wait_evaluation_count,
      requestNumSuffix :"طلب",
      requestPageLink:"/emp/eval_stat_requests4HRmanager"
    },
  ]

  return (
    <div className='EvaluationStatisticsMainPageShow'>
        <Navbar/>

        <div className='EvaluationStatisticsMainPageShow_container' dir='rtl'>
                    
      {
         loading ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
        <>     
           
           {
            error &&
            <span style={{color:"red", width:"100%"}}>{error}</span>
           }

         {
            arrCards.map((obj,index)=>(
              <Cards cardTitle={obj.cardTitle} requestsNum={obj.requestsNum} requestPageLink={obj.requestPageLink}
                     requestNumSuffix={obj.requestNumSuffix} key={index}
                />
            ))
          }

       </>

      }
    
        </div>

    </div>
  )
}

export default EvaluationStatisticsMainPageShow