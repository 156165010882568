// src/localStorage.js

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    return { user: state.user }; // Only load the user state
  } catch (err) {
    return undefined;
  }
};


  
  export const saveState = (state) => {

    try {
      const serializedState = JSON.stringify({ user: state.user }); 
      localStorage.setItem('state', serializedState);
    } catch (err) {
      // Ignore write errors.
    }


  };
  