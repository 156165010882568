
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react'

import Navbar from '../../../components/Navbar/Navbar'
import './updateAnOldDepartment.scss'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Puff, TailSpin } from 'react-loader-spinner';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdCancel } from "react-icons/md";


import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';
import { errorHandeling } from '../../../errorHandeling';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function UpdateAnOldDepatment() {

  const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);
    
    const { id } = useParams();

    console.log("the id in the link is :",id)
    const navigate = useNavigate();

    
    const [depName,setDepName] = useState("")
    const [depManger,setDepManger] = useState("")
    const [depMangerId,setDepMangerId] = useState(0)

    const [depParentId,setDepParentId] = useState("")
    const [depParentName,setDepParentName] = useState("")

    const [loadingUpdate,setLoadingUpdate] = useState(false)
    const [loadingSearchDeps,setLoadingSearchDeps] = useState(false)
    const [loadingSearchUsers,setLoadingSearchUsers] = useState(false)

    const [departmentsArr,setDepartmentsArr] = useState([])

    const [usersArr,setUserssArr] = useState([])


    const [showSearchResultsDeps, setShowSearchResultsDeps] = useState(false);
    const [showSearchResultsUsers, setShowSearchResultsUsers] = useState(false);

    const [error,setError] = useState(null)


    useEffect(() => {
      if (authMeStatus === 'idle') {
        dispatch(fetchAuthMe());
      }
      else if (authMeStatus === 'failed') {
        setError("*خطأ في تحميل البيانات");
      }
    }, [dispatch, authMeStatus]);


    
//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};


    const searchInputRef = useRef(null);

    useEffect(() => {
        const fetchDepartmentData = async () => {
            try {
                setLoadingUpdate(true)
                const response = await axios.get(`https://emp.almatingroup.com/api/V1/departments/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // const { DepName, DepManger, DepParent } = response.data.data;
                // setDepName(DepName);
                // setDepManger(DepManger);
                // setDepParent(DepParent);
                setDepName(response.data.data.title);
                setDepManger(response.data.data.manager?.name || "")
                setDepMangerId(response.data.data.manager?.id || 2)
                setDepParentId(response.data.data.parent?.id || 1)
                setDepParentName(response.data.data.parent?.title || "")
                console.log("the dep after fetching is:",response.data.data)
                setLoadingUpdate(false)

            } catch (error) {
                console.error("Error fetching department data:", error);
                setLoadingUpdate(false)
                setError(errorHandeling(error))
            }
        };


        
    // const fetchDepartments = async()=> {
    //     try {
    //       const response = await axios.get(
    //         'https://emp.almatingroup.com/api/V1/departments',
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //             'Content-Type': 'application/json',
    //           },
    //         }
    //       );
    //       console.log('Response:', response.data);
    //       setDepartmentsArr(response.data.data)
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };

        if(authMeStatus === "succeeded"){
                 fetchDepartmentData();
        }
 
        // fetchDepartments();
  
    }, [id, token,authMeStatus]);




    let handleSubmit = async(e)=>{
        e.preventDefault()

        if(checkPermissionAndThrow('update_departments')){

       const data = {
         title : depName,
         parent_id : depParentId ,
         manager_job_id: depMangerId 
       }

       console.log("the data is :",data)
        try {
            setLoadingUpdate(true)
            const response = await axios.put(`https://emp.almatingroup.com/api/V1/departments/${id}`,data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // const { DepName, DepManger, DepParent } = response.data.data;
            // setDepName(DepName);
            // setDepManger(DepManger);
            // setDepParent(DepParent);
       
            // console.log("the dep after fetching is:",response.data)
            setLoadingUpdate(false)
        } catch (error) {
            console.error("Error fetching department data:", error);
            setLoadingUpdate(false)
        }
 
        navigate("/emp/mang_deps");
    }
  }


    const handleDelete = async(e)=>{
        e.preventDefault()

        if(checkPermissionAndThrow('delete_departments')){
        try {
            setLoadingUpdate(true)
            const response = await axios.delete(`https://emp.almatingroup.com/api/V1/departments/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // const { DepName, DepManger, DepParent } = response.data.data;
            // setDepName(DepName);
            // setDepManger(DepManger);
            // setDepParent(DepParent);
       
            // console.log("the dep after fetching is:",response.data)
            setLoadingUpdate(false)
        } catch (error) {
            console.error("Error fetching department data:", error);
            setLoadingUpdate(false)
        }
 
        navigate("/emp/mang_deps");
    }

  }


   //choose a department to be the parent department for our department

    const  handelSearchDepParentChange = async(e)=>{

        // console.log("the event is :",typeof (e.target.value))
        // console.log("the event is :",depParentName)


        let searchVal = e.target.value
        
        // setDepParentName(searchVal)

        if(searchVal && searchVal !== ""){
          try {
            setLoadingSearchDeps(true);
            const response = await axios.post(
              `https://emp.almatingroup.com/api/V1/departments/search/${searchVal}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response for search:', response.data.data);
            setDepartmentsArr(response.data.data.data)
            setLoadingSearchDeps(false);
    
          } catch (error) {
            console.error('Error:', error);
            setLoadingSearchDeps(false);
          }
      
        }
        else{
          try {
            setLoadingSearchDeps(true);
            const response = await axios.get(
              'https://emp.almatingroup.com/api/V1/departments',
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response:', response.data.data);
            setDepartmentsArr(response.data.data.data)
            setLoadingSearchDeps(false);
    
          } catch (error) {
            console.error('Error:', error);
            setLoadingSearchDeps(false);
    
          }
        }
    
      }


    //   const handelSearchChangeAndUpdateState = (e) =>{
    //     console.log("the event for parent name is :",typeof (e.target.value))
    //     setDepParentName("JJJJ")
    //     handelSearchChange(e)
    //   }

    //   const handleClickOutside = (event) => {
    //     if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
    //         setShowSearchResults(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

   const  handelDepParentChoose = (event,depId,depName) =>{
    event.stopPropagation(); // Prevent click event from propagating to document listener
    console.log('the dep parent id :', depId);
    console.log('the dep parent name :', depName);

    setDepParentName(depName);
    setDepParentId(depId);
    // Keep the dropdown open
    setShowSearchResultsDeps(false);
   }


  
  
   //handel choose a manger to be a depManger for our department

   const  handelSearchDepMangerChange = async(e)=>{

    // console.log("the event is :",typeof (e.target.value))
    // console.log("the event is :",depParentName)


    let searchVal = e.target.value
    
    // setDepParentName(searchVal)

    if(searchVal && searchVal !== ""){
      try {
        setLoadingSearchUsers(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/users/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data.data);
        setUserssArr(response.data.data.data)
        setLoadingSearchUsers(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchUsers(false);
      }
  
    }
    else{
      try {
        setLoadingSearchUsers(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/users',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data.data);
        setUserssArr(response.data.data.data)
        setLoadingSearchUsers(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchUsers(false);

      }
    }

  }


  const  handelDepMangerChoose = (event,managerId,managerName) =>{
    event.stopPropagation(); // Prevent click event from propagating to document listener
    console.log('the dep parent id :', managerId);
    console.log('the dep parent name :', managerName);

    setDepManger(managerName);
    setDepMangerId(managerId);
    // Keep the dropdown open
    setShowSearchResultsUsers(false);
   }





  return (
    <div className='UpdateAnOldDepatment'>
        <Navbar/>

        <div className='UpdateAnOldDepatment_container'>
            <span className='UpdateAnOldDepatment_container_header' dir="rtl">تعديل قسم</span>
            <form >
            <div className='UpdateAnOldDepatment_container_depContent_items' dir="rtl">




                <div className='UpdateAnOldDepatment_container_depContent_item theBiggestFlexElment_depUpdate'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">اسم القسم<span className='important_toFill'>*</span></span>
                    <input type="text" className='UpdateAnOldDepatment_container_depContent_item_input' placeholder='اسم القسم' dir="rtl" value={depName} onChange={(e)=>setDepName(e.target.value)}/>
                </div>

                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">مدير القسم<span className='important_toFill'>*</span></span>
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '   placeholder='مدير القسم' dir="rtl" value={depManger} onChange={(e)=>{setDepManger(e.target.value); handelSearchDepMangerChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsUsers(true); 
                                  handelSearchDepMangerChange(e)
                                   console.log("the value",e.target.value) }
                            } 
                            
         
                />
                    
                      {showSearchResultsUsers && (
                        <>
                        {/* <MdCancel className='theCancel_depUpdate' onClick={()=>{setShowSearchResultsUsers(false)}}/> */}
                        {
                          loadingSearchUsers ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        usersArr.length === 0 ?
                                        <span>لا توجد بيانات لعرضها</span>:
                                        <>
                                         {usersArr.map((user,index) => (
                                        <span className={index === usersArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={user.job_id} dir='rtl' onClick={(event)=>{handelDepMangerChoose(event,user.job_id,user.name)}}>{user.name}</span>
                                    ))}
                                        </>
                                    }
               
                                </div>
                      }</> )}

                </div>



                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">القسم الأب<span className='important_toFill'>*</span></span>
                        {/* need to be changed ......................... ..........*/}
{/*                     
                    <select dir="rtl" className='EmpsMangment_selection' value={depParentId} onChange={(e)=>{setDepParentId(e.target.value)}} >
                    {
                      departmentsArr && departmentsArr.map((el)=>(
                        <option value={el.id} key={el.id} dir="rtl">{el.title}</option>
                      ))
                    }
      
                 </select> */}
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '  ref={searchInputRef} placeholder='اسم القسم الأب' dir="rtl" value={depParentName} onChange={(e)=>{setDepParentName(e.target.value); handelSearchDepParentChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsDeps(true); 
                                 handelSearchDepParentChange(e)
                                   console.log("the value",e.target.value) }
                            } 
         
                />
                 
                      {showSearchResultsDeps && (
                          <>
                        {/* <MdCancel className='theCancel_depUpdate' onClick={()=>{setShowSearchResultsDeps(false)}}/> */}
                          
                          {
                          loadingSearchDeps ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        departmentsArr.length ===0 ?
                                        <span>لا توجد بيانات لعرضها</span>
                                        :
                                        <>
                                        {departmentsArr.map((dep, index) => {
                                            console.log("the index is:",index)
                                       return <span className={index === departmentsArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={dep.id} dir='rtl' onClick={(event)=>{handelDepParentChoose(event,dep.id,dep.title)}}>{dep.title}</span>
                                     })}
                                        </>
                                     }
                           
                                </div>
                      }</>)}

                </div>

            </div>

            {error &&  
      
            <span className='error_in_saving' style={{ color: 'red'}} dir='rtl'>{error}</span>
            }



            {
                loadingUpdate ?
                <>
                <div className='TailSpin_loading_update_depPage'>
                <TailSpin
                 visible={true}
                 height="45"
                 width="45"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                </div>
                </>
                :
                <>
                {
                   (showSearchResultsUsers || showSearchResultsDeps) ?
                   <>
                   <span dir='rtl'>*عليك اختيار قيمة من القوائم ليسمح لك بحفظ التعديلات</span>
                   </>
                   :
                   <>

    
                       <div className='show_buttons_forTheUpdateDep'>
                      <button type='submit' dir="rtl" className='UpdateAnOldDepatment_container_depContent_submitBtn' onClick={(e)=>handleSubmit(e)}>حفظ</button>
                      
                      {/* <button type='submit' dir="rtl" className='UpdateAnOldDepatment_container_depContent_submitBtn delter_Btn_updateDepPage' onClick={(e)=>handleSubmit(e)}>حذف</button> */}

                      <FaRegTrashAlt className='UpdateDepPage_table_trash' onClick={(e)=>handleDelete(e)}/>
                   </div>
                   
                   </>
                }
                
                </>
            }

      

            </form>

        </div>
    </div>
  )
}

export default UpdateAnOldDepatment