import "./evaluationRequestsWatingTheManagersDep.scss"
import Navbar from "../../../../components/Navbar/Navbar"
import TabelForTheStatistcsEvaluation from "../TabelForTheStatistcsEvaluation/TabelForTheStatistcsEvaluation"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { TailSpin } from "react-loader-spinner"


function EvaluationRequestsWatingTheManagersDep() {


  const token = useSelector(state => state.user.token)

  const [searchValue,setSearchValue] = useState("")

  const [empsArr,setEmpsArr] = useState([])

  const [loadingResult,setLoadingResult] = useState(false)

  const [loadingSearchResult , setLoadingSearchResult] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)


  //fetch the the records for the statistcs status
  
  useEffect(() => {

    fetchEmpRecords(currentPage);
 
   }, [token, currentPage ]);


  
  const fetchEmpRecords = async (page) => {

    console.log("the search value is :",searchValue);

    console.log("the search value is there :",!searchValue);


    

   if(!searchValue){
    try {
      setLoadingResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/evaluations/statistics/MANAGER_WAIT?page=${page}`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setEmpsArr(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
      setLoadingSearchResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setLoadingSearchResult(false);
      setError(error.message)
  
    }
  }
  else{

    try {
      setLoadingResult(true);
      setLoadingSearchResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/evaluations/statistics-search/MANAGER_WAIT/${searchValue}?page=${page}`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setEmpsArr(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
      setLoadingSearchResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setLoadingSearchResult(false);
      setError(error.message)
  
    }
  }
  
  };




  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };



  
  return (
    <div className='EvaluationRequestsWatingforEvaluationofficer'>
      <Navbar/>

      <div className='EvaluationRequestsWatingforEvaluationofficer_container'>
       
       <span className="EvaluationRequestsWatingforEvaluationofficer_container_header" dir="rtl">الطلبات المنتظرة عند رؤساء الاقسام</span>
       
       <div className="search_part_eval_statistics">
              <input type="text" placeholder='ابحث عن قسم ما' className="search_part_eval_statistics_inp" dir="rtl" value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}} />
              <button className={loadingSearchResult ? "search_part_eval_statistics_btn btn_LoadingToSeach_eval" : "search_part_eval_statistics_btn" }  onClick={()=>{fetchEmpRecords(1)}}
                disabled={loadingResult || loadingSearchResult}
                >
                {loadingSearchResult ? <>يتم البحث </> : <>ابحث</>}
                </button>
       </div>

       
       {
         loadingResult ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
        <> 
       
       <div className="EvaluationRequestsWatingforEvaluationofficer_container_showData">

 
          <TabelForTheStatistcsEvaluation  data={empsArr}/>

       </div>

       <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>

     </>
    }

      </div>

    </div>
  )
}

export default EvaluationRequestsWatingTheManagersDep