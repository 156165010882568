import React, { useEffect, useState } from 'react'

import "./empInfoInsurance.scss"
import { TailSpin } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { BsPersonCheck } from 'react-icons/bs'

import { createSelector } from 'reselect';
import { errorHandeling } from '../../../errorHandeling'


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

function EmpInfoInsurance({empInfo}) {


    
    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);



   console.log("the props we have it in the personal data are :",empInfo)
  
  const [fields,setFields] = useState({
    job_id : empInfo.job_id,
    social_insurance:empInfo.insurance? empInfo.insurance.social_insurance : "false" ,
    insurance_salary : empInfo.insurance?.insurance_salary || "",
    date_registration : empInfo.insurance?.date_registration || "",
    remaining_advance: empInfo.insurance?.remaining_advance || "",
    social_insurance_number: empInfo.insurance?.social_insurance_number || "",

  })


  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(null)

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)

  console.log("the social insurance: ",empInfo.insurance?.social_insurance)








  const handleChange = (e) => {
    const { name, value } = e.target;

    let convertedValue = value ; 

    if(name === "social_insurance"){
     convertedValue = value === 'true' ? true : value === 'false' ? false : false;
    }

    setFields((prevFields) => ({
      ...prevFields,
      [name]: convertedValue
    }));
  };





  //check for permissions 

  const hasPermission = (permission) => userPermissions.includes(permission);

  const checkPermissionAndThrow = (permission) => {
    if (!hasPermission(permission)) {
      
      return false;
    }
    else{
      return true;
    }
  };

  


//handel the submit of the user
const handleSubmit = async (e)=>{
    e.preventDefault();

    if(checkPermissionAndThrow("update_insurances")){

    console.log("the fields to save :",fields)
    //show the result of changing the departments and dont forget to optimize not fetching all the time
    // console.log("we are submitting the data")

    let data = {
        ...fields

    } 

    console.log("the data is after forming it:",data)

    if(!empInfo.insurance && fields.social_insurance === "UNKNOWN"){
        setError("(لا يجب أن يكون حقل التأمين الاجتماعي غير محدد (عليك ملئ الحقول")
        return;
    }


    if(empInfo.insurance){

        try {
            setLoading(true)
        
            const response = await axios.put(
              `https://emp.almatingroup.com/api/V1/insurances/${empInfo.job_id}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response after submitting:', response.data);
            setLoading(false)
            setSaveChangesSuccessfully(true)
        
          } catch (error) {
            console.error('Error:', error);
            setLoading(false)
            setError(errorHandeling(error))
            setSaveChangesSuccessfully(false)

          }

    }
    else{

        try {
            setLoading(true)
        
            const response = await axios.post(
              `https://emp.almatingroup.com/api/V1/insurances`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response after submitting:', response.data);
            setLoading(false)
            setSaveChangesSuccessfully(true)
        
          } catch (error) {
            console.error('Error:', error);
            setLoading(false)
            setError(errorHandeling(error))
            setSaveChangesSuccessfully(false)

          }

    }
    
  }

}


  return (
    <div className='EmpInfoInsurance'>



    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>



    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }


    <form onSubmit={handleSubmit}>
         
       
     <div className="EmpsMangment_search_result_table_component_items">


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">التأمين الاجتماعي</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.social_insurance} name='social_insurance' onChange={handleChange}>
      <option value={'true'} dir="rtl">مسجل</option>
      <option value={'false'} dir="rtl">غير مسجل</option>
     </select>
      </div>



      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">راتب التأمينات</label>
      <input type="text" dir="rtl" value={fields.insurance_salary} name="insurance_salary" onChange={handleChange} />
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">المتبقي من التأمينات</label>
      <input type="text" dir="rtl" value={fields.remaining_advance} name="remaining_advance"  onChange={handleChange} />
      </div>



      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">بداية تاريخ التأمين</label>
      <input type="date" dir="rtl" value={fields.date_registration} name="date_registration" onChange={handleChange} />
      </div>

  
      
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم التأمينات الاجتماعية</label>
      <input type="text" dir="rtl" value={fields.social_insurance_number} name="social_insurance_number" onChange={handleChange} />
      </div>





    </div>

    {
        error ? 
        <span className='EmpInfoInsurace_error' dir='rtl'>{error}</span>
        :
        <></>
    }

    {
      checkPermissionAndThrow("update_insurances") &&
     <button dir="rtl" type='submit' className='submitButton_changeUserIfno' onClick={(e)=>{handleSubmit(e)}} disabled={loading}>
      {
     loading ? 
      <>
        <TailSpin
        visible={true}
        height="22"
        width="22"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </>
      :
      <>حفظ</>
      }</button>
    }

   </form>

    </div>
  )
}

export default EmpInfoInsurance