import React from 'react'
import './unautherized.scss'


function Unautherized() {
  return (
    <div className='Unautherized'>
        <div className='Unautherized_text'>
           <span className='Unautherized_text_big'>401</span>
           <span className='Unautherized_text_small'>You dont have access to this page</span>

        </div>

    </div>
  )
}

export default Unautherized