import React, { useEffect, useState } from 'react'

import './mangmentJobPositions.scss'

import Navbar from '../../../components/Navbar/Navbar'

import { Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import NothingSearchShow from '../../../components/NothingSearchShow/NothingSearchShow';

import { createSelector } from 'reselect';
import { FaPlus } from 'react-icons/fa';
import { errorHandeling } from '../../../errorHandeling';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function MangmentJobPositions() {


  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);

  const authMeStatus = useSelector(state => state.authme.status);


  const [posArr,setPosArr] = useState([])
  const [loadingResult,setLoadingResult] = useState(false)

  const [error,setError] = useState(null)


  const [isSearching, setIsSearching] = useState(false);



     const navigate = useNavigate();


 



  //check for permissions 

  const hasPermission = (permission) => userPermissions.includes(permission);

  const checkPermissionAndThrow = (permission) => {
    if (!hasPermission(permission)) {
      
      return false;
    }
    else{
      return true;
    }
  };





     useEffect(() => {
    
      const fetchJobPositions = async()=> {
     
        try {
          setLoadingResult(true);
          setIsSearching(false);
          const response = await axios.get(
            'https://emp.almatingroup.com/api/V1/job-positions',
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response:', response.data);
      
          if(response.data.data.data.length === 0){
            setError("*لايوجد بيانات لعرضها")
          }
          else{
          setError(null)
          } 
          
          setPosArr(response.data.data.data)
          setLoadingResult(false);

        } catch (error) {
          console.error('Error:', error);
          setLoadingResult(false);
          setIsSearching(false);
          setError(errorHandeling(error))
        }

       };


       if(authMeStatus === "succeeded"){
           fetchJobPositions();
       }  
    
   }, [token ,authMeStatus]);
  



     let handelUpdating = (id)=>{
      if(checkPermissionAndThrow("update_job_positions")){
      navigate(`/emp/update_job_position/${id}`);
      }
     }



     const  handelSearchChange = async(e)=>{

      console.log("the event is :",typeof (e.target.value))
      let searchVal = e.target.value

    
      
      if(searchVal && searchVal !== ""){
        try {
          setIsSearching(true);
          setLoadingResult(true);
          const response = await axios.post(
            `https://emp.almatingroup.com/api/V1/job-positions/search/${searchVal}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response for search:', response.data);

          if(response.data.data.data.length === 0){
            setError("*لايوجد بيانات لعرضها")
          }
          else{
          setError(null)
          }
          setPosArr(response.data.data.data)
          setLoadingResult(false);
  
        } catch (error) {
          console.error('Error:', error);
          setLoadingResult(false);
          setIsSearching(false);
          setError(errorHandeling(error))

        }
    
      }
      else{
        try {
          setIsSearching(false);
          setLoadingResult(true);
          const response = await axios.get(
            'https://emp.almatingroup.com/api/V1/job-positions',
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response:', response.data);

          if(response.data.data.data.length === 0){
            setError("*لايوجد بيانات لعرضها")
          }
          else{
          setError(null)
          }
          setPosArr(response.data.data.data)
          setLoadingResult(false);
  
        } catch (error) {
          console.error('Error:', error);
          setLoadingResult(false);
          setIsSearching(false);
          setError(errorHandeling(error))
        }
      }
  

  
  }

  return (
    <div className='ManagmentJobPositions'>
        <Navbar/>

        <div className='ManagmentJobPositions_container'>

            <div className='ManagmentDepartments_container_top_part'>
              { //check if the user has the right permission

                checkPermissionAndThrow("create_job_positions") &&
                <Link to='/emp/create_job_position'  dir="rtl"><FaPlus className='ManagmentDepartments_container_bottom_part_createbButtontext' /></Link>   
              }
           
                 <div className='ManagmentDepartments_container_top_part_searchBar'>
                    <input type="text" placeholder='ابحث عن منصب ما' dir="rtl"  onChange={(e)=>{handelSearchChange(e)}}/>
                 </div>
                <span className='ManagmentDepartments_container_top_part_header' dir="rtl">ادارة المناصب</span>
            </div>
           
           
            <div className='ManagmentJobPositions_container_bottom_part'>
               
                
            {
                  loadingResult ?
                  <>
                <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                  </>
                  :

                  (error
                    ?
                    <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
                    :
                    <>
                    <table className='ManagmentJobPositions_table'>
                    <thead>
                   <tr className='ManagmentJobPositions_header_row_table'>
                      <th>عدد الموظفين</th>
                      <th>اسم المنصب الوظيفي</th>              
                   </tr>
                   </thead>
  
                  <tbody>
                   { posArr.map(
                     obj =>(
                    <tr key={obj.id}  className='ManagmentJobPositions_container_bottom_part_rowTable' onClick={()=>handelUpdating(obj.id)}>
                    <td dir="rtl">{obj.employees_count || 0}</td>
                    <td dir="rtl">{obj.title}</td>
                 </tr>
                 )
                   )}
                     </tbody>
  
              </table>
                    
                    </>
                  )
 

            }


            </div>

        </div>

    </div>
  )
}

export default MangmentJobPositions