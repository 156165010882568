import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import './vacationStatistics.scss'

import axios from 'axios'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'
import VacationsInWaiting from '../../components/CompsForVacationsStatistacs/VacationsInWaiting/VacationsInWaiting'
import VacationsForTodayAccepted from '../../components/CompsForVacationsStatistacs/VacationsForTodayAccepted/VacationsForTodayAccepted'

function VacationStatistics() {

  const token = useSelector(state => state.user.token)

  const [tableToShow,setTableToShow] = useState('1');
  
  const [fields,setFields] = useState({
    today : "",
    today_accepted_vacations_count: "",
    all_emp_count : "",
    department_managers_count: ""
  })


  const [loadingResult,setLoadingResult] = useState(false)
  



 useEffect(() => {
    
  const fetchVacations = async()=> {
    try {
      setLoadingResult(true);
      const response = await axios.get(
        'https://emp.almatingroup.com/api/V1/vacations/main/statistics',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data);

      setFields(
        prev =>({
          ...prev ,
          today : response.data.data.today ,
          today_accepted_vacations_count: response.data.data.today_accepted_vacations_count,
          all_emp_count : response.data.data.all_emp_count,
          department_managers_count: response.data.data.department_managers_count

        })
      )

      setLoadingResult(false);

    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
    }
  };


  fetchVacations();
}, [token]);


  return (
    <div className='VacationStatistics_page'>
        <Navbar/>
       <div className='VacationStatistics_container'>
          <div className='VacationStatistics_leftPart'>
            <div className='VacationStatistics_top_section'>
            {
                loadingResult ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="40"
               width="40"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
               :
               <>
                  <div className='VacationStatistics_top_section_item'>

                    <div className='VacationStatistics_top_section_item_header'>
                    <span >عدد الاجازات اليوم</span>
                    <span className='the_dateOfToday_item_header_VacationStatistics'>{fields.today}</span>
                   </div>
                    <span className='VacationStatistics_top_section_item_content'>{fields.today_accepted_vacations_count}</span>
                    <span className='VacationStatistics_top_section_item_header_bottom'></span>

                </div>
                <div className='VacationStatistics_top_section_item'>
                    <span className='VacationStatistics_top_section_item_header'>عدد رؤساء الاقسام</span>
                    <span className='VacationStatistics_top_section_item_content'>{fields.department_managers_count}</span>
                    <span className='VacationStatistics_top_section_item_header_bottom'></span>

                </div>
                <div className='VacationStatistics_top_section_item'>
                    <span className='VacationStatistics_top_section_item_header'>عدد الموظفين</span>
                    <span className='VacationStatistics_top_section_item_content'>{fields.all_emp_count}</span>
                    <span className='VacationStatistics_top_section_item_header_bottom'></span>

                </div>
               
               </>
               }
  
            </div>

            <div className='VacationStatistics_bottom_section'>

              {
                tableToShow === '1' ?
                <VacationsInWaiting/> :
                 <VacationsForTodayAccepted/>
              }
                
            </div>

          </div>


          <div className='VacationStatistics_rightPart'>
            <span className='VacationStatistics_rightPart_header' dir="rtl">احصائيات الاجازات</span>
            <div className='VacationStatistics_rightPart_items'>
                <span className={tableToShow === '1' ? 'VacationStatistics_rightPart_item VacationStatistics_rightPart_item_first_item activeShowTable' : 'VacationStatistics_rightPart_item VacationStatistics_rightPart_item_first_item'} dir="rtl" onClick={()=>setTableToShow('1')}>اجازات قيد الانتظار</span>
                <span className={tableToShow === '2' ? 'VacationStatistics_rightPart_item activeShowTable' :  'VacationStatistics_rightPart_item'} dir="rtl"  onClick={()=>setTableToShow('2')}>الاجازات الموافق عليها</span>
            </div>
          </div>
       </div>
    </div>
  )
}

export default VacationStatistics