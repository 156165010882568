
import './vacationManagerShow.scss'

import Navbar from '../../components/Navbar/Navbar';
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import PopupGfg from './VacationReasonPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';


// import { createSelector } from 'reselect';

import { fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';

// const selectUserPermissions = createSelector(
//   state => {
//     const roles = state.authme.data?.roles;
//     if (!roles || roles.length === 0 || !roles[0].permessions) {
//       return null; // Return null instead of a new array
//     }
//     return roles[0].permessions;
//   },
//   permessions => permessions ? permessions.map(perm => perm.name) : []
// );



function VacationManagerShow() {

  const dispatch = useDispatch();


const token = useSelector(state => state.user.token)

// const userPermissions = useSelector(selectUserPermissions);

const authMeStatus = useSelector(state => state.authme.status);

const authMe = useSelector(state => state.authme.data);

const [vacationsArr,setVacationsArr] = useState([])
const [loadingResult,setLoadingResult] = useState(false)

const [loadingChanges,setLoadingChanges] = useState(false)

const [changeableStateForChangingVaca,setChangeableStateForChangingVaca] = useState(false)

const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);

const [error,setError] = useState(null)



useEffect(() => {
  if (authMeStatus === 'idle') {
    dispatch(fetchAuthMe());
  }
  else if (authMeStatus === 'failed') {
    setError("*خطأ في تحميل البيانات");
  }
}, [dispatch, authMeStatus]);






  //check for permissions 

  // const hasPermission = (permission) => userPermissions.includes(permission);

  // const checkPermissionAndThrow = (permission) => {
  //   if (!hasPermission(permission)) {
  //     setError("*ليس لديك الصلاحيات المناسبة يرجى مراجعة قسم التقانة");
  //     return false;
  //   }
  //   else{
  //     return true;
  //   }
  // };

  



const TruncatedText = (text, maxLength ) => {
  const truncatedText = text.length > maxLength? `${text.split(' ')[0]}...` : text;

  return <>{truncatedText}</>
};


////////////////////////////////////

useEffect(() => {

  if (authMeStatus === 'succeeded') {
    fetchVacations(currentPage);
  }
}, [token, currentPage ,changeableStateForChangingVaca ,authMeStatus]);





const fetchVacations = async (page) => {

  if(authMe?.is_manager){


  try {
    setLoadingResult(true);
    const response = await axios.get(
    `https://emp.almatingroup.com/api/V1/vacations/waiting/get?page=${page}`,
   
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('Response:', response.data.data.data);
    setVacationsArr(response.data.data.data);
    setCurrentPage(response.data.data.current_page);
    setTotalPages(response.data.data.last_page);
    setLoadingResult(false);
  } catch (error) {
    console.error('Error:', error);
    setLoadingResult(false);
    setError(error.message)

  }
}

};



const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};




const handlePageChange = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};



const renderPageNumbers = () => {
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
      >
        {i}
      </button>
    );
  }

  return pageNumbers;
};






let handelAcceptVacation = async(vacationId)=>{

  if(authMe?.is_manager ){
 

 const data = {
  status : 1,

 }

 console.log("the data is :",data)
  try {
      setLoadingChanges(true)
      const response = await axios.put(`https://emp.almatingroup.com/api/V1/vacations/${vacationId}/change-status`,data, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      // const { DepName, DepManger, DepParent } = response.data.data;
      // setDepName(DepName);
      // setDepManger(DepManger);
      // setDepParent(DepParent);
 
      console.log("the dep after fetching is:",response.data)
      setLoadingChanges(false)
      setChangeableStateForChangingVaca(prev =>(!prev))
      
  } catch (error) {
      console.error("Error fetching department data:", error);
      setLoadingChanges(false)
      setError(error.message)
  }


}


}



let handelRefuceVacation = async(vacationId)=>{
 
  if(authMe?.is_manager ){
  const data = {
   status : 0,
 
  }
 
  console.log("the data is :",data)
   try {
       setLoadingChanges(true)
       const response = await axios.put(`https://emp.almatingroup.com/api/V1/vacations/${vacationId}/change-status`,data, {
           headers: {
               Authorization: `Bearer ${token}`
           }
       });
       // const { DepName, DepManger, DepParent } = response.data.data;
       // setDepName(DepName);
       // setDepManger(DepManger);
       // setDepParent(DepParent);
  
       // console.log("the dep after fetching is:",response.data)
       setLoadingChanges(false)
       setChangeableStateForChangingVaca(prev =>(!prev))
       
   } catch (error) {
       console.error("Error fetching department data:", error);
       setLoadingChanges(false)
       setError(error.message)
   }
 
 
 }

}


  return (
    <div className='vacationManagerShow'>

        <Navbar/>

    <div className="vacationManagerShow_content">
        <span className="vacationManagerShow_title" dir="rtl">طلبات الاجازة</span>

        {
          error 
          ?
          <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
          :
          <></>
        }
        
        <table className='vacationManagerShow_table'>
        <thead>
        <tr className='vacationManagerShow_header_row_table'>
          <th>خيارات</th>
          <th>الأسباب</th>
          <th>تاريخ النهاية</th>
          <th>تاريخ البداية</th>
          <th>الاسم</th>
          <th>الرقم الوظيفي</th>
        </tr>
        </thead>


    <tbody>

          { vacationsArr?.map(
            obj =>(
              <tr  key={obj.id}>
              <td>
            <div className='vacationManagerShow_table_choices'>
            {
              loadingChanges || loadingResult ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="20"
               width="20"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
          :
         <>
              <button onClick={()=>{handelRefuceVacation(obj.id)}} disabled={loadingResult}>
              <MdOutlineCancel className='vacationManagerShow_btn_cancel' />
              </button>
        
              <button onClick={()=>{handelAcceptVacation(obj.id)}} disabled={loadingResult}>
              <FaCheck className='vacationManagerShow_btn_accept'   />
              </button>
         
         </>
        }

            </div>
           </td>
           <td dir="rtl"><PopupGfg reason={obj.reasons} firstWord = {TruncatedText(obj.reasons,10)}/></td>
           <td dir="rtl">{formatDate(obj.end_date)}</td>
           <td dir="rtl">{formatDate(obj.start_date)}</td>
           <td dir="rtl">{obj.employee?.name || "لم يتم تحديد الاسم بعد"}</td>
           <td dir="rtl">{obj.employee?.job_id}</td>
        </tr>

            )
          )}
        </tbody>

    </table>

    {
      loadingResult ? 
      <>
      </>
      :
      <>
      <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>
      
      </>
    }



    {
         loadingResult ?
          <div className='spinner_getData'>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
         <></>
        }

    </div>

    </div>
  )
}

export default VacationManagerShow