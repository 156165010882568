import React, { useEffect, useState } from 'react'
import './evaluationinfos.scss'
function EvaluationInfos() {

  const [evHalfYearField , setEvHalfYearField] = useState("")
  const [evInternPeriodField , setEvInternPeriodField] = useState("")
  
  useEffect(() => {
    const fetchData = ()=> {
     setEvHalfYearField("Ev_firstHalfYear");
     setEvInternPeriodField("Finished");

    };

    fetchData();
 }, []);


  let EvHalfYearFunc = (e) => {
    setEvHalfYearField(e.target.value);
  }

  let EvInternPeriodFunc = (e) => {
    setEvInternPeriodField(e.target.value);
  }


  let handleSubmit = (e)=>{
    e.preventDefault();
    console.log("You have clicked the submit button")
   }


  return (
    <div className="EmpsMangment_search_result_table_component">
    
    <div className="EmpsMangment_search_result_table_empId">
      <span>5955</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>

    <form onSubmit={handleSubmit}>

   <div className="EmpsMangment_search_result_table_component_items">

    <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl"> التقييم النصف سنوي</label>
      <select dir="rtl" className='EmpsMangment_selection' value={evHalfYearField} onChange={EvHalfYearFunc}>
      <option value="Ev_firstHalfYear" dir="rtl">تقييم النصف السنوي الأول</option>
      <option value="Waiting_Ev_firstHalfYear" dir="rtl">انتظار تقييم النصف السنوي الأول</option>
      <option value="Ev_secondHalfYear" dir="rtl">تقييم النصف السنوي الثاني</option>
      <option value="Waiting_Ev_secondHalfYear" dir="rtl">انتظار تقييم النصف السنوي الثاني</option>

  </select>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تقييم الفترة التدريبية</label>
      <select dir="rtl" className='EmpsMangment_selection'value={evInternPeriodField} onChange={EvInternPeriodFunc} >
      <option value="Finished" dir="rtl">منتهي</option>
      <option value="Ev_firstMonth" dir="rtl">تقييم الشهر الأول</option>
      <option value="Waiting_Ev_firstMonth" dir="rtl">انتظار تقييم الشهر الأول</option>
      <option value="Ev_firstThreeMonths" dir="rtl">تقييم الأشهر الثلاثة الأولى</option>
      <option value="Waiting_Ev_firstThreeMonths" dir="rtl">انتظار تقييم الأشهر الثلاثة الأولى</option>

  </select>
      </div>

      </div>

      <button dir="rtl" type='submit'>حفظ</button>

    </form>

    </div>
  )
}

export default EvaluationInfos