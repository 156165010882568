import React, { useEffect, useState } from 'react'
import './vacationInWaiting.scss'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function VacationsInWaiting() {

      const token = useSelector(state => state.user.token)

      const [vacationsArr,setVacationsArr] = useState([])

      const [loadingResult,setLoadingResult] = useState(false)

      const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(1);

      const [vacationTypeToShow,setVacationTypeToShow] = useState("WAITING_FOR_HR_ACCEPT")


      useEffect(() => {
        fetchVacations(currentPage);
      }, [token, currentPage]);



      // useEffect(()=>{
      //   console.log("the type for vacation are from useeffect:",vacationTypeToShow)
      // },[vacationTypeToShow])

    
      const fetchVacations = async (page) => {
        let data = {
          status: vacationTypeToShow
        };

        console.log("the data for fetching vacation is:",data)
    
        try {
          setLoadingResult(true);
          const response = await axios.post(
            `https://emp.almatingroup.com/api/V1/vacations/changed/statistics?page=${page}`,
             data , 
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response:', response.data.data);
          setVacationsArr(response.data.data.data);
          setCurrentPage(response.data.data.current_page);
          setTotalPages(response.data.data.last_page);
          setLoadingResult(false);
        } catch (error) {
          console.error('Error:', error);
          setLoadingResult(false);
        }
      };
    




      const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
          setCurrentPage(page);
        }
      };

      
    
      const renderPageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
    
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
            >
              {i}
            </button>
          );
        }
    
        return pageNumbers;
      };
      



    
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };





      const handleVacationChangeTypeShowResult = ()=>{
        fetchVacations(1)
      }



  return (

   <div className='VacationsInWaiting_fullComp'>

    <div className='vacationWating_changeResultType'>

    <div className="vacationWating_changeResultType_select">
      <label dir="rtl">الاجازات المنتظرة موافقة :</label>
      <select dir="rtl" className='vacationWating_changaType_selection' value={vacationTypeToShow} name='vacationTypeToShow' onChange={(e)=>{setVacationTypeToShow(e.target.value)}}>
      <option value="WAITING_FOR_HR_ACCEPT" className='vacationWating_changaType_selection' dir="rtl">ال HR</option>
      <option value="WAITING_FOR_MANAGER_ACCEPT" className='vacationWating_changaType_selection' dir="rtl">المدير</option>
     </select>
      </div>

      <button className='vacationWating_changeResultType_showResult_button' onClick={()=>{handleVacationChangeTypeShowResult()}} disabled={loadingResult} dir='rtl'>
      {
                    loadingResult ?
                    <div className='spinner_getData_button'>
                    <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                      />
                    </div> 
                    :
                    <>
                     عرض النتائج
                    </>

  }
   </button>

    </div>
   





    <div className='VacationsInWaiting'>

             {
                loadingResult ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="40"
               width="40"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
               :
               <>

                       <span className='VacationsInWaiting_header' dir="rtl">إجازات قيد الإنتظار (جميع الطلبات)</span>
                    
                    <table className='VacationsInWaiting_table'>
                      <thead>
                    <tr className='VacationsInWaiting_header_row_table'>
                      <th>رقم الموبايل</th>
                      <th>تاريخ النهاية</th>
                      <th>تاريخ البداية</th>
                      <th>القسم</th>
                      <th>الاسم</th>
                      <th>الرقم الوظيفي</th>
                    </tr>
                  </thead>
            
                  <tbody>
                      { vacationsArr?.map(
                        obj =>(
                          
                          <tr key={obj.id}>
                       <td dir="rtl">{obj.employee?.mobile}</td>
                       <td dir="rtl">{formatDate(obj.end_date)}</td>
                       <td dir="rtl">{formatDate(obj.start_date)}</td>
                       <td dir="rtl">{obj.employee?.department?.title}</td>
                       <td dir="rtl">{obj.employee?.name}</td>
                       <td dir="rtl">{obj.employee?.job_id}</td>
                    </tr>
                        )
                      )}
                  </tbody>
            
            
                </table>



                <div className="pagination_VacationStatistics">
            <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
               <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
            </button>

            {renderPageNumbers()}
            
            <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            <FaAngleRight   className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
            </button>
          </div>
          
               
               </>
             }

   
    </div>

    </div>

  )
}

export default VacationsInWaiting