import React, { useState } from 'react'
import './changepassword.scss'
import { BsPersonCheck } from "react-icons/bs";
import axios from 'axios'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'
import { Link } from 'react-router-dom';
import {errorHandeling} from '../../errorHandeling'

function ChangePassword({empInfo,url,isChangerIsUser}) {

  const token = useSelector(state => state.user.token)
  

  const [newPasswordField , SetnewPasswordField] = useState("")
  const [newPasswordAuthField , SetnewPasswordAuthField] = useState("")


  const [loading,setLoading] = useState(false);
  const [error, setError] = useState('');
  const [changePassSuccessfully,setChangePassSuccessfully] = useState(false)

  let  NewPasswordFunc = (e) => {
    SetnewPasswordField(e.target.value);
  }

  let  NewPasswordAuthFunc = (e) => {
    SetnewPasswordAuthField(e.target.value);
  }



  const handleSubmit = async (e) => {
    e.preventDefault();

       // Reset error message
        setError('');

        // Validation check
        if (newPasswordField.trim() === '' || newPasswordAuthField.trim() === '') {
          setError('الحقول لا يجب أن تكون فارغة');
          return;
        }
    
        if (newPasswordField !== newPasswordAuthField) {
          setError('حقل كلمة المرور و حقل تأكيد كلمة المرور يجي أن يكونو متطابقان');
          return;
        }

        const data = {
          password:newPasswordField,
          password_confirmation:newPasswordAuthField
        };
  
    try {
      setLoading(true)
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)
      SetnewPasswordField("")
      SetnewPasswordAuthField("")
      setChangePassSuccessfully(true)
    } catch (error) {
      let err = error.response?.data?.errors?.password || errorHandeling(error)
     
      setError(err)
      setLoading(false)
      setChangePassSuccessfully(false)
      console.error('Error:', error);

    }
    console.log("the data that need to send is :",data)
    console.log("the url after changing is :",url)

  };
  
  
  


  return (
    <div className="EmpsMangment_search_result_table_component_change_pass">
    
    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo?.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>

    {
      changePassSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم تغيير كلمة السر بنجاح</span>
      {
        isChangerIsUser?
        <span className='changePassComp_changeSuccPassMessage_textForLink'><Link to={'/emp'}> (عودة الى الصفحة الرئيسية)</Link></span>
        :
        <></>
      }
    
   
      </div>

      :
      <></>
    }

    <form onSubmit={handleSubmit}>

   <div className="EmpsMangment_search_result_table_component_items">

   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تأكيد كلمة المرور الجديدة</label>
      <input type="text" dir="rtl" value={newPasswordAuthField} onChange={NewPasswordAuthFunc} />
      </div>

    <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">كلمة المرور الجديدة</label>
      <input type="text" dir="rtl" value={newPasswordField} onChange={NewPasswordFunc} />
      </div>


      </div>

      {
        error &&
        <span dir='rtl' className='error_in_changePassword'>{error}</span>
      }

      <button dir="rtl" type='submit' disabled={loading}>
        {
          loading ?
          <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
          </>
          :
          <>حفظ</>
        }
      </button>
      
      </form>

    </div>
  )
}

export default ChangePassword