import  { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './evaluateDraftPageForSixMonthes.scss'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import PopupForEvaluationDraftExplaination from '../PopupForEvaluationDraftExplaination/PopupForEvaluationDraftExplaination';
import {extractDataForTabelFromTheObject} from '../extractDataForTabelFromTheObject';
import axios from 'axios'
import {errorHandeling} from "../../../../errorHandeling"
import { useNavigate } from 'react-router-dom'
import NoficationForReturnedEval from '../NoficationForReturnedEval/NoficationForReturnedEval';
import { createSelector } from 'reselect';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function EvaluateDraftPageForSixMonthes({data, tableRowsLabels }) {

  const token = useSelector(state => state.user.token)

  const authMe = useSelector(state => state.authme.data);

  const navigate = useNavigate();

  const userPermissions = useSelector(selectUserPermissions);


  // Initial values for m_recommendations and m_money_recommended
  let m_recommendations = data.m_recommendations || "التثبيت بعد انتهاء فترة التجربة";
  let m_money_recommended = data.m_money_recommended || null;

  // Check if m_recommendations contains "بنسبة"
  if (m_recommendations.includes("بنسبة")) {
    // Split the string at "بنسبة" and extract the parts
    const splitRecommendation = m_recommendations.split("بنسبة");
    
    m_recommendations = splitRecommendation[0].trim(); // Text before "بنسبة"
    m_money_recommended = splitRecommendation[1].trim(); // Text after "بنسبة"
  }



  const [fields,setFields] = useState({
    
    evaluationType : "evalTemp",
    managerOpinion : "install_afterTheTesting",
    tabelLabels : [],
    tabelValues : {
      "m_first_mark" : data.m_first_mark || null,
      "m_first_notes": data.m_first_notes || null,
      "m_second_mark": data.m_second_mark || null,
      "m_second_notes": data.m_second_notes || null,
      "m_third_mark": data.m_third_mark|| null,
      "m_third_notes": data.m_third_notes || null,
      "m_fourth_mark": data.m_fourth_mark || null,
      "m_fourth_notes": data.m_fourth_notes || null,
      "m_fifth_mark": data.m_fifth_mark || null,
      "m_fifth_notes": data.m_fifth_notes || null,
      "m_sixth_mark": data.m_sixth_mark|| null,
      "m_sixth_notes": data.m_sixth_notes || null,
      "m_seventh_mark": data.m_seventh_mark || null,
      "m_seventh_notes": data.m_seventh_notes || null,
      "m_eighth_mark": data.m_eighth_mark || null,
      "m_eighth_notes": data.m_eighth_notes || null,
      "m_ninth_mark": data.m_ninth_mark || null,
      "m_ninth_notes": data.m_ninth_notes || null,
      "m_tenth_mark": data.m_tenth_mark || null,
      "m_tenth_notes": data.m_tenth_notes || null,
      "m_strengths": data.m_strengths || null,
      "m_weaknesses": data.m_weaknesses || null,
      "m_impression": data.m_impression || null,
      "m_recommendations": data.m_recommendations || null
    } ,
    totalDegreesValue : 0 ,
    m_strengths :data.m_strengths || null,
    m_weaknesses :data.m_weaknesses || null,
    m_impression : data.m_impression || null,
    m_recommendations : m_recommendations ,
    m_money_recommended : m_money_recommended,
    m_return_reason : data.m_return_reason || null,

  })

  const [tabelLabels , setTabelLabels] = useState([]);

  const [toShowManagerOpnion,setToShowManagerOpnion] = useState(false)


  const [loading , setLoading ] = useState(false);

  const [error , setError] = useState(null);

  const [loadingReturnEvalToManager , setLoadingReturnEvalToManager] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };



  //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};


  
 // to format the date 

  const formatDate = (theDate) => {
    const date = new Date(theDate);
    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    
    // Return the formatted date
    return `${month}/${day}/${year}`;
  };





 const  handelChangeFieldsForManagerSuggestion = (value)=>{

  setFields((prevFields) => ({
    ...prevFields,
    ["m_recommendations"]: value
  }));

setToShowManagerOpnion(false)

 }



  const handleNumBiggerThanRange = (event, maxRange) => {
    const inputElement = event.target;
    const value = parseFloat(inputElement.value);

    if (value > maxRange) {
      inputElement.classList.add('danger_aboveTheMax');
    } else {
      inputElement.classList.remove('danger_aboveTheMax');
    }
  };



  //for the table


//I think there are over rendering happeing here so be crazy and look at it

  useEffect(()=>{
    const setDataForTable = ()=>{
    //get the semiAnnual table labels 
    let semiAnnual = tableRowsLabels.find(item => item.type === "SEMI_ANNUAL");
  
    console.log("the semmi annual is :",semiAnnual);
  
    setTabelLabels(extractDataForTabelFromTheObject(semiAnnual));
  
   
    }
  
    setDataForTable();
  
  },[])
  
  
  


  const  sendTabelLabelsArr = ["m_first","m_second","m_third","m_fourth","m_fifth","m_sixth","m_seventh","m_eighth"
    ,"m_ninth" , "m_tenth" ];


    const handelChangeFieldsForTheTabel = (e)=>{
      const { name, value } = e.target;

      setFields((prevFields) => ({
        ...prevFields,
        ["tabelValues"]: {
          ...prevFields.tabelValues,
          [name] : value ,
        }
      }));
    }




    useEffect(()=>{
      const getTheSum = () => {
        let sum = 0; // Initialize sum outside the loop to accumulate the total correctly
    
        for (let i = 0; i < 10; i++) {
          const mark = parseInt(fields.tabelValues[`${sendTabelLabelsArr[i]}_mark`], 10); // Convert the mark to an integer
    
       // Handle null values by considering them as 0
       if (!Number.isInteger(mark)) {
        sum += 0; // Add 0 to the sum if the mark is not a valid integer
      } else {
        sum += mark; // Accumulate the valid integer
      }
      
        }
    
        setFields((prevFields) => ({
          ...prevFields,
          ["totalDegreesValue"]:sum ,

        }));


      };
    
      getTheSum(); // Call the function to calculate the sum
    },[fields.tabelValues])
    

  //validate the data before the submitting 
  const validateData = ()=>{
    for(let i = 0 ; i < tabelLabels.length ; i++){
      
      if( !fields.tabelValues[`${sendTabelLabelsArr[i]}_mark`]  ){
       setError("*يرجى التأكد من ملئ جميع حقول درجة أداء الموظف");
       return false ; 
      }
    }

     if(!fields.m_strengths || !fields.m_weaknesses || !fields.m_impression){
      setError("*يرجى التأكد من ملئ حقول نقاط القوة و نقاط الضعف و الانطباع العام عن الموظف");
      return false ; 
     }

     else if(fields.totalDegreesValue < 0 || fields.totalDegreesValue > 100 ){
      setError("*يرجى التأكد من أن مجموع درجات الموظف ليس أكبر من 100");
      return false ; 
    }


     setError(null);

     return true;
  }


  //onSubmit 
  const handelSubmit = async ()=>{
   
    if(!validateData()){  return ; }

    let payload = {
      ...fields.tabelValues,
      m_strengths : fields.m_strengths ,
      m_weaknesses : fields.m_weaknesses ,
      m_impression : fields.m_impression ,
      m_recommendations : fields.m_recommendations + ( fields.m_recommendations === "الحصول على ترقية او زيادة راتب" ? " بنسبة " +fields.m_money_recommended : ""),

    }

    try {
      setLoading(true)
  
      const response = await axios.put(
        `https://emp.almatingroup.com/api/V1/evaluations/manager/${data.id}`,
        payload ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)
      setError(null)
      navigate("/emp/eval_emp_manager");
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      setError(error.message ? error.message : errorHandeling(error) ) 
 
  
    }


    console.log("the payload is :",payload)
  }  


      //hadnel return the eval to the manager from the hr 
      const handelReturnEvalToManager = async ()=>{
   
        if(!fields.m_return_reason){  
          setError("*يرجى ملئ سبب الارجاع قبل الارسال")
          return ; }
    
        let payload = {
  
          m_return_reason : fields.m_return_reason ,
    
        }
    
        try {
          setLoadingReturnEvalToManager(true)
      
          const response = await axios.put(
            `https://emp.almatingroup.com/api/V1/evaluations/return-to-manager/${data.id}`,
            payload ,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response after submitting:', response.data);
          setLoadingReturnEvalToManager(false)
          setError(null)
          navigate("/emp/eval_emp_manager");
          
      
        } catch (error) {
          console.error('Error:', error);
          setLoadingReturnEvalToManager(false)
          setError(error.message ? error.message : errorHandeling(error) ) 
     
      
        }
    
    
        console.log("the payload is :",payload)
      } 
  

  return (
    <div className="EvaluateDraftPageForSixMonthes">


    <div className='EvaluateDraftPageForSixMonthes_container'>

      <div className='EvaluateDraftPageForSixMonthes_container_header'>
         <span className='EvaluateDraftPageForSixMonthes_container_header_text' dir='rtl'>نموذج تقييم أداء موظف :</span>
         <span className='EvaluateDraftPageForSixMonthes_container_header_note' dir='rtl'> ملاحظة :يرجى التأكد من ملئ البيانات المرفقة ب <span className='important_toFill'>*</span></span>
      </div>

      
      {
      data.status === "MANAGER_RETURN" &&
          <NoficationForReturnedEval reasonOFReturn={data.m_return_reason}/>
      }


     <div className='EvaluateDraftPageForSixMonthes_container_parts'>
      
      <div className='EvaluateDraftPageForSixMonthes_container_topPart' >

        <div className='EvaluateDraftPageForSixMonthes_container_topPart_items'>


          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp' dir='rtl' value={data.employee?.job_id} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>الرقم الوظيفي:</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp' dir='rtl' value={data.employee?.name}  readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>اسم الموظف :</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp' dir='rtl' value={data.employee?.job_position?.title} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>المسمى الوظيفي:</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp'  dir='rtl' value={data.employee?.department?.title} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>القسم:</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp'  dir='rtl' value={data.employee?.department?.manager?.name} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>اسم المسؤول المباشر :</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp' dir='rtl' value={data.employee?.qualification || " "} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>المؤهل + التخصص :</span>
          </div>

          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp'  dir='rtl' value={formatDate(data.employee?.hiring_date) || " "} readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>تارخ التعيين :</span>
          </div>


          <div className='EvaluateDraftPageForSixMonthes_container_topPart_item'>
            <input type="text" className='EvaluateDraftPageForSixMonthes_container_topPart_item_inp' value="تقييم نصف ثانوي" dir='rtl' readOnly  />
            <span className='EvaluateDraftPageForSixMonthes_container_topPart_item_label' dir='rtl'>نوع التقييم :</span>
          </div>

          </div>



      </div>




      <div className='EvaluateDraftPageForSixMonthes_container_middelPart'>
          <span className='EvaluateDraftPageForSixMonthes_container_middelPart_subHeader' dir='rtl'>تقييم المسؤول عن الموظف</span>
      
          <table className='EvaluateDraftPageForSixMonthes_table'>
                  <thead>
                 <tr className='EvaluateDraftPageForSixMonthes_header_row_table'>
                    <th>ملاحظات</th>
                    <th>درجة أداء الموظف</th>
                    <th>درجة التقييم القصوى</th>
                    <th>عامل التقييم</th>             
                 </tr>
                 </thead>

                <tbody>

                 {
                  tabelLabels.map((label , index)=>(
                    <tr   className={index+1 === tabelLabels.length ? "EvaluateDraftPageForSixMonthes_container_rowTable EvaluateDraftPageForSixMonthes_row_table_lastOne " : "EvaluateDraftPageForSixMonthes_container_rowTable" } key={index} >
                    <td  className="EvaluateDraftPageForSixMonthes_table_td_inputNote" dir="rtl" ><textarea name={`${sendTabelLabelsArr[index]}_note`}  value={fields.tabelValues[`${sendTabelLabelsArr[index]}_note`] || ''} onChange={(e)=>{handelChangeFieldsForTheTabel(e)}}></textarea></td>
                    <td  className="EvaluateDraftPageForSixMonthes_table_td_inputMinMax" dir="rtl">
                       <input      onInput={(e) => handleNumBiggerThanRange(e, label.mark)}   type="number" min="0" max={label.mark}  name={`${sendTabelLabelsArr[index]}_mark`}  value={fields.tabelValues[`${sendTabelLabelsArr[index]}_mark`] || '' } onChange={(e)=>{handelChangeFieldsForTheTabel(e)}} /></td>
                    <td  className="EvaluateDraftPageForSixMonthes_table_td_MaxValue" dir="rtl">{label.mark}</td>

                    <td dir='rtl'><PopupForEvaluationDraftExplaination reason={label.description} firstWord ={label.title}/></td>
                    </tr>
  
                  ))
                 }


                   </tbody>

            </table>

            <div className="EvaluateDraftPageForSixMonthes_container_middelPart_table_totlSum" dir='rtl'>
              <span className='EvaluateDraftPageForSixMonthes_container_middelPart_table_totlSum_text' dir='rtl'>
                المجموع النهائي من 100 :
              </span>
              <span className={
                fields.totalDegreesValue < 50 ?
                "EvaluateDraftPageForSixMonthes_finalDegreeValueDangerous"
                :
               (fields.totalDegreesValue >= 50 && fields.totalDegreesValue < 70 ) 
                ?
                "EvaluateDraftPageForSixMonthes_finalDegreeValueMidDangerous"
                :
                (fields.totalDegreesValue >= 70 && fields.totalDegreesValue < 90 ) 
                 ?
                 "EvaluateDraftPageForSixMonthes_finalDegreeValueMidSuccess"
                 :
                  "EvaluateDraftPageForSixMonthes_finalDegreeValueSuccess"
              }
              >{fields.totalDegreesValue}</span>
            </div>


            <div className='EvaluateDraftPageForSixMonthes_points' dir='rtl'>

              <div className='EvaluateDraftPageForSixMonthes_points_item'>
                <textarea rows="4" placeholder='نقاط الضعف' dir='rtl' name='m_weaknesses' value={fields.m_weaknesses || ''} onChange={(e)=>{handleChange(e)}} ></textarea>
                <span className='EvaluateDraftPageForSixMonthes_points_item_label' dir='rtl'>نقاط الضعف لدى الموظف : <span className='important_toFill'>*</span></span>
              </div>

              <div className='EvaluateDraftPageForSixMonthes_points_item'>
                <textarea rows="4" placeholder='نقاط القوة' dir='rtl' name='m_strengths' value={fields.m_strengths || ''} onChange={(e)=>{handleChange(e)}}></textarea>
                <span className='EvaluateDraftPageForSixMonthes_points_item_label'  dir='rtl'>نقاط القوة لدى الموظف : <span className='important_toFill'>*</span></span>
              </div>

              
              <div className='EvaluateDraftPageForSixMonthes_points_item'>
                <textarea rows="4" placeholder='الانطباع العام عن الموظف' dir='rtl' name='m_impression' value={fields.m_impression || ''} onChange={(e)=>{handleChange(e)}}  ></textarea>
                <span className='EvaluateDraftPageForSixMonthes_points_item_label' dir='rtl'>الانطباع العام عن الموظف خلال فترة التقييم : <span className='important_toFill'>*</span></span>
              </div>


            </div>
      
      </div>

      <div className='EvaluateDraftPageForSixMonthes_container_bottomPart'>
      



      <div className="EvaluateDraftPageForSixMonthes_container_bottomPart_item" dir='rtl'>
      <span  className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_label' dir="rtl">توصيات المسؤول المباشر : <span className='important_toFill'>*</span></span>
       
       <div className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_dropdownPart'>
        
        <div className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_to_show' onClick={()=>{setToShowManagerOpnion(prev => !prev)}} >
        <span dir="rtl" className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_to_show_text' >{fields.m_recommendations || ""}</span>
      
      {
        toShowManagerOpnion ? 
        <IoIosArrowUp className="EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_to_show_icon" />
        :
        <IoIosArrowDown  className="EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_to_show_icon" />
      }

     
      
      </div>
     
      <div className={toShowManagerOpnion ? 'EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItems EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItems_showElements' : 'EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItems'}>
      <span  onClick={()=>{handelChangeFieldsForManagerSuggestion("التثبيت بعد انتهاء فترة التجربة")}} className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItem'  dir="rtl">التثبيت بعد انتهاء فترة التجربة</span>
      <span  onClick={()=>{handelChangeFieldsForManagerSuggestion("الحصول على ترقية او زيادة راتب")}} className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItem' dir="rtl">الحصول على ترقية او زيادة راتب</span>
      <span  onClick={()=>{handelChangeFieldsForManagerSuggestion("نقل الموظف لمكان آخر")}} className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItem' dir="rtl">نقل الموظف لمكان آخر</span>
      <span  onClick={()=>{handelChangeFieldsForManagerSuggestion("انهاء خدمة الموظف")}}  className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_select_dropdownItem' dir="rtl">انهاء خدمة الموظف</span>
      </div>
      </div>
      </div>



{
  fields.m_recommendations === "الحصول على ترقية او زيادة راتب" &&

  <div className='EvaluateDraftPageForSixMonthes_container_bottomPart_item'>
  <input type='text' className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_inp' dir='rtl' name='m_money_recommended' value={fields.m_money_recommended || ''} onChange={(e)=>{handleChange(e)}} />
  <span className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_label'  dir='rtl'>النسبة المقترحة : <span className='important_toFill'>*</span></span>
</div>
}
 



      <div className='EvaluateDraftPageForSixMonthes_container_bottomPart_item'>
          {/* <input   dir='rtl' /> */}
          <span className='EvaluateDraftPageForSixMonthes_container_bottomPart_item_label'  dir='rtl'>توقيع المسؤول المباشر : <span className='important_toFill'>*</span></span>
        </div>


      </div>

   {
    (checkPermissionAndThrow("read_evaluation_settings")) &&

    <div className='EvaluationForHRDraftPage_container_HR_MangerOpinion'>
    <span className='EvaluationForHRDraftPage_container_HR_MangerOpinion_label' dir='rtl'>سبب ارجاع التقييم :</span>
     <textarea className='EvaluationForHRDraftPage_container_HR_MangerOpinion_inp' rows="4" name="m_return_reason" value={fields.m_return_reason}  onChange={(e)=>{handleChange(e)}}  dir='rtl' ></textarea>
    </div>

   }


      {
        error &&
        ( <span style={{color:"red", marginTop:"1rem", marginBotton:"1rem"}} dir= "rtl" >{error}</span> )
      }



<div className='draftEval_Six_andTestperiod_buttons'>
   {
     authMe.is_manager &&
     (
        <button className={ loading ? 'EvaluateDraftPageForSixMonthes_button_submit btn_LoadingToSave_eval' : 'EvaluateDraftPageForSixMonthes_button_submit' } onClick={()=>{handelSubmit()}} disabled={loading} dir='rtl'>
         {
         loading ?
           "يتم الحفظ ..." 
         :
           "حفظ التقييم "
         }
       </button>)  
     }
  


{
   (checkPermissionAndThrow("read_evaluation_settings")) &&
  
     <button className={ loadingReturnEvalToManager ? 'EvaluateDraftPageForSixMonthes_button_submit btn_LoadingToSave_eval' : 'EvaluateDraftPageForSixMonthes_button_submit' } onClick={()=>{handelReturnEvalToManager()}} disabled={loading | loadingReturnEvalToManager} dir='rtl'>
       {
         loadingReturnEvalToManager ?
         "يتم الارسال ..." 
         :
         "ارجاع الى مدير القسم"
       }
     </button>

     }
</div>


     </div>


    </div>




    </div>

  )
}

export default EvaluateDraftPageForSixMonthes