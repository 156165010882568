import React, {  useEffect, useState } from 'react'
import './createNewUser.scss'

import { useDispatch, useSelector } from 'react-redux'

import Navbar from '../../components/Navbar/Navbar'
import EmpInfoPersonalData from '../../components/CreateNewUserComps/EmpInfoPersonalData/EmpInfoPersonalData'
import  EmpInfoContact from '../../components/CreateNewUserComps/EmpInfoContact/EmpInfoContact'
import  EmpInfoInsurance from '../../components/CreateNewUserComps/EmpInfoInsurance/EmpInfoInsurance'
import  EmpInfoJobDes from '../../components/CreateNewUserComps/EmpInfoJobDes/EmpInfoJobDes'
import  EmpRole from '../../components/CreateNewUserComps/EmpRole/EmpRole'
import { clearCreateUserData } from '../../store/CreateUserSlice/createUserSlice'




function CreateNewUser() {

  const dispatch = useDispatch();

  const CreateCompNum = useSelector(state => state.createUser.compNum);




//  console.log("the data arr is for job postions :",jobPostionsArr)
//  console.log("the deps arr are :",departmentsArr)

 console.log("...........................................")



 useEffect(()=>{
   
  return ()=>{

    dispatch(clearCreateUserData());
  
  }

 },[dispatch])


 let sayHi = ()=>{
  if(CreateCompNum===1)
    return<> <EmpInfoPersonalData /></>;
  else if(CreateCompNum===2)
    return<>  <EmpInfoContact /></>
  else if(CreateCompNum===3)
    return<>  <EmpInfoJobDes /></>
  else if(CreateCompNum===4)
    return<>  <EmpInfoInsurance /></>
  // else if(CreateCompNum===5)
  //   return<>  <EmpRole /></>
  
}





  return (
    <div className="CreateNewUser">

      <Navbar/>
{/*     
    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div> */}

    <div className='CreateNewUser_container'>

      <div className='CreateNewUser_container_header'>
         <span className='CreateNewUser_container_header_text' dir='rtl'>انشاء موظف :</span>
         <span className='CreateNewUser_container_header_note' dir='rtl'> ملاحظة :يرجى التأكد من ملئ البيانات المرفقة ب <span className='important_toFill'>*</span></span>
      </div>

   <div className='CreateNewUser_container_topBarToShowCopms' >

    <div className={CreateCompNum===1 ? 'subElementComp_EmpInfoCreate subElementComp_open' : 'subElementComp_EmpInfoCreate'} dir='rtl' >

    <span  dir='rtl' >المعلومات الشخصية</span>
    
    </div>


<div className={CreateCompNum===2 ? 'subElementComp_EmpInfoCreate subElementComp_open' : 'subElementComp_EmpInfoCreate'} dir='rtl' >

<span   dir='rtl' >معلومات الاتصال</span>

</div>



<div className={CreateCompNum===3 ? 'subElementComp_EmpInfoCreate subElementComp_open' : 'subElementComp_EmpInfoCreate'} dir='rtl' >

<span   dir='rtl' >المعلومات الوظيفية</span>

</div>


<div className={CreateCompNum===4  ? 'subElementComp_EmpInfoCreate subElementComp_open' : 'subElementComp_EmpInfoCreate'} dir='rtl' >

<span   dir='rtl' >معلومات التأمين الاجتماعي</span>

</div>


{/* <div className={CreateCompNum===5 ? 'subElementComp_EmpInfoCreate subElementComp_open' : 'subElementComp_EmpInfoCreate'} dir='rtl' >

<span   dir='rtl' >صلاحية الموظف</span>

</div> */}


</div>



{
   sayHi()
}




    </div>

    </div>
  )
}

export default CreateNewUser