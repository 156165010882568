import React, { useEffect, useState } from 'react'

import './punshmentMangmentHRsShowComp.scss'
import PopupGfg from '../../../pages/VacationManagerShow/VacationReasonPopup'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { IoSearchSharp } from 'react-icons/io5'
import { MdOutlineCancel } from 'react-icons/md'
import { FaAngleLeft, FaAngleRight, FaRegTrashAlt } from 'react-icons/fa'
import { TailSpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

function PunshmentMangmentHRsShowComp() {

      const token = useSelector(state => state.user.token)

      const userPermissions = useSelector(selectUserPermissions);

      const navigate = useNavigate();

      const getDateFourDaysAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 4);
        return date.toISOString().split('T')[0];
      }
    
    
      const [startingDatePenField , setStartingDatePenField] = useState(getDateFourDaysAgo());
      const [endingDatePenField , setEndingDatePenField] = useState(new Date().toISOString().split('T')[0] )
      
     const [searchValue,setSearchValue] = useState("")

      const [penaltiesArr,setPenaltiesArr] = useState([])
    
      const [loadingResult,setLoadingResult] = useState(false)
      const [loadingExcelFile,setLoadingExcelFile] = useState(false);
      const [loadingCancel,setLoadingCancel] = useState(false)


      
      const [error,setError] = useState(null)
      
      const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(1);


      


      useEffect(() => {
        fetchPenalties(currentPage);
      }, [token, currentPage]);
    


          //check for permissions 

    const hasPermission = (permission) => userPermissions.includes(permission);

    const checkPermissionAndThrow = (permission) => {
      if (!hasPermission(permission)) {
        return false;
      }
      else{
        return true;
      }
    };

    

          
      const fetchPenalties = async (page) => {
        let data = {
          date_from: startingDatePenField,
        
          date_to : endingDatePenField ,
    

        };

        console.log("the data that sending to the server :",data)

        if(searchValue && searchValue!==0){

          try {
            setLoadingResult(true);
            const response = await axios.post(
              `https://emp.almatingroup.com/api/V1/penalties/search/${searchValue}?page=${page}`,
               data ,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response:', response.data.data);
            setPenaltiesArr(response.data.data.data);
            setCurrentPage(response.data.data.current_page);
            setTotalPages(response.data.data.last_page);

            // if(response.data.data.data?.length !== 0){

            // }

            setLoadingResult(false);
          } catch (error) {
            console.error('Error:', error);
            setLoadingResult(false);
       
            setError(error.message)
          }
      
      }
      else{
        try {
          setLoadingResult(true);
          const response = await axios.get(
            `https://emp.almatingroup.com/api/V1/penalties?page=${page}`,
          
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          console.log('Response:', response.data.data);
          setPenaltiesArr(response.data.data.data);
          setCurrentPage(response.data.data.current_page);
          setTotalPages(response.data.data.last_page);
  
          setLoadingResult(false);
        } catch (error) {
          console.error('Error:', error);
          setLoadingResult(false);
        
          setError(error.message)
        }
      }
    };
    
    

    
    
      let StartingDatePenFieldFunc = (e) => {
        setStartingDatePenField(e.target.value);
        console.log(e.target.value);
      }
    
      let EndingDatePenFieldFunc = (e) => {
        setEndingDatePenField(e.target.value);
        console.log(e.target.value);
      }
    
    

    

    const TruncatedText = (text, maxLength ) => {
      const truncatedText = text.length > maxLength ? `${text.split(' ')[0]}...` : text;
    
      return <>{truncatedText}</>
    };


    
const handlePageChange = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};



const renderPageNumbers = () => {
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
      >
        {i}
      </button>
    );
  }

  return pageNumbers;
};




    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    

    const handelSrearch = ()=>{
      setCurrentPage(1)
      fetchPenalties(1)
    }


    const handelCancelPenalty = async(penaltiyId)=>{
 
     if(checkPermissionAndThrow("delete_penalties")){
     
       try {
              setLoadingCancel(true)
           const response = await axios.delete(`https://emp.almatingroup.com/api/V1/penalties/${penaltiyId}`,
             {
               headers: {
                   Authorization: `Bearer ${token}`
               }
           });
           // const { DepName, DepManger, DepParent } = response.data.data;
           // setDepName(DepName);
           // setDepManger(DepManger);
           // setDepParent(DepParent);
      
           // console.log("the dep after fetching is:",response.data)
           setLoadingCancel(false)
           
           fetchPenalties(currentPage)
          //  setChangeableStateForChangingVaca(prev =>(!prev))
           
       } catch (error) {
           console.error("Error fetching department data:", error);
           setLoadingCancel(false)
           setError(error.message)
       }
     

    }
  }


    const handelAddNewPenalty = ()=>{
      navigate("/emp/addPunshment");
    }


    const handelShowByDatePenalties = ()=>{
      
    // Convert string dates to Date objects for comparison
    const startDate = new Date(startingDatePenField);
    const endDate = new Date(endingDatePenField);
    const today = new Date();
    
   // Set the time part of the dates to 00:00:00 for comparison
   startDate.setHours(0, 0, 0, 0);
   endDate.setHours(0, 0, 0, 0);
   today.setHours(0, 0, 0, 0);

    console.log("the today is :",today)
    console.log("the starting date is :",startDate)





    // Check if the starting date is after the ending date
    if (startDate > endDate) {
      setError(" *تاريخ البداية يجب ان يكون اصغر من تاريخ نهاية العقوبة");

      return; // Prevent further execution
    }
    else if(startDate > today){
      setError("*تاريخ البداية  يجب أن يحدد ابتداءا من اليوم  وما قبله");

      return; // Prevent further execution
    }
    else if(endDate > today){
      setError("*تاريخ النهاية  يجب أن يحدد ابتداءا من اليوم  وما قبله");

      return; // Prevent further execution
    }
    else {
      setError(""); // Clear any previous errors
      // Proceed with form submission logic here
      setCurrentPage(1);
      fetchPenalties(1);


    }

    }



  return (
    <div className='PunshmentMangmentHRsShowComp'>

    <div className="ManagmentPunshmentsByHRs_search">
      <button className="ManagmentPunshmentsByHRs_search_btn" onClick={()=>{handelSrearch()}} >
        <IoSearchSharp className='ManagmentPunshmentsByHRs_search_icon_react'   />
        </button>
        <input type="text" placeholder='الرقم الوظيفي' dir="rtl" className='ManagmentPunshmentsByHRs_search_inp' 
        value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}}/>
    
      </div>

    <div className='PunshmentMangmentHRsShowComp_Note_div'>
        <span className='PunshmentMangmentHRsShowComp_Note_word' dir='rtl'>ملاحظة :</span>
        <span className='PunshmentMangmentHRsShowComp_Note_text' dir='rtl'>افتراضيا يتم عرض الصادرة من أربع ايام الى تاريخ هذا اليوم .</span>
        
    </div>
    
    <div className='PunshmentMangmentHRsShowComp_Time_filter_div'>
      <div className='PunshmentMangmentHRsShowComp_Time_section'>
        <div className='PunshmentMangmentHRsShowComp_Time_section_item'>
             <span dir='rtl'>الى</span>
             <input type='date' dir="rtl" value={endingDatePenField} onChange={(e)=>{EndingDatePenFieldFunc(e)}} />
        </div>
        <div className='PunshmentMangmentHRsShowComp_Time_section_item' dir='rtl'>
        <    span dir='rtl'>من</span>
             <input type='date' dir="rtl" value={startingDatePenField} onChange={(e)=>{StartingDatePenFieldFunc(e)}}  />


        </div>
      </div>

      {error && <p className='penaltiesRequestEmp_errorInDateMessage' dir="rtl">{error}</p>} 
      
      <div className='PunshmentMangmentHRsShowComp_button_section'>
      <button className="PunshmentMangmentHRsShowComp_download_excel_file active_button_addPenalty"  onClick={()=>{handelAddNewPenalty()}}  dir='rtl'>اضافة عقوبة</button>
        <button className='PunshmentMangmentHRsShowComp_show_results' dir='rtl' onClick={()=>{handelShowByDatePenalties()}}>عرض النتائج</button>
      </div>
    
    </div>
    
    <div className='PunshmentMangmentHRsShowComp_containerForTable'>
    
    <span className='PunshmentMangmentHRsShowComp_header' dir="rtl">العقوبات</span>
    {
      loadingResult 
      ?
      <>
        <div className='spinner_getData'>
          <TailSpin
              visible={true}
              height="40"
              width="40"
              color="rgb(246,229,56)"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
                />
        </div> 
      </>
      :
      <>
          <table className='PunshmentMangmentHRsShowComp_table'>
      <thead>
    <tr className='PunshmentMangmentHRsShowComp_header_row_table'>
      {
        checkPermissionAndThrow("delete_penalties") &&
         <th>حذف</th>
      }

       <th>تاريخ العقوبة</th>
       <th>ملاحظة</th>
       <th>سبب الخصم</th>
      <th>مقدار الخصم</th>
      <th>الاسم</th>
      <th>الرقم الوظيفي</th>
    </tr>
    </thead>
    
    <tbody>
      { penaltiesArr.map(
        obj =>(
          <tr key={obj.id}>

      {
        checkPermissionAndThrow("delete_penalties") &&
        <td dir="rtl">
          
        <button onClick={()=>{handelCancelPenalty(obj.id)}} disabled={loadingResult || loadingCancel} className='trash_button_penalty'>
        {
        loadingCancel 
        ?
        <div className='spinner_deletePenalty_button'>
        <TailSpin
        visible={true}
        height="20"
        width="20"
        color="rgb(246,229,56)"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
          />
        </div> 
        :
        <>
         <FaRegTrashAlt className='trash_button_penalty_icon'/>
        </>
      }
       </button>    
   </td> 
      }

         <td dir="rtl">{formatDate(obj.created_at)}</td>
         <td dir="rtl"><PopupGfg reason={obj.notes || ""} firstWord = {TruncatedText(obj.notes || "",10)}/></td>
         <td dir="rtl"><PopupGfg reason={obj.reason || ""} firstWord = {TruncatedText(obj.reason || "",10)}/></td>
         <td dir="rtl">{
                 obj.type === "PERCENTAGE"
                 ?
                 <>{Math.floor(obj.value)}%</>
                 :
                 <>{Math.floor(obj.value)}</>
                }
                </td>
       <td dir="rtl">{obj.employee?.name}</td>
       <td dir="rtl">{obj.employee?.job_id}</td>
    </tr>
        )
      )}
    </tbody>
    
    </table>


    {
      loadingResult ? 
      <>
      </>
      :
      <>
      <div className="pagination_penalties">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>
      
      </>
    }
      </>
    }
                

    
    </div>
    </div>
  )
}

export default PunshmentMangmentHRsShowComp