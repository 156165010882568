import React, { useEffect, useState } from 'react'

import './empRole.scss'
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BsPersonCheck } from 'react-icons/bs';
import { createSelector } from 'reselect';
import { errorHandeling } from '../../../errorHandeling';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function EmpRole({empInfo}) {

    const token = useSelector(state => state.user.token)

    const authMe = useSelector(state => state.authme.data);

    const userPermissions = useSelector(selectUserPermissions);

    const [roleNames , setRoleNames ] = useState([])

    const [empRoleName , setEmpRoleName] = useState("")

    const [loading,setLoading] = useState(false)
   
    const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)
  
    const [error,setError] = useState(null)


    useEffect(() => {
        // Assuming fetchData() is a function that fetches your data from the server
        const fetchData = async () => {
            const response = await axios.get(
                `https://emp.almatingroup.com/api/V1/roles`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                }
              );
          const data = await response.data.data;

          console.log("the data after fetching are :",data)
          
          // Extract role names from the fetched data
          const names = data.map(role => role.name);
          setRoleNames(names);
        };
    
        fetchData();

        setEmpRoleName(empInfo?.roles[0] ? empInfo?.roles[0]?.name : "UNKNOWN")

        console.log("the empInfo is : ",empInfo)
      }, []);


      useEffect(()=>{
        console.log("the roles names are :",roleNames)
      },[roleNames])


          //check for permissions 

    const hasPermission = (permission) => userPermissions.includes(permission);

    const checkPermissionAndThrow = (permission) => {
      if (!hasPermission(permission)) {
        return false;
      }
      else{
        return true;
      }
    };


  const handelChangeRolesForEmp = (e)=>{
    let newRole = e.target.value ; 
    
    setEmpRoleName(newRole);
  }
  
    
  const handleSubmit = async (e)=>{
    e.preventDefault();


    
   if(checkPermissionAndThrow("update_roles")){

    console.log("the emp roleName is :",empRoleName)

    if(!loading && !(empRoleName === "UNKNOWN")){

    console.log("we are submitting the data")

    let data = {
        // ...empInfo,
        // ...fields,
       roles : [empRoleName]

        // department_id:empInfo.department ?  empInfo.department.id: 1,
        // job_position_id:empInfo.job_position ?  empInfo.job_position.id : 1

    } 

    console.log("the data is after forming it:",data)
    

    try {
        setLoading(true)
    
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/users/${empInfo?.job_id}/assign-roles`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response after submitting:', response.data);
        setLoading(false)
        setSaveChangesSuccessfully(true)
    
      } catch (error) {
        console.error('Error:', error);
        setLoading(false)
        setSaveChangesSuccessfully(false)
        setError(errorHandeling(error))
      }
  
    }
}

}

console.log("the empinfo is :",empInfo)

  return (
    <div className='EmpRole'>

    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }




        <div className='EmpRole_container'>

        <div className="EmpsMangment_empRole_search_result_table_component_items">

        <div className="EmpsMangment_empRole_search_result_table_component_item">
      <label dir="rtl">صلاحية الموظف</label>
      <select dir="rtl" className='EmpsMangment_selection' value={empRoleName} onChange={(e)=>{handelChangeRolesForEmp(e)}} >
           <option value="UNKNOWN" dir="rtl">لم يتم التحديد</option>
        {
            roleNames && roleNames.map((role,index)=>(
                <option value={role} key={index} dir="rtl">{role}</option>
            ))
        }
      {/* <option value="Manger_Developer" dir="rtl">مطور النظام</option>
      <option value="General_Adminstration" dir="rtl">ادارة عامة</option>
      <option value="Head_Department_Hr" dir="rtl">رئيس قسم -Hr</option>
      <option value="Head_Department_IT" dir="rtl">رئيس قسم -IT</option>
      <option value="Head_Department" dir="rtl">رئيس قسم</option>
      <option value="Following_Admistrator" dir="rtl">مسؤول متابعة -Hr</option>
      <option value="Evaluation_Adminstartor" dir="rtl">مسؤول تقييم -Hr</option>
      <option value="Salary_Adminstartor" dir="rtl">مسؤول رواتب -Hr</option>
      <option value="Employing_Adminstrator" dir="rtl">مسؤول توظيف -Hr</option>
      <option value="Employee" dir="rtl">موظف</option> */}
     </select>
      </div>


      </div>

      {error &&  
      
      <span className='error_in_saving' style={{ color: 'red'}}>{error}</span>
      }

    <button dir="rtl" type='submit' onClick={(e)=>{handleSubmit(e)}} disabled={loading | (empRoleName === "UNKNOWN")}>
        {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>حفظ</>
        }</button>

        </div>
    </div>
  )
}

export default EmpRole