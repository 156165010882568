import React from 'react'
import './footer.scss'

function Footer() {
  return (
    <div className='footer'>
        <div className='text_footer'>
          <div className='text_footer_name_section' dir='rtl'>
          <span dir='rtl'>تصميم و برمجة م. صالح الحايك و م.علاء الدين عاصي </span>
          <span dir='rtl'>من قسم التقانة لمجموعة المتين</span>
          </div>

           <span><a href="#">Almatin.com</a>&copy; 2024</span>
        </div>
    </div>
  )
}

export default Footer