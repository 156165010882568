import './evaluationForHRDraftPage.scss'

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import {extractDataForTabelFromTheObject} from '../extractDataForTabelFromTheObject';
import {errorHandeling} from "../../../../errorHandeling"
import PopupForEvaluationDraftExplaination from '../PopupForEvaluationDraftExplaination/PopupForEvaluationDraftExplaination';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { createSelector } from 'reselect';
import NoficationForReturnedEval from '../NoficationForReturnedEval/NoficationForReturnedEval';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function EvaluationForHRDraftPage({data , tableRowsLabels }) {

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const navigate = useNavigate();

    console.log("the data is :",data);

    const [fields,setFields] = useState({
      tabelValues : {
        "hr_first_mark" : data.hr_first_mark || null,
        "hr_first_notes": data.hr_first_notes || null,
        "hr_second_mark": data.hr_second_mark || null,
        "hr_second_notes": data.hr_second_notes || null,
        "hr_third_mark": data.hr_third_mark || null,
        "hr_third_notes": data.hr_third_notes || null,
        "hr_fourth_mark": data.hr_fourth_mark || null,
        "hr_fourth_notes": data.hr_fourth_notes || null,
        "hr_fifth_mark": data.hr_fifth_mark || null,
        "hr_fifth_notes": data.hr_fifth_notes || null,
        "hr_sixth_mark": data.hr_sixth_mark || null,
        "hr_sixth_notes": data.hr_sixth_notes || null,
        "hr_seventh_mark": data.hr_seventh_mark || null,
        "hr_seventh_notes": data.hr_seventh_notes || null,
        "hr_eighth_mark": data.hr_eighth_mark || null,
        "hr_eighth_notes": data.hr_eighth_notes || null,
        "hr_ninth_mark": data.hr_ninth_mark || null,
        "hr_ninth_notes": data.hr_ninth_notes || null,
        "hr_tenth_mark": data.hr_tenth_mark || null,
        "hr_tenth_notes": data.hr_tenth_notes || null,
      } ,
        totalDegreesValue : 0 ,
        hr_courses : data.hr_courses || null ,
        hr_return_reason : data.hr_return_reason || null , 
        hr_notes_and_recommendations : data.hr_notes_and_recommendations || null , 
    
      })
      


      const [tabelLabels , setTabelLabels] = useState([]);
    
      const [loading , setLoading ] = useState(false);

      const [loadingToReturnEval , setLoadingToReturnEval] = useState(false);

      const [loadinToAcceptEvalFromHrManager , setLoadinToAcceptEvalFromHrManager] = useState(false);
    
      const [error , setError] = useState(null);
    
    
      const handleChange = (e) => {
        const { name, value } = e.target;
    
        setFields((prevFields) => ({
          ...prevFields,
          [name]: value
        }));
      };



      const handleNumBiggerThanRange = (event, maxRange) => {
        const inputElement = event.target;
        const value = parseFloat(inputElement.value);
    
        if (value > maxRange) {
          inputElement.classList.add('danger_aboveTheMax');
        } else {
          inputElement.classList.remove('danger_aboveTheMax');
        }
      };

      
        //for the table


//I think there are over rendering happeing here so be crazy and look at it

  useEffect(()=>{
    const setDataForTable = ()=>{
    //get the semiAnnual table labels 
    let HR = tableRowsLabels?.find(item => item.type === "HR");
  
    console.log("the HR is :",HR);
  
    setTabelLabels(extractDataForTabelFromTheObject(HR));
  
   
    }
  
    setDataForTable();
  
  },[tableRowsLabels])

  useEffect(()=>{
    console.log("the tabel labels rows :",tableRowsLabels)
  },[tableRowsLabels])
  
  
  useEffect(()=>{
    console.log("the tabellabels is:",tabelLabels)
  },[tabelLabels])
  


  const  sendTabelLabelsArr = ["hr_first","hr_second","hr_third","hr_fourth","hr_fifth","hr_sixth","hr_seventh","hr_eighth"
    ,"hr_ninth" , "hr_tenth" ];

    const  tabelLabelsManagerArr = ["m_first","m_second","m_third","m_fourth","m_fifth","m_sixth","m_seventh","m_eighth"
      ,"m_ninth" , "m_tenth" ];





    const handelChangeFieldsForTheTabel = (e)=>{
      const { name, value } = e.target;

      setFields((prevFields) => ({
        ...prevFields,
        ["tabelValues"]: {
          ...prevFields.tabelValues,
          [name] : value ,
        }
      }));
    }


    useEffect(()=>{
      console.log("the fields is:",fields)
    },[fields])


    useEffect(()=>{
      const getTheSum = () => {
        let sum = 0; // Initialize sum outside the loop to accumulate the total correctly
    
        for (let i = 0; i < 10; i++) {
          const mark = parseInt(fields.tabelValues[`${sendTabelLabelsArr[i]}_mark`], 10); // Convert the mark to an integer
    
      // Handle null values by considering them as 0
      if (!Number.isInteger(mark)) {
        sum += 0; // Add 0 to the sum if the mark is not a valid integer
      } else {
        sum += mark; // Accumulate the valid integer
      }

        }
    
        setFields((prevFields) => ({
          ...prevFields,
          ["totalDegreesValue"]:sum ,

        }));


      };
    
      getTheSum(); // Call the function to calculate the sum
    },[fields.tabelValues])


       // to format the date 

  const formatDate = (theDate) => {
    const date = new Date(theDate);
    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    
    // Return the formatted date
    return `${month}/${day}/${year}`;
  };



    //function to get the reult of manager evaluation
     const managerEvalTotal = ()=>{
          let sum = 0 ;
          
          for(let i = 0 ; i<10 ; i++){
            sum += (data[`${tabelLabelsManagerArr[i]}_mark`] || 0)
          }
          
          return sum ; 
        }

    
    //function to get the reult of all evaluation
    const evalTotalValue = ()=>{
          
         let mangerValue = managerEvalTotal();

         let theTotalValue = (mangerValue + fields.totalDegreesValue)/2;
          
          return theTotalValue ; 
        }




//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};
     


  //validate the data before the submitting 
  const validateData = ()=>{
    for(let i = 0 ; i < tabelLabels.length ; i++){
      
      if( !fields.tabelValues[`${sendTabelLabelsArr[i]}_mark`]  ){
       setError("*يرجى التأكد من ملئ جميع حقول درجة أداء الموظف");
       return false ; 
      }
    }

    if(fields.totalDegreesValue < 0 || fields.totalDegreesValue > 100 ){
      setError("*يرجى التأكد من أن مجموع درجات الموظف ليس أكبر من 100");
      return false ; 
    }

     setError(null);

     return true;
  }



  //onSubmit 
  const handelSubmit = async ()=>{
   
    if(!validateData()){  return ; }

    let payload = {
      ...fields.tabelValues,
      hr_courses : fields.hr_courses,

    }

    
    try {
      setLoading(true)
  
      const response = await axios.put(
        `https://emp.almatingroup.com/api/V1/evaluations/hr/${data.id}`,
        payload ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)
      setError(null)
      navigate("/emp/eval_emp_manager");
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      setError(error.message ? error.message : errorHandeling(error) ) 
 
  
    }


    console.log("the payload is :",payload)
  }  



  
  //Return Eval to the HR emp
  const handelReturnEvalToTheHr = async ()=>{

    if(!fields.hr_return_reason){
      setError("*يرجى ملئ حقل سبب الارجاع قبل الارسال ");
      return ;
    }

    let payload = {
      hr_return_reason : fields.hr_return_reason,

    }

    
    try {
      setLoadingToReturnEval(true)
  
      const response = await axios.put(
        `https://emp.almatingroup.com/api/V1/evaluations/return-to-hr/${data.id}`,
        payload ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoadingToReturnEval(false)
      setError(null)
      navigate("/emp/eval_emp_manager");
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoadingToReturnEval(false)
      setError(error.message ? error.message : errorHandeling(error) ) 
 
  
    }


    console.log("the payload is :",payload)
  }  

    
    //Accept eval from the HR manager
    const handelAcceptTheEvalFromHrManager = async ()=>{
   
      if(!fields.hr_notes_and_recommendations){
        setError("*يرجى ملئ حقل ملاحظات و توصيات قبل الارسال ");
        return ;
      }
  
      let payload = {
        hr_notes_and_recommendations : fields.hr_notes_and_recommendations
  
      }
  
      
      try {
        setLoadinToAcceptEvalFromHrManager(true)
    
        const response = await axios.put(
          `https://emp.almatingroup.com/api/V1/evaluations/hr-manager/${data.id}`,
          payload ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response after submitting:', response.data);
        setLoadinToAcceptEvalFromHrManager(false)
        setError(null)
        navigate("/emp/eval_emp_manager");
        
    
      } catch (error) {
        console.error('Error:', error);
        setLoadinToAcceptEvalFromHrManager(false)
        setError(error.message ? error.message : errorHandeling(error) ) 
   
    
      }
  
  
      console.log("the payload is :",payload)
    }  
  

  return (
    <div className='EvaluationForHRDraftPage'>


        <div className='EvaluationForHRDraftPage_container'>

            <span className='EvaluationForHRDraftPage_container_header' dir='rtl'>نموذج تقييم الموارد البشرية</span>
        

            {
           data.status === "HR_RETURN" &&
          <NoficationForReturnedEval reasonOFReturn={data.hr_return_reason}/>
           }

            
<div className='EvaluationForHRDraftPage_container_topPart' >

<div className='EvaluationForHRDraftPage_container_topPart_items'>


  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={data.employee?.job_id} readOnly dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>الرقم الوظيفي:</span>
  </div>

  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={data.employee?.name} readOnly dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>اسم الموظف :</span>
  </div>

  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={data.employee?.job_id} readOnly dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>المسمى الوظيفي:</span>
  </div>

  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={data.employee?.department?.title} readOnly  dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>القسم:</span>
  </div>

  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={data.employee?.job_id} readOnly  dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>اسم المسؤول المباشر :</span>
  </div>


  <div className='EvaluationForHRDraftPage_container_topPart_item'>
    <input type="text" className='EvaluationForHRDraftPage_container_topPart_item_inp' value={formatDate(data.employee?.hiring_date)} readOnly  dir='rtl' />
    <span className='EvaluationForHRDraftPage_container_topPart_item_label' dir='rtl'>تارخ التعيين :</span>
  </div>



  </div>




</div>




<span className='EvaluationForHRDraftPage_container_Subheader' dir='rtl'>تقييم الموارد البشرية</span>
 
            
            
            <table className='EvaluationForHRDraftPage_table'>
                  <thead>
                 <tr className='EvaluationForHRDraftPage_header_row_table'>
                    <th>ملاحظات</th>
                    <th>درجة أداء الموظف</th>
                    <th>درجة التقييم القصوى</th>
                    <th>عامل التقييم</th>             
                 </tr>
                 </thead>

                <tbody>


                {
                  tabelLabels.map((label , index)=>(
                    <tr   className={index+1 === tabelLabels.length ? "EvaluationForHRDraftPage_container_rowTable EvaluationForHRDraftPage_row_table_lastOne " : "EvaluationForHRDraftPage_container_rowTable" } key={index} >
                    <td  className="EvaluationForHRDraftPage_table_td_inputNote" dir="rtl" ><textarea name={`${sendTabelLabelsArr[index]}_note`}  value={fields.tabelValues[`${sendTabelLabelsArr[index]}_note`] || ''} onChange={(e)=>{handelChangeFieldsForTheTabel(e)}}></textarea></td>
                    <td  className="EvaluationForHRDraftPage_table_td_inputMinMax" dir="rtl">
                       <input      onInput={(e) => handleNumBiggerThanRange(e, label.mark)}   type="number" min="0" max={label.mark}  name={`${sendTabelLabelsArr[index]}_mark`}  value={fields.tabelValues[`${sendTabelLabelsArr[index]}_mark`] || '' } onChange={(e)=>{handelChangeFieldsForTheTabel(e)}} /></td>
                    <td  className="EvaluationForHRDraftPage_table_td_MaxValue" dir="rtl">{label.mark}</td>

                    <td dir='rtl'><PopupForEvaluationDraftExplaination reason={label.description} firstWord ={label.title}/></td>
                    </tr>
  
                  ))
                 }



                   </tbody>

            </table>

            <div className="EvaluationForHRDraftPage_container_middelPart_table_totlSum" dir='rtl'>
              <span className='EvaluationForHRDraftPage_container_middelPart_table_totlSum_text' dir='rtl'>
                المجموع النهائي من 100 :
              </span>
              <span className={
                fields.totalDegreesValue < 50 ?
                "EvaluationForHRDraftPage_finalDegreeValueDangerous"
                :
               (fields.totalDegreesValue >= 50 && fields.totalDegreesValue < 70 ) 
                ?
                "EvaluationForHRDraftPage_finalDegreeValueMidDangerous"
                :
                (fields.totalDegreesValue >= 70 && fields.totalDegreesValue < 90 ) 
                 ?
                 "EvaluationForHRDraftPage_finalDegreeValueMidSuccess"
                 :
                  "EvaluationForHRDraftPage_finalDegreeValueSuccess"
              }
              >{fields.totalDegreesValue}</span>
            </div>

        
        <span className='EvaluationForHRDraftPage_container_Subheader' dir='rtl'>الدورات التدريبية المنجزة المقدمة من الشركة</span>
        
        <div className='EvaluationForHRDraftPage_container_courses'>
        <span className='EvaluationForHRDraftPage_container_courses_label' dir='rtl'>الدورات المنجزة :</span>
        <textarea className='EvaluationForHRDraftPage_container_courses_inp' rows="4" name="hr_courses" value={fields.hr_courses}  onChange={(e)=>{handleChange(e)}}  dir='rtl' ></textarea>
        </div>
                     

<span className='EvaluationForHRDraftPage_container_Subheader' dir='rtl'>مجموع التقييمين</span>

<div className='EvaluationForHRDraftPage_container_FinalEvalDegrees'>
   <div className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item'>
      <input type="text" className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_inp' placeholder='0' value={managerEvalTotal()} readOnly dir='rtl' />
      <span className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_label' dir='rtl'>تقييم المسؤول المباشر: </span>
   </div>

   <div className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item'>
      <input type="text" className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_inp' placeholder='0' value={fields.totalDegreesValue || 0} readOnly dir='rtl'/>
      <span className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_label' dir='rtl'>تقييم ادارة الموارد البشرية: </span>
   </div>

   <div className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item'>
      <input type="text" className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_inp' placeholder='0' value={evalTotalValue()} readOnly dir='rtl'  />
      <span className='EvaluationForHRDraftPage_container_FinalEvalDegrees_item_label' dir='rtl'>درجة التقييم النهائي: </span>
   </div>
</div>

{
  checkPermissionAndThrow("hr_manager") &&
  (
  <>
    <span className='EvaluationForHRDraftPage_container_Subheader' dir='rtl'>رأي ادارة الموارد البشرية</span>

   <div className='EvaluationForHRDraftPage_container_HR_MangerOpinion'>
   <span className='EvaluationForHRDraftPage_container_HR_MangerOpinion_label' dir='rtl'>ملاحظات و توصيات و الاجراءات الواجب اتخاذها : </span>
   <textarea className='EvaluationForHRDraftPage_container_HR_MangerOpinion_inp' rows="4" name="hr_notes_and_recommendations" value={fields.hr_notes_and_recommendations}  onChange={(e)=>{handleChange(e)}}  dir='rtl' ></textarea>
   </div>


  <div className='EvaluationForHRDraftPage_container_HR_MangerOpinion'>
  <span className='EvaluationForHRDraftPage_container_HR_MangerOpinion_label' dir='rtl'>في حال ارجاع التقييم يرجى ذكر السبب :</span>
  <textarea className='EvaluationForHRDraftPage_container_HR_MangerOpinion_inp' rows="4"  name="hr_return_reason" value={fields.hr_return_reason}  onChange={(e)=>{handleChange(e)}}  dir='rtl' ></textarea>
  </div>

  </>
  )
}

     
     {
        error &&
        ( <span style={{color:"red", marginTop:"1rem", marginBotton:"1rem"}} dir= "rtl" >{error}</span> )
      }




    {
      !checkPermissionAndThrow("hr_manager") //if someone is hr but not hr-manager show him the save else show him buttons for hr-manager
           ?

    <button className={ loading ? 'EvaluationForHRDraftPage_container_button_submit btn_LoadingToSave_eval' : 'EvaluationForHRDraftPage_container_button_submit'} dir='rtl' onClick={()=>{handelSubmit()}}  disabled={loading | loadingToReturnEval | loadinToAcceptEvalFromHrManager} >
    {
          loading ?
          "يتم الحفظ ..." 
          :
          "حفظ التقييم "
        }
    </button>

          :     

<>
  <div className='buttons_ForHrManager_eval'>
  <button className={ loadingToReturnEval ? 'EvaluationForHRDraftPage_container_button_submit btn_LoadingToSave_eval' : 'EvaluationForHRDraftPage_container_button_submit'} dir='rtl' onClick={()=>{handelReturnEvalToTheHr()}}  disabled={loading | loadingToReturnEval | loadinToAcceptEvalFromHrManager} >
    {
          loadingToReturnEval ?
          "يتم الارجاع ..." 
          :
          "ارجاع الى Hr"
        }
  </button>

  <button className={ loadinToAcceptEvalFromHrManager ? 'EvaluationForHRDraftPage_container_button_submit btn_LoadingToSave_eval' : 'EvaluationForHRDraftPage_container_button_submit'} dir='rtl' onClick={()=>{handelAcceptTheEvalFromHrManager()}}  disabled={loading | loadingToReturnEval | loadinToAcceptEvalFromHrManager} >
    {
          loadinToAcceptEvalFromHrManager ?
          "يتم الحفظ ..." 
          :
          "الموافقة على التقييم"
        }
  </button>

  </div>
  </>
}

        </div>
    </div>
  )
}

export default EvaluationForHRDraftPage