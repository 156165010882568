import React, { useEffect, useState } from 'react'
import './restpapers.scss'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { BsPersonCheck } from 'react-icons/bs';
import { createSelector } from 'reselect';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

function RestPapers({empInfo}) {

  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);

  const [fields, setFields] = useState({
    job_id : empInfo?.job_id,
    id_photo : empInfo.papers ? empInfo.papers.id_photo : false,
    residence_document: empInfo.papers ? empInfo.papers.residence_document : false,
    no_conviction: empInfo.papers ? empInfo.papers.no_conviction : false,
    individual_civil_record: empInfo.papers ? empInfo.papers.individual_civil_record : false,
    personal_photos : empInfo.papers ? empInfo.papers.personal_photos : false,
    certificate_copy: empInfo.papers ? empInfo.papers.certificate_copy : false,
    medical_report: empInfo.papers ? empInfo.papers.medical_report : false,
    military_notebook: empInfo.papers ? empInfo.papers.military_notebook : false
  });

  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)
  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)

  useEffect(() => {



 }, []);


 console.log("the fileds from the useEffect",fields)

 console.log("the photo of the id :",empInfo.papers)


     //check for permissions 

     const hasPermission = (permission) => userPermissions.includes(permission);

     const checkPermissionAndThrow = (permission) => {
       if (!hasPermission(permission)) {
         return false;
       }
       else{
         return true;
       }
     };


 const handleChange = (e) => {
  const { name, value } = e.target;

  let convertedValue = value === 'true' ? true : value === 'false' ? false : false;

  setFields((prevFields) => ({
    ...prevFields,
    [name]: convertedValue
  }));
};


  let handleSubmit = async (e)=>{
    e.preventDefault();

    if(checkPermissionAndThrow("update_papers")){

    console.log("You have clicked the submit button")
    console.log("THE DATA WE HAVE IT IS :",fields)


        if(empInfo.papers){
          //if there are papers before then update them

          try {
            setLoading(true)
        
            const response = await axios.put(
              `https://emp.almatingroup.com/api/V1/papers/${empInfo?.job_id}`,
              fields,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response after submitting:', response.data);
            setLoading(false)
            setSaveChangesSuccessfully(true)
        
          } catch (error) {
            console.error('Error:', error);
            setError(error.message && error.message)
            setLoading(false)
            setSaveChangesSuccessfully(false)
        
          }

        }
        else{
           //store the papers if there is no papers(null) before

           try {
            setLoading(true)
        
            const response = await axios.post(
              `https://emp.almatingroup.com/api/V1/papers`,
              fields,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response after submitting:', response.data);
            setLoading(false)
            setSaveChangesSuccessfully(true)
        
          } catch (error) {
            console.error('Error:', error);
            setError(error.message && error.message)
            setLoading(false)
            setSaveChangesSuccessfully(false)
        
          }
        }

    

      
   }
  }



  return (
    <div className="RestPapers">
    
    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo?.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>



    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }



    <form onSubmit={handleSubmit}>

   <div className="EmpsMangment_search_result_table_component_items">

    <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صورة عن الهوية</label>
      <select dir="rtl" className='EmpsMangment_selection' name="id_photo" value={fields.id_photo} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>

  </select>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">سند الإقامة</label>
      <select dir="rtl" className='EmpsMangment_selection' name="residence_document" value={fields.residence_document} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>

  </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">لا حكم عليه</label>
      <select dir="rtl" className='EmpsMangment_selection' name="no_conviction" value={fields.no_conviction} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">اخراج قيد فردي</label>
      <select dir="rtl" className='EmpsMangment_selection' name="individual_civil_record" value={fields.individual_civil_record} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صور شخصية</label>
      <select dir="rtl" className='EmpsMangment_selection' name="personal_photos" value={fields.personal_photos} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صورة عن الشهادة العلمية</label>
      <select dir="rtl" className='EmpsMangment_selection' name="certificate_copy" value={fields.certificate_copy} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تقرير طبي</label>
      <select dir="rtl" className='EmpsMangment_selection' name="medical_report" value={fields.medical_report} onChange={handleChange}>
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صورة عن دفتر العلم</label>
      <select dir="rtl" className='EmpsMangment_selection' name="military_notebook" value={fields.military_notebook} onChange={handleChange} >
      <option value={false} dir="rtl">لم يتم التسليم</option>
      <option value={true} dir="rtl">تم التسليم</option>
  </select>
      </div>

      </div>

      {error ? 
       <span>{error}</span>
      :
       <></>  
      }
     
     {
      checkPermissionAndThrow("update_papers") &&
      <button dir="rtl" type='submit' onClick={(e)=>{handleSubmit(e)}} disabled={loading}>
      {
     loading ? 
      <>
        <TailSpin
        visible={true}
        height="22"
        width="22"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </>
      :
      <>حفظ</>
      }</button>
     }


      
   </form>

    </div>
  )
}

export default RestPapers