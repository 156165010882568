import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './vacationReasonPopup.scss'
 
export default function PopupGfg({reason,firstWord}) {

    // console.log("the first word:",firstWord)
    return (
        <div>
          
            <Popup trigger=
                {  <h4 className='text_inPopUp' dir='rtl'>{firstWord}</h4>}
                position="bottom center">
                <div className='VacationReasonPopup_div_container' dir='rtl'><p dir='rtl'>{reason}</p></div>
            </Popup>
        </div>
    )
};