import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'

import "./tabelForTheStartTestPeriodEvaluation.scss"
import axios from 'axios'
import { useSelector } from 'react-redux'

function TabelForTheStartTestPeriodEvaluation({data}) {

    const token = useSelector(state => state.user.token)
 
    const [empsData,setEmpsData] = useState([])

    const [loading,setLoading] = useState(false)

    
  const [error,setError] = useState(null)

    
    useEffect(()=>{
       setEmpsData(data) 
    },[data])




    const handelBeginEvalClicked = async (id) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/evaluations/start`,
          {
            job_id : id
          },
       
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        
        console.log("the respanse :",response);

        setLoading(false);
   
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        setError(error.message)
    
      }
  
    
    };
  



  return (
    <div className='TabelForTheStartTestPeriodEvaluation'>
        {/* <span className={depNameClicked ? 'AccordionForEvaluationSectionStatistics_depName AccordionForEvaluationSectionStatistics_depName_open' : 'AccordionForEvaluationSectionStatistics_depName' } onClick={()=>{handleDepNameClicked()}}>{depName && depName}</span> */}
         

         {
          error &&
          (<span style={{color:"red"}}>{error}</span>)
         }
         
         <div className= "TabelForTheStartTestPeriodEvaluation_show">

        <table className={ "TabelForTheStartTestPeriodEvaluation_table"} >
        <thead>
        <tr className='TabelForTheStartTestPeriodEvaluation_header_row_table'>
          <th dir='rtl'>بدء التقييم</th>
          <th>حالة التقييم</th>
          <th>القسم</th>
          <th>الاسم</th>
        </tr>
        </thead>


    <tbody>

          {  empsData?.map(
            obj =>(
              <tr  key={obj.id} className='TabelForTheStartTestPeriodEvaluation_table_rowShowDataTable'>
              <td>
        

    
            <div className='TabelForTheStartTestPeriodEvaluation_table_button'>
            {
                loading ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="20"
               width="20"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
          :
         <>
              <button   onClick={()=>{handelBeginEvalClicked(obj.employee.job_id)}}  disabled={loading} dir='rtl'>
                بدء
              </button>
         
         </>
        }

            </div>
                    

           </td>
           <td dir="rtl">تقييم فترة تجريبية</td>
           <td dir="rtl">{obj.employee?.department?.title}</td>
           <td dir="rtl">{obj.employee.name}</td>
        </tr>

            )
          )}
        </tbody>

    </table>

    </div>

    </div>
  )
}

export default TabelForTheStartTestPeriodEvaluation

