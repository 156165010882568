import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'

import "./tabelForTheWaitingsEvaluations.scss"
import { useNavigate } from 'react-router-dom';

function TabelForTheWaitingsEvaluations({data}) {

    const navigate = useNavigate();
 
    const [empsData,setEmpsData] = useState([])

    // const [depNameClicked,setDepNameClicked] = useState(false)

    const [loadingPDFFile,setLoadingPDFFile] = useState(false)
    
    useEffect(()=>{
       setEmpsData(data) 
    },[data])


//to form the date in the way I want

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


//navigate to the draft evaluation when clicking at any row 
const handleRowClick = (rowData) => {
  navigate('/emp/eval_emp_draft', { state: { rowData } });
};


  return (
    <div className='TabelForTheWaitingsEvaluations'>
        {/* <span className={depNameClicked ? 'AccordionForEvaluationSectionStatistics_depName AccordionForEvaluationSectionStatistics_depName_open' : 'AccordionForEvaluationSectionStatistics_depName' } onClick={()=>{handleDepNameClicked()}}>{depName && depName}</span> */}
         
         
         <div className= "TabelForTheWaitingsEvaluations_show">

        <table className={ "TabelForTheWaitingsEvaluations_table"} >
        <thead>
        <tr className='TabelForTheWaitingsEvaluations_header_row_table'>
          <th>حالة التقييم</th>
          <th dir='rtl'>تاريخ المباشرة</th>
          <th>القسم</th>
          <th>الاسم</th>
        </tr>
        </thead>


    <tbody>

          {  empsData?.map(
            obj =>(
              <tr  key={obj.id} className='TabelForTheWaitingsEvaluations_table_rowShowDataTable'
                onClick={(()=>{handleRowClick(obj)})}
              >
             
           <td dir="rtl">{obj.status === "MANAGER_WAIT" ? "بانتظار مدير القسم" : (
            obj.status === "MANAGER_RETURN" ? "معاد الى مدير القسم" 
            : (
              obj.status === "HR_WAIT" ? "بانتظار ال hr" :
              (obj.status === "HR_RETURN" ? "معاد الى ال hr" : (
                obj.status === "HR_Manager_WAIT" ? "بانتظار مدير ال hr" : (
                  obj.status === "DONE" ? "منتهي" : " "
                )
              ))
            )
           )}</td>
            <td dir="rtl">{formatDate(obj.employee?.hiring_date)}</td>
           <td dir="rtl">{obj.employee?.department?.title}</td>
           <td dir="rtl">{obj.employee.name}</td>
        </tr>

            )
          )}
        </tbody>

    </table>

    </div>

    </div>
  )
}

export default TabelForTheWaitingsEvaluations

