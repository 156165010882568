import React, { useEffect, useState } from 'react'

import './leftPersonalPapersSection.scss'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { FaCheck } from 'react-icons/fa6'
import { MdOutlineCancel } from 'react-icons/md'
import { TailSpin } from 'react-loader-spinner'
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice'

function LeftPersonalPapersSection() {

const dispatch = useDispatch();


const authMe = useSelector((state) => state.authme.data);

const authMeStatus = useSelector((state) => state.authme.status);

const [error,setError] = useState(null)




useEffect(() => {
if (!authMe) {
  dispatch(fetchAuthMe());
}
}, [authMe, dispatch]);



useEffect(() => {
    if (authMeStatus === 'failed') {
      setError('خطأ في تحميل البيانات');
    }
  }, [authMeStatus]);


console.log("the data comming from redux",authMe)








  // useEffect(() => {
  //   const fetchData = async () => {
  //       try {
  //         setLoading(true)
  //         const response = await axios.get(`https://emp.almatingroup.com/api/V1/papers/${empInfo?.job_id}`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });
         
  //         console.log("the data that coming from the server: ",response.data.data)
  //         setLetfPersonalPaper(response.data.data)
  //         setLoading(false)

  //       } catch (error) {
  //         console.error("Error fetching data: ", error);
  //         setLetfPersonalPaper(null)
  //         setLoading(false)

  //         if (error.response && error.response.status === 404) {
  //           setError("لا يوجد بيانات لعرضها");
  //         } else {
  //           setError(error.message);
  //         }
          
  //       }
  
  //   };

  //   fetchData();
  // }, []);



       
    return (
      <div className="EvaluationForEmployeeShowPage_LeftPersonalPapersSection">
      <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_header' dir="rtl">الاوراق الشخصية المتبنقية:</span>
      <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_items'>
      
  
         {
         authMeStatus === 'loading' 
         
         ?

          <div className='spinner_getData'>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>

          :

         ( authMe?.papers ? 
          <>
        
          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.id_photo ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">صورة عن الهوية:</span>
          </div>


          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.residence_document ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">سند اقامة:</span>
          </div>


          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.no_conviction ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">لا حكم عليه:</span>
          </div>


          
          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.individual_civil_record ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">اخراج قيد فردي :</span>
          </div>
  

            
          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.personal_photos ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">صور شخصية:</span>
          </div>


          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.certificate_copy ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">صورة عن الشهادة العلمية:</span>
          </div>


          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.medical_report ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">تقرير طبي:</span>
          </div>

          <div className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_item'>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_valueElement' dir="rtl">
              {
                  authMe.papers.military_notebook ?    <FaCheck className='LeftPersonalPapersSection_btn_accept' /> 
                  :
                  <MdOutlineCancel className='LeftPersonalPapersSection_btn_cancel' />
              }
          </span>
          <span className='EvaluationForEmployeeShowPage_LeftPersonalPapersSection_content_keyElement' dir="rtl">صورة عن دفتر العلم:</span>
          </div>
          
          
          </>
          :
          <>
          {
           error ? 
           <span dir='rtl'>{error}</span>
           :
           <span dir='rtl'>لا يوجد بيانات لعرضها</span>
          }
          </>)
      
         }
  
  
      
  
      </div>
  </div>
    )
  
}

export default LeftPersonalPapersSection