import React, { useState } from 'react'
import Navbar from '../../../../components/Navbar/Navbar'
import { IoSearchSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'

import './compInternalNums.scss'
import { TailSpin } from 'react-loader-spinner'
import axios from 'axios'
import { FaPlus } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

function CompInternalNums() {

 const objArr = [
      {
        id : 1,
        name : "علي أفندي",
        numberToCall :32115640 ,
        dep:"الادارة العامة"
      },
      { 
        id : 2,
        name : "علي أفندي",
        numberToCall :32115640 ,
        dep:"الادارة العامة"
      },
      {
        id : 3,
        name : "علي أفندي",
        numberToCall :32115640 ,
        dep:"الادارة العامة"
      }
      ,
      {
        id : 4,
        name : "علي أفندي",
        numberToCall :32115640 ,
        dep:"الادارة العامة"
      }
 ]

    const navigate = useNavigate();


    const token = useSelector(state => state.user.token)

    const [searchId ,setSearchId] = useState(0)
    const [searchResult, setSearchResult] = useState(null);
  
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState("")
  



    const handelSearchId =  async(e)=>{
        e.preventDefault()
     
          console.log("the type of the search id :",typeof searchId)
         if (searchId && searchId !==0 && searchId.trim() !== "") {
           try {
             setLoading(true)
             let response;
   
             let data ;
   
             if (isNaN(parseInt(searchId))) {
               // Use the search endpoint if searchId is not a valid number
               response = await axios.post(`https://emp.almatingroup.com/api/V1/users/search/${searchId}`, 
                 {},
                 {
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               });
   
               data = response.data.data.data[0];
   
               if (!data) {
                 throw new Error("خطأ : يرجى التحقق من البيانات المدخلة");
               }
   
             } else {
               // Use the existing endpoint if searchId is a valid number
               response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`,
                  {
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               });
   
               data = response.data.data;
             }
   
   
             //  response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`, {
             //   headers: {
             //     Authorization: `Bearer ${token}`
             //   }
             // });
             setSearchResult(data);
             setLoading(false)
             
           } catch (error) {
   
             if (axios.isAxiosError(error)) {
               console.error("Axios error fetching data: ", error);
               setError("خطأ : يرجى التحقق من البيانات المدخلة");
             } else {
               console.error("Unexpected error: ", error);
               setError(error.message);
             }
   
   
             console.error("Error fetching data: ", error);
             setSearchResult(null);
             setLoading(false)
           }
         }
         else{
          
        //    SetopenChangableTable(false)
           setSearchResult(null)
   
         }
       
   
   }


   const hadelRowClicked = (id) =>{
    navigate(`/emp/update_interalNum/${id}`)
   }



  return (
    <div className='CompInternalNums'>
        <Navbar/>

        <div className='CompInternalNums_container'>
           
        <form onSubmit={handelSearchId}>
            
            <div className='CompInternalNums_container_topPart'>
             <span className='CompInternalNums_container_topPart_header' dir='rtl'>الأرقام الداخلية</span>
           
             <input type="text" placeholder='الرقم الوظيفي' dir="rtl" className='CompInternalNums_container_searchPart_inp' onChange={(e)=>{setSearchId(e.target.value)}}/>
           
            <button className="CompInternalNums_container_searchPart_btn" type="submit" onClick={(e)=>{handelSearchId(e)}} disabled={loading}>
              <IoSearchSharp className='CompInternalNums_container_search_icon_react'   />
           </button>
          
           <Link to='/emp/create_internalNum'  dir="rtl"><FaPlus className='CompInternalNums_container_createbButton' /></Link>   
            </div>


         </form>
        
           
  <div className='showNumbers_CompInternalNums'>
           {
  loading ?
  <div className='spinner_searchId_CompInternalNums'>
    <TailSpin
    visible={true}
    height="40"
    width="40"
     color="rgb(246,229,56)"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    />
  </div>
  
  :

  <>
  
  <div className="CompInternalNums_search_result">

<div className="CompInternalNums_search_result_item">




{error &&  
<div className='Wraper_error_in_searchid_CompInternalNums'>
<span className='error_in_searchid_CompInternalNums'>{error}</span>
</div>
}

<table>
  <thead>
  <tr className='CompInternalNums_header_row_table'>
    <th>القسم</th>
    <th>رقم التحويلة</th>
    <th>الاسم</th>
  </tr>
  </thead>

 <tbody>
  {
    objArr.map((obj,index)=>(
      <tr key={index} className='theRowOfTheTableForData_CompInternalNums' onClick={()=>{hadelRowClicked(obj.id)}}>
      <td dir="rtl">{obj.dep}</td>
      <td className='tdToInTheMiddel_CompInternalNums' dir="rtl">{obj.numberToCall}</td>
      <td dir="rtl">{obj.name}</td>
   </tr>
    ))
  }

  </tbody>

</table>
</div>




</div>
  
  </>


}


           </div>
        
        </div>
    </div>
  )
}

export default CompInternalNums