import "./noficationForReturnedEval.scss"

const NoficationForReturnedEval = ({reasonOFReturn}) => {
  return (
    <div className='noficationForReturnedEval' dir='rtl' >
    <span className='noficationForReturnedEval_header' dir='rtl' >لقد تم اعادة هذا التقييم بسبب : </span>    
    <span className='noficationForReturnedEval_reason' dir='rtl' >{reasonOFReturn || ''}</span>
   </div> 
  )
}

export default NoficationForReturnedEval