import { useState } from "react"
import EvaluationDraftHRChange from "../EvaluationDraftHRChange/EvaluationDraftHRChange"
import EvaluationDraftSixMonthesChange from "../EvaluationDraftSixMonthesChange/EvaluationDraftSixMonthesChange"
import EvaluationDraftTestPeriodChange from "../EvaluationDraftTestPeriodChange/EvaluationDraftTestPeriodChange"
import "./evaluationDraftChangeMainComp.scss"

const EvaluationDraftChangeMainComp = ({tableLabels}) => {

  const [containerAccordionClicked , setContainerAccordionClicked] = useState(false)

  return (
    <div className="EvaluationDraftChangeMainComp" >
      <span className="EvaluationDraftChangeMainComp_subHeader" dir="rtl" onClick={()=>{setContainerAccordionClicked(prev => !prev)}}>تعديل نماذج التقييم </span>

      <div className={containerAccordionClicked ? "EvaluationDraftChangeMainComp_container EvaluationDraftChangeMainComp_container_clicked_inAccordion" : "EvaluationDraftChangeMainComp_container" }>
        
      <EvaluationDraftSixMonthesChange tableLabels = {tableLabels} />

      <EvaluationDraftTestPeriodChange tableLabels = {tableLabels} />

      <EvaluationDraftHRChange tableLabels = {tableLabels} />

      </div>
    
    </div>
  )
}

export default EvaluationDraftChangeMainComp