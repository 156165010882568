import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { clearAuthMe, fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';
import { TailSpin } from 'react-loader-spinner';

import { CiWifiOff } from "react-icons/ci";

import './checkPrivateRouteAuth.scss'
import { clearUser } from '../../store/UserSlice/userSlice';

const CheckPrivateRouteAuth = ({ children }) => {
  // const user = useSelector((state) => state.user);

  // const { data, status ,error } = useSelector((state) => state.authme);

  // return user.token ? children : <Navigate to="/login" />;
 
  const dispatch = useDispatch();

  const navigate = useNavigate();
 
  const { data, status, error } = useSelector((state) => state.authme);

  const token = useSelector(state => state.user.token)

  const [loading, setLoading] = useState(false);


 
  useEffect(() => {

    if(status === 'idle' && token){
      dispatch(fetchAuthMe())
      setLoading(true)
    }
    else if (status === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  
  }, [status]);



//haneling the logout if the error for unautherized showed

  const handelLogOut = ()=>{
    // Clear local storage
    dispatch(clearAuthMe())
    dispatch(clearUser());

    const serializedState = localStorage.getItem('state');

    if (serializedState !== null) {
       localStorage.removeItem('state');

     }
    
    // Dispatch logout action to clear Redux state


    // Navigate to the login page or another page
    navigate('/login');
}




  if (loading) {
    console.log('Loading from CheckPrivateRouteAuth' ,status);
    return <div  
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

         }}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
    </div>;
  }

  if (!token ) {
    console.log("the status is :",status)
    console.log("the data is :",data)

    console.log('Authentication error or no data, redirecting to login');
    return <Navigate to="/login" />;
  }

  if(error){
    return <div className='No_internet_access_errorPage'>
            <div className='No_wifi_part'><CiWifiOff className='No_wifi_icon'/></div>
            <span className='No_wifi_text' dir='rtl'>تحقق من اتصالك بالانترنيت و ان تكرر ظهور هذه الصفحة يرجى</span>
            <span className='No_wifi_text' dir='rtl' style={{textDecoration:"underline" , color:"blue",cursor:"pointer"}}  onClick={()=>{handelLogOut()}}>تسجيل الخروج</span>
         </div>
  } 

  if(status === "succeeded"){
    console.log("we have moved from the login")
  return children;
  }
};

export default CheckPrivateRouteAuth;
