import React, {  useState } from 'react'

import "./empInfoInsurance.scss"
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { BsPersonCheck } from 'react-icons/bs'

import { createUserCompNum} from '../../../store/CreateUserSlice/createUserSlice'
import {  FaAngleRight } from 'react-icons/fa'
import {errorHandeling} from '../../../errorHandeling'



function EmpInfoInsurance() {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)

    const CreateUserData = useSelector(state => state.createUser.userData);

    const CreateCompNum = useSelector(state => state.createUser.compNum);



  
  const [fields,setFields] = useState({
    social_insurance: CreateUserData.social_insurance ?  CreateUserData.social_insurance : false,
    insurance_salary : CreateUserData.insurance_salary ?  CreateUserData.insurance_salary : "",
    date_registration : CreateUserData.date_registration ?  CreateUserData.date_registration : "",
    remaining_advance: CreateUserData.remaining_advance ?  CreateUserData.remaining_advance : "",
    social_insurance_number: CreateUserData.social_insurance_number ?  CreateUserData.social_insurance_number : "",

  })


  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(null)

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)






  const handleChange = (e) => {
    const { name, value } = e.target;

    let convertedValue = value ; 

    if(name === "social_insurance"){
     convertedValue = value === 'true' ? true : value === 'false' ? false : false;
    }

    setFields((prevFields) => ({
      ...prevFields,
      [name]: convertedValue
    }));
  };




 
 
  const handelMoveBackword = (e)=>{
   e.preventDefault()
  dispatch(createUserCompNum(CreateCompNum - 1))
 }
 

  

 const handleSubmit = async (e)=>{
  e.preventDefault();

  if(fields.social_insurance === "" || fields.insurance_salary === "" || 
    fields.date_registration === "" || fields.social_insurance_number === ""
  ){
   
  console.log("we are submitting the data")

  let data = {
   
      ...CreateUserData,
      // insurance
  } 

  console.log("the data is after forming it:",data)
  

  try {
      setLoading(true)
  
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/users`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)
      setSaveChangesSuccessfully(true)
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      setSaveChangesSuccessfully(false)

      if (error.response && error.response.status === 422) {
        // Assuming the error response contains a list of error messages
        const errorMessages = error.response.data.errors || {};
        const formattedErrors = Object.values(errorMessages).flat().join(', ');
        setError(`Error: ${formattedErrors}`);
      } else {
        setError(errorHandeling(error))
      }

    }


}
else if(fields.social_insurance !== "" && fields.insurance_salary !== "" && 
       fields.date_registration !== "" && fields.social_insurance_number !== ""){
   
  console.log("we are submitting the data")

  let  insurance ={...fields , 
                   job_id : CreateUserData.job_id
   }

  let data = {
   
      ...CreateUserData,
      // insurance
  } 

  console.log("the data is after forming it:",data)
  

  try {
      setLoading(true)
  
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/users`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting and we are in the sending for the insurance:', response.data);

        // If the user creation was successful, send the insurance data
    if (response.data.status === "success") { // Adjust condition based on your API response structure
      try{
      console.log("we are sending the insurance to the server ....")

      const insuranceResponse = await axios.post(
        `https://emp.almatingroup.com/api/V1/insurances`,
        insurance,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting insurance:', insuranceResponse.data);

      setLoading(false)
      setSaveChangesSuccessfully(true)
    }
    catch(insuranceError){
      setLoading(false)
      
      if (insuranceError.response && insuranceError.response.status === 422) {
        const insuranceErrorMessages = insuranceError.response.data.errors || {};
        const formattedInsuranceErrors = Object.values(insuranceErrorMessages).flat().join(', ');
        setError(`*هناك خطأ في عملية ارسال بيانات التأمينات و الخطأ هو: ${formattedInsuranceErrors}`);
      } else {
        setError(`*هناك خطأ في عملية ارسال بيانات التأمينات و الخطأ هو: ${insuranceError.message}`);
      }

    }
    }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      setSaveChangesSuccessfully(false)
      
      if (error.response && error.response.status === 422) {
        // Assuming the error response contains a list of error messages
        const errorMessages = error.response.data.errors || {};
        const formattedErrors = Object.values(errorMessages).flat().join(', ');
        setError(`Error: ${formattedErrors}`);
      } else {
        setError(errorHandeling(error))
      }


    }

}
 }



  return (
    <div className='EmpInfoInsurance'>





    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }


    <form onSubmit={handleSubmit}>
         
       
     <div className="EmpsMangment_search_result_table_component_items">


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">التأمين الاجتماعي</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.social_insurance} name='social_insurance' onChange={handleChange}>
      <option value={true} dir="rtl">مسجل</option>
      <option value={false} dir="rtl">غير مسجل</option>
     </select>
      </div>



      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">راتب التأمينات</label>
      <input type="text" dir="rtl" value={fields.insurance_salary} name="insurance_salary" onChange={handleChange} />
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">المتبقي من التأمينات</label>
      <input type="text" dir="rtl" value={fields.remaining_advance} name="remaining_advance"  onChange={handleChange} />
      </div>



      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">بداية تاريخ التأمين</label>
      <input type="date" dir="rtl" value={fields.date_registration} name="date_registration" onChange={handleChange} />
      </div>

  
      
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم التأمينات الاجتماعية</label>
      <input type="text" dir="rtl" value={fields.social_insurance_number} name="social_insurance_number" onChange={handleChange} />
      </div>





    </div>

    {
        error ? 
        <span className='EmpInfoInsurace_error' dir='rtl'>{error}</span>
        :
        <></>
    }

    
   <div className='buttons_toMoveBackAndForNext'>


       <button dir="rtl" type='submit' className='forNextButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handleSubmit(e)}} disabled={loading}> 
       {
    loading ? 
     <>
       <TailSpin
       visible={true}
       height="22"
       width="22"
       color="white"
       ariaLabel="tail-spin-loading"
       radius="1"
       wrapperStyle={{}}
       wrapperClass=""
       />
     </>
     :
     <>
    حفظ
    </>
       }

       </button>
       <button dir="rtl" type='submit' className='BackButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveBackword(e)}} disabled={loading}>السابق <FaAngleRight className='buttons_toMoveBackAndForNext_button_icon' /></button>



 </div>
   </form>

    </div>
  )
}

export default EmpInfoInsurance