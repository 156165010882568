import React, { useEffect, useState } from 'react'

import './noficationsPage.scss'
import Navbar from '../../components/Navbar/Navbar'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { errorHandeling } from '../../errorHandeling';
import { TailSpin } from 'react-loader-spinner';


function NoficationsPage() {

  const token = useSelector(state => state.user.token)

  const [semiAnnualStatus , setSemiAnnualStatus] = useState(false);

  const [loading,setLoading] = useState(false)

  const [error,setError] = useState(null)


  useEffect(()=>{

    const fetchEvaluationSemiAnnualStatus = async()=> {
     
      try {
        setLoading(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/users/evaluation-users/all',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data);

        setSemiAnnualStatus(response.data.data.SEMI_ANNUAL)
    
        setLoading(false);
  
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        setError(errorHandeling(error))
      }
  
     };

     fetchEvaluationSemiAnnualStatus();

  },[token])





    let obj = [{
        noId:1,
        nofTitle:"لديك تقييم ",
        nofContent:"هناك شخص لم تقم بتقييمه بعد رجاءا قييمه",
        nofDate:"12-08-2024"
    },
    {    noId:2,
        nofTitle:"لديك عمل ما ",
        nofContent:"عليك انجاز المهمة لتحصيل ع المزيد م النقاط",
        nofDate:"12-08-2024"
    },
]

  return (
    <div className='NoficationsPage'>
        <Navbar/>

        <div className='NoficationsPage_container'>
          
          {
          error &&
          (<span style={{color:"red"}}>{error}</span>)

          }

           
           {
            loading ?
                  <>
                <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                  </>
                  :

            <>
           {
            obj.map((item)=>(

                <div className='NoficationsPage_container_item' key={item.noId}>
                <div className='NoficationsPage_container_item_header'>
                  <span className='NoficationsPage_container_item_header_date' dir="rtl">{item.nofDate}</span>
                  <span className='NoficationsPage_container_item_header_text' dir="rtl">{item.nofTitle}</span>
                </div>
                <div className='NoficationsPage_container_item_content'>
                <p  dir="rtl">{item.nofContent}</p>
                </div>
             </div>

            ))
           }


       </>
      }
        </div>
    </div>
  )
}

export default NoficationsPage