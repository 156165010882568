
export const extractDataForTabelFromTheObject = (data) => {

    const extractedData = [];

    for (let i = 1; i <= 10; i++) {
        if( data[`${ordinal(i)}_title`] ){
            
        extractedData.push({
            title: data[`${ordinal(i)}_title`],
            description: data[`${ordinal(i)}_description`],
            mark: data[`${ordinal(i)}_mark`],
        });
    }
    }
    
    function ordinal(n) {
        const ordinals = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth"];
        return ordinals[n - 1];
    }
    
    console.log("the extracted data:",extractedData);

  return extractedData;
  
}

export default extractDataForTabelFromTheObject