import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import MainLayout from '../Layouts/MainLayout';

import CheckPrivateRouteAuth from '../components/CheckPrivateRouteAuth/CheckPrivateRouteAuth';
import MainPage from '../pages/Main_page/MainPage';
import Login from '../pages/Login/Login';


const router = createBrowserRouter([
    {
      path: '/emp',
      element: <MainLayout />,
    //   errorElement: <Error />,
      children: [
        {
          index: true,
          element:(
            <CheckPrivateRouteAuth>
            <MainPage />
          </CheckPrivateRouteAuth>
          )
        },
        // {
        //   path: 'cart',
        //   element: (
        //     <CheckPrivateRouteAuth>
        //       <Cart />
        //     </CheckPrivateRouteAuth>
        //   )
        // },
        // {
        //   path: 'categories',
        //   element: <Categories />
        // },
        // {
        //   path: 'about-us',
        //   element: <AboutUs />
        // },
        // {
        //   path: 'categories/products/:prefix',
        //   element: (
        //     <CheckPrivateRouteAuth>
        //       <Products />
        //     </CheckPrivateRouteAuth>
        //   ),
        //   loader: ({ params }) => {
        //     if (
        //       typeof params.prefix !== 'string' ||
        //       !/^[a-z]+$/i.test(params.prefix)
        //     ) {
        //       throw new Response('Bad Request', {
        //         statusText: 'Category not found',
        //         status: 400
        //       });
        //     }
        //     return true;
        //   }
        // }
      ]
    },
    {
      path: '/login',
      element: <Login />
    },
    // {
    //   path: '/register',
    //   element: <Register />
    // }
  ]);
  
  function AppRouter() {
    return (
      <RouterProvider router={router} />
    );
  }
  
  export default AppRouter;