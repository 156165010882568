import React, {  useEffect, useState } from 'react'
import './empInfoToChangeMainComp.scss'
import { useSelector } from 'react-redux'
import EmpInfoPersonalData from '../EmpInfoPersonalData/EmpInfoPersonalData'
import EmpInfoContact from '../EmpInfoContact/EmpInfoContact'
import EmpInfoJobDes from '../EmpInfoJobDes/EmpInfoJobDes'
import EmpInfoInsurance from '../EmpInfoInsurance/EmpInfoInsurance'
import EmpRole from '../EmpRole/EmpRole'
import { createSelector } from 'reselect'



const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);

function EmpInfoToChangeMainComp({empInfo}) {


  const userPermissions = useSelector(selectUserPermissions);





  //for showing the fields

  const [compNum,setCompNum] = useState(1)






  console.log("the empInfo are :",empInfo)



    //check for permissions 

    const hasPermission = (permission) => userPermissions.includes(permission);

    const checkPermissionAndThrow = (permission) => {
      if (!hasPermission(permission)) {
        return false;
      }
      else{
        return true;
      }
    };




//  console.log("the data arr is for job postions :",jobPostionsArr)
//  console.log("the deps arr are :",departmentsArr)

 console.log("...........................................")




 let sayHi = ()=>{
  if(compNum===1)
    return<> <EmpInfoPersonalData empInfo={empInfo}/></>;
  else if(compNum===2)
    return<>  <EmpInfoContact empInfo={empInfo}/></>
  else if(compNum===3)
    return<>  <EmpInfoJobDes empInfo={empInfo}/></>
  else if(compNum===4)
    return<>  <EmpInfoInsurance empInfo={empInfo}/></>
  else if(compNum===5)
    return<>  <EmpRole empInfo={empInfo}/></>
  
}





  return (
    <div className="EmpsMangment_search_result_table_component">
{/*     
    <div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div> */}
   <div className='EmpInfoToChageMainComp_topBarToShowCopms' >

    <div className={compNum===1 ? 'subElementComp_EmpInfoChange subElementComp_open' : 'subElementComp_EmpInfoChange'} dir='rtl' onClick={()=>{setCompNum(1)}}>

    <span  dir='rtl' >المعلومات الشخصية</span>
    
    </div>


<div className={compNum===2 ? 'subElementComp_EmpInfoChange subElementComp_open' : 'subElementComp_EmpInfoChange'} dir='rtl' onClick={()=>{setCompNum(2)}}>

<span   dir='rtl' >معلومات الاتصال</span>

</div>



<div className={compNum===3 ? 'subElementComp_EmpInfoChange subElementComp_open' : 'subElementComp_EmpInfoChange'} dir='rtl' onClick={()=>{setCompNum(3)}}>

<span   dir='rtl' >المعلومات الوظيفية</span>

</div>


{
   checkPermissionAndThrow("read_insurances") &&
   <>
   <div className={compNum===4  ? 'subElementComp_EmpInfoChange subElementComp_open' : 'subElementComp_EmpInfoChange'} dir='rtl' onClick={()=>{setCompNum(4)}}>

    <span   dir='rtl' >معلومات التأمين الاجتماعي</span>

</div>
   </>
}



{//check if the permission has the rights to see and update the permission for another user

  checkPermissionAndThrow("read_roles") &&
  <>
  <div className={compNum===5 ? 'subElementComp_EmpInfoChange subElementComp_open' : 'subElementComp_EmpInfoChange'} dir='rtl' onClick={()=>{setCompNum(5)}}>

    <span   dir='rtl' >صلاحية الموظف</span>

</div>
  </>
}



</div>



{
   sayHi()
}




    </div>
  )
}

export default EmpInfoToChangeMainComp