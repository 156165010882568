import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import './managmentPunshmentsByHRs.scss'

import PunshmentMangmentHRsShowComp from '../../components/PunshmentsComps/PunshmentMangmentHRsShowComp/PunshmentMangmentHRsShowComp';


function ManagmentPunshmentsByHRs() {
  return (
    <div className='ManagmentPunshmentsByHRs_fullPage'>
        <Navbar/>

        <div className='ManagmentPunshmentsByHRs_container'>
 


      <PunshmentMangmentHRsShowComp/>

        </div>
    </div>
  )
}

export default ManagmentPunshmentsByHRs