import React from 'react'

import './evalSectionForEmployee.scss'

function EvalSectionForEmoloyee() {
  return (
    <div className="EvaluationForEmployeeShowPage_evalSection">
    <span className='EvaluationForEmployeeShowPage_evalSection_header' dir="rtl">معلومات التقييم</span>
    <div className='EvaluationForEmployeeShowPage_evalSection_items'>
        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">5955</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">الرقم الوظيفي: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">100</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">درجة التقييم النهائي: : </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">100</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">اخر تقييم للمدير المباشر : </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">100</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">اخر تقييم للموارد البشرية: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">طموح ومتعلم وسريغ البديهة ذكي</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">نقاط القوة: </span>
        </div>

        <div className='EvaluationForEmployeeShowPage_evalSection_item'>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_valueElement' dir="rtl">طموح ومتعلم وسريغ البديهة ذكي</span>
        <span className='EvaluationForEmployeeShowPage_evalSection_content_keyElement' dir="rtl">نقاط الضعف: </span>
        </div>

    </div>
</div>

  )
}

export default EvalSectionForEmoloyee