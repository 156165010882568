import React, { useEffect, useState } from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { IoSearchSharp } from 'react-icons/io5'

import './showRolesAndDelete.scss'
import PopupGfg from '../../../pages/VacationManagerShow/VacationReasonPopup'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'
import { errorHandeling } from '../../../errorHandeling'

function ShowRolesAndDelete() {

    const token = useSelector(state => state.user.token)

    const [searchForRule,setSearchForRule] =  useState("")
  
    const [roles, setRoles] = useState([]);

    const [loading,setLoading]= useState(false)
    
    const [loadingDelete,setLoadingDelete] = useState(false)

    const [error,setError] = useState(null)


    useEffect(() => {
        fetchRoles();
    
      }, []);


      let fetchRoles = async()=>{
        if(searchForRule && searchForRule !==""){
          try{
            setLoading(true)
            const response = await axios.post(`https://emp.almatingroup.com/api/V1/roles/search/${searchForRule}`
                , {} ,{
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  });
                
                setRoles(response.data.data.data)
                setLoading(false)
           }
           catch(error){
            setError(errorHandeling(error))
            console.log("the error is:",error)
            setLoading(false)
           }
        }        
        else{
        
          try{
         setLoading(true)
         const response = await axios.get(`https://emp.almatingroup.com/api/V1/roles`
             , {
                 headers: {
                   Authorization: `Bearer ${token}`
                 }
               });
             
             setRoles(response.data.data)
             setLoading(false)
        }
        catch(error){
          setError(errorHandeling(error))
         console.log("the error is:",error)
         setLoading(false)
        }
      }
     }




    
    const handelDelete = async(RoleId)=>{
        try{
            setLoadingDelete(true)
            const response = await axios.delete(`https://emp.almatingroup.com/api/V1/roles/${RoleId}`
                , {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  });

                setLoadingDelete(false)
                fetchRoles();
           }
           catch(error){
            setError(error.message)
            console.log("the error is:",error)
            setLoadingDelete(false)
           }
    }


  return (
    <div className='ShowRolesAndDelete'>
        <div className='ShowRolesAndDelete_search_part'>
            <button className='ShowRolesAndDelete_search_partButton' onClick={()=>{fetchRoles()}} disabled={loading} >
            <IoSearchSharp className='ShowRolesAndDelete_search_partIcon' />
            </button>
            <input type="text" className='ShowRolesAndDelete_search_partInput' dir='rtl' placeholder='ابحث عن صلاحية ما'
             value={searchForRule}  onChange={(e)=>{setSearchForRule(e.target.value)}} />
        </div>

    
            <div className='ShowRolesAndDelete_items'>
            
            { loading 
            ?
            <>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
            </> 
            :
            <>
            
            
            {roles?.map((role,index) => {
          const permissions = role.permessions.map((perm) => perm.name).join(', ');
          console.log("the permissions are:",typeof permissions)
          console.log("the index is:",index)

          return(
            <div className={roles.length === index+1 ? 'ShowRolesAndDelete_item ShowRolesAndDelete_lastItem' : 'ShowRolesAndDelete_item'} key={role.id}>
            <button className='ShowRolesAndDelete_item_DeleteButton' onClick={()=>{handelDelete(role.id)}} disabled={loadingDelete}>
                {
                    loadingDelete ?
       
                        <>
                        <TailSpin
                        visible={true}
                        height="20"
                        width="20"
                        color="rgb(246,229,56)"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />
                    </>
                    :
                    <><FaRegTrashAlt className='ShowRolesAndDelete_item_DeleteIcon'/></>
                }

            </button>
            <span className='ShowRolesAndDelete_item_text'  dir='rtl' >
            <PopupGfg reason={permissions} firstWord ={role.name}/>
            </span>
         
         </div>

          )

            })}
            
            </>
            }

   

         </div>

         {
    error ?
    <span className='errorIn_ShowRolesAndDelete'>{error}</span>
    :
    <></>
   }   

        </div>

  )
}

export default ShowRolesAndDelete