import React, { useEffect, useState } from 'react'

import "./empInfoContact.scss"
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { BsPersonCheck } from 'react-icons/bs'

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice'
import {addToCreateUserData, createUserCompNum} from '../../../store/CreateUserSlice/createUserSlice'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'





function EmpInfoContact() {

  const dispatch = useDispatch();



    const CreateUserData = useSelector(state => state.createUser.userData);

    const CreateCompNum = useSelector(state => state.createUser.compNum);




  const [fields,setFields] = useState({
    phone : CreateUserData.phone ?  CreateUserData.phone : "",
    mobile : CreateUserData.mobile ?  CreateUserData.mobile : "",
    email : CreateUserData.email ?  CreateUserData.email : "",
    internal_phone : CreateUserData.internal_phone ?  CreateUserData.internal_phone : ""

  })

  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(null)

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)






  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };






 const handelMoveForword = (e)=>{
   e.preventDefault()
   console.log("the comp nuber is =",CreateCompNum)
  dispatch(addToCreateUserData(fields))
  dispatch(createUserCompNum(CreateCompNum + 1))
 }


 const handelMoveBackword = (e)=>{
  e.preventDefault()
 dispatch(createUserCompNum(CreateCompNum - 1))
}



    return (
    <div className='EmpInfoContact'>




    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }

    <form onSubmit={handelMoveForword}>
        
   <div className="EmpsMangment_search_result_table_component_items">

   
   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الهاتف</label>
      <input type="text" dir="rtl" value={fields.phone} name='phone' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الجوال</label>
      <input type="text" dir="rtl" value={fields.mobile} name='mobile' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">البريد الالكتروني</label>
      <input type="text" dir="rtl" value={fields.email} name='email' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم التلفون الداخلي</label>
      <input type="text" dir="rtl" value={fields.internal_phone} name='internal_phone' onChange={handleChange}/>
      </div>



    </div>

    {error &&  
      
        <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
        }


<div className='buttons_toMoveBackAndForNext'>

{
    loading ? 
     <>
       <TailSpin
       visible={true}
       height="22"
       width="22"
       color="white"
       ariaLabel="tail-spin-loading"
       radius="1"
       wrapperStyle={{}}
       wrapperClass=""
       />
     </>
     :
     <>
       <button dir="rtl" type='submit' className='forNextButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveForword(e)}} disabled={loading}>التالي <FaAngleLeft className='buttons_toMoveBackAndForNext_button_icon' /></button>
       <button dir="rtl" type='submit' className='BackButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveBackword(e)}} disabled={loading}>السابق <FaAngleRight className='buttons_toMoveBackAndForNext_button_icon' /></button>
    
     </>
     }


 </div>
   </form>
    </div>
  )
}

export default EmpInfoContact