import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'

import "./tabelForTheStatistcsEvaluation.scss"

function TabelForTheStatistcsEvaluation({data}) {
 
    const [empsData,setEmpsData] = useState([])

    // const [depNameClicked,setDepNameClicked] = useState(false)

    const [loadingPDFFile,setLoadingPDFFile] = useState(false)
    
    useEffect(()=>{
       setEmpsData(data) 
    },[data])


    // const handleDepNameClicked = ()=>{
    //     setDepNameClicked(prev => !prev)
    // }



  return (
    <div className='AccordionForEvaluationSectionStatistics'>
        {/* <span className={depNameClicked ? 'AccordionForEvaluationSectionStatistics_depName AccordionForEvaluationSectionStatistics_depName_open' : 'AccordionForEvaluationSectionStatistics_depName' } onClick={()=>{handleDepNameClicked()}}>{depName && depName}</span> */}
         
         
         <div className= "AccordionForEvaluationSectionStatistics_show">

        <table className={ "AccordionForEvaluationSectionStatistics_table"} >
        <thead>
        <tr className='AccordionForEvaluationSectionStatistics_header_row_table'>
          <th dir='rtl'>طباعة PDF</th>
          <th>حالة التقييم</th>
          <th>القسم</th>
          <th>الاسم</th>
        </tr>
        </thead>


    <tbody>

          {  empsData?.map(
            obj =>(
              <tr  key={obj.id} className='AccordionForEvaluationSectionStatistics_table_rowShowDataTable'>
              <td>
                {
                  obj.status === "DONE" ? 

    
            <div className='AccordionForEvaluationSectionStatistics_table_button'>
            {
                loadingPDFFile ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="20"
               width="20"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
          :
         <>
              <button   disabled={loadingPDFFile}>
                PDF
              </button>
         
         </>
        }

            </div>
                    
              :
             
              <>_</>
              }
           </td>
           <td dir="rtl">{obj.status === "MANAGER_WAIT" ? "بانتظار مدير القسم" : (
            obj.status === "MANAGER_RETURN" ? "معاد الى مدير القسم" 
            : (
              obj.status === "HR_WAIT" ? "بانتظار ال hr" :
              (obj.status === "HR_RETURN" ? "معاد الى ال hr" : (
                obj.status === "HR_Manager_WAIT" ? "بانتظار مدير ال hr" : (
                  obj.status === "DONE" ? "منتهي" : " "
                )
              ))
            )
           )}</td>
           <td dir="rtl">{obj.employee?.department?.title}</td>
           <td dir="rtl">{obj.employee.name}</td>
        </tr>

            )
          )}
        </tbody>

    </table>

    </div>

    </div>
  )
}

export default TabelForTheStatistcsEvaluation

