// src/store/createUserSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  compNum : 1 ,
  status: 'idle',
  error: null,
};

const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {
    addToCreateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
   createUserCompNum: (state, action) => {
      state.compNum = action.payload;
    },
    clearCreateUserData: (state) => {
      state.userData = {};
      state.compNum = 1;
      state.status = 'idle';
      state.error = null;

    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addToCreateUserData, createUserCompNum ,clearCreateUserData, setStatus, setError } = createUserSlice.actions;

export default createUserSlice.reducer;
