import React, { useState } from 'react'
import { BsCloudPlusFill } from "react-icons/bs";
import './uploadFilesEvaluation.scss'

function UploadFilesEvaluation() {

  const [fileName, setFileName] = useState('');

  let handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };


    let handleSubmit = (e)=>{
        e.preventDefault();
        console.log("You have clicked the submit button")
       }

  return (
    <div className="uploadFiles_evaluation">
    <form  className='uploadFiles_container_form' onSubmit={handleSubmit}>
    <div className="uploadFiles_container_form_uploadInfo">

      <div className='uploadFiles_container_form_title'>
      <span className='uploadFiles_container_form_title_content' dir="rtl">ملف التقييم</span>
      </div>

      <span className='uploadFiles_container_form_note' dir="rtl">أقصى حجم ممكن 2 ميغا</span>

    <div className='uploadFiles_container_form_showFiles_container'>
     
        <div className='uploadFiles_container_form_showFiles_container_icon'>
        <label htmlFor="write-enter-files" className="uploadFiles_container_form_UploadIcon_contaienr"><BsCloudPlusFill className='uploadFiles_container_form_UploadIcon' /></label>
        <input 
        type="file" 
        className="write-input-files"
         id='write-enter-files' 
         style={{display:"none"}}
         onChange={(e)=>{handleFileChange(e)}}
         />
        </div>
       
       <span dir="rtl">{fileName ? fileName : 'اضغط لعرض ملفاتك'}</span>

       </div>

    </div>
      <button type='submit' className='uploadFiles_container_form_btn' dir="rtl">رفع الملف و تحديث البيانات</button>
    </form>
    </div>
  )
}

export default UploadFilesEvaluation