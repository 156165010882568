import { BsPersonCheck } from "react-icons/bs"
import "./successedMessage.scss"


const SuccessedMessage = () => {
  return (
    <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
    <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
  <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    

  </div>
  )
}

export default SuccessedMessage