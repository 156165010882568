import { useState , useEffect } from "react"
import "./evaluationTimeSettings.scss"
import { useSelector } from 'react-redux'
import axios from "axios";
import { errorHandeling } from "../../../errorHandeling";

import SuccessedMessage from "../../SuccessedMessage/SuccessedMessage";

const EvaluationTimeSettings = () => {

    const token = useSelector(state => state.user.token)

    //this state for the accordion 
    const [timeEvalSettingsClicked,setTimeEvalSettingsClicked] = useState(true)

    const [loading , setLoading] = useState(false);

    const [error, setError] = useState({});
 
    const [saveChangeSuccessfully , setSaveChangeSuccessfully] = useState(false);

    const [fields,setFields] = useState({
        next_evaluation_date : null ,

        minimum_working_days_before_semi_annual_evaluation : null
    
    })


   const handelFieldsChange = (e)=>{
     let { name , value } = e.target ;
        setFields(prev => ({
            ...prev ,
            [name] : value , 
        }))
    }


     // to format the date 

  const formatDate = (theDate) => {
    const date = new Date(theDate);
    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    
    // Return the formatted date
    return `${month}-${day}-${year}`;
  };



    // Validate date
    const validateDate = () => {
        const startDate = new Date(fields.next_evaluation_date);
        const today = new Date();
        startDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (startDate < today) {
            setError(prev => ({
                ...prev,
                next_evaluation_date: "*تاريخ بداية التقييم يجب أن يحدد ابتداءا من اليوم وما بعده"
            }));
            return false;
        } else {
            setError(prev => ({
                ...prev,
                next_evaluation_date: ""
            }));
            return true;
        }
    };


   const validateFieldsInfoBeforeSubmitting = ()=>{
    if(!fields.minimum_working_days_before_semi_annual_evaluation || !fields.next_evaluation_date){
        setError({
            general: "*يرجى التأكد من ملئ البيانات قبل الحفظ"
        });
        return false;
    }

    else
     return true ; 
   }


    // Handle form submission
    const handleSubmit = async () => {
        
        setSaveChangeSuccessfully(false);

        if (!validateDate()) return; // Skip submission if validation fails

        if(!validateFieldsInfoBeforeSubmitting()) {
           return; 
        }

        setError({});

        
        try {
            setLoading(true);
            const response = await axios.put('https://emp.almatingroup.com/api/V1/evaluation-settings/1', 
                fields,
                 {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.status === "error") {
                setError(response.data.errors);
                setSaveChangeSuccessfully(false);
            } else {
                // Handle success
                setError({}); // Clear errors on successful submission
                setSaveChangeSuccessfully(true);
                // Additional success handling here
            }
        } catch (error) {

            setSaveChangeSuccessfully(false);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setError(error.response.data.errors || {
                    general: errorHandeling(error)
                });
            } else if (error.request) {
                // The request was made but no response was received
                setError({
                    general: errorHandeling(error)
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                setError({
                    general: errorHandeling(error)
                });
            }
        } finally {
            setLoading(false);
        }
    };

  return (
    <div className="EvaluationTimeSettings" >
        <span className="EvaluationTimeSettings_sub_header" dir="rtl" onClick={()=>{setTimeEvalSettingsClicked(prev => !prev)}}>تعديل فنرة التقيم و من يشمله التقييم :</span>
        
        
        <div className={timeEvalSettingsClicked ? "EvaluationTimeSettings_container EvaluationTimeSettings_container_items_clicked_inAccordion" : "EvaluationTimeSettings_container" }>

        {
            saveChangeSuccessfully && 
            < SuccessedMessage />
        }

   
       
        <div className= "EvaluationTimeSettings_items" >
            
            <div className="EvaluationTimeSettings_item">
                <span className="EvaluationTimeSettings_item_label" dir="rtl">تاريخ البدء بالتقييم  <span className="important_toFill">*</span></span>
                
                {error.next_evaluation_date && (
                            <span style={{ color: "red" }} dir="rtl">{error.next_evaluation_date}</span>
                        )}
                
                <input type="date" dir="rtl"  className="EvaluationTimeSettings_item_input" name="next_evaluation_date" value={fields.next_evaluation_date || ''} onChange={(e)=>{handelFieldsChange(e)}} />
            </div>

            <div className="EvaluationTimeSettings_item">
                <span className="EvaluationTimeSettings_item_label" dir="rtl">تحديد المدة الزمنية بالأيام لمن سيشملهم التقييم <span className="important_toFill">*</span></span>
               
                {error.minimum_working_days_before_semi_annual_evaluation && (
                            <span style={{ color: "red" }} dir="rtl">{error.minimum_working_days_before_semi_annual_evaluation}</span>
               )}

               
                <input type="number" min={0} dir="rtl"  className="EvaluationTimeSettings_item_input"  name="minimum_working_days_before_semi_annual_evaluation" value={fields.minimum_working_days_before_semi_annual_evaluation || ''} onChange={(e)=>{handelFieldsChange(e)}} />
            </div>
   
        </div>

                
                {error.general && (
                    <span style={{ color: "red" , marginTop:"2rem"}} dir="rtl">{error.general}</span>
                    )}
     
        
        <button className={loading ? "EvaluationTimeSettings_submit_button button_saving_evalSettings_loading" : "EvaluationTimeSettings_submit_button"} dir="rtl" onClick={()=>{handleSubmit()}} disabled={loading} >
           {loading ? "جاري الحفظ..." : "حفظ الإعدادات"}
        </button>

        </div>

    </div>
  )
}

export default EvaluationTimeSettings