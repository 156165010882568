import React, { useState } from 'react'
import './uploadFiles.scss'
import Navbar from '../../components/Navbar/Navbar'

import UploadFilesSalary from '../../components/UploadFilesCompnents/UploadFilesSalary/UploadFilesSalary';
import UploadFilesEvaluation from '../../components/UploadFilesCompnents/UploadFilesEvaluation/UploadFilesEvaluation';
import UploadFilesInsurance from '../../components/UploadFilesCompnents/UploadFilesInsurance/UploadFilesInsurance';
import UploadFilesFollowUp from '../../components/UploadFilesCompnents/UploadFilesFollowUp/UploadFilesFollowUp';
import UploadFilesSanctions from '../../components/UploadFilesCompnents/UploadFilesSanctions/UploadFilesSanctions';
import UploadFilesAddEmps from '../../components/UploadFilesCompnents/UploadFilesAddEmps/UploadFilesAddEmps';
import UploadFilesDepartments from '../../components/UploadFilesCompnents/UploadFilesDepartments/UploadFilesDepartments';
import UploadFilesJobPositions from '../../components/UploadFilesCompnents/UploadFilesJobPositions/UploadFilesJobPositions';
import UploadFilesPapers from '../../components/UploadFilesCompnents/UploadFilesPapers/UploadFilesPapers';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function UploadFiles() {

    const userPermissions = useSelector(selectUserPermissions);

    const [compNumber,SetCompNumber] = useState(2);


    //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};


  
  
    let changeComp = ()=>{
      // if(compNumber===1)
      //   return<><UploadFilesSalary/></>;
      if(compNumber===2)
        return<><UploadFilesEvaluation/></>
      else if(compNumber===3)
        return<><UploadFilesInsurance/></>
      // else if(compNumber===4)
      //   return<><UploadFilesFollowUp/></>
      // else if(compNumber===5)
      //   return<><UploadFilesSanctions/></>
      else if(compNumber===6)
        return<><UploadFilesAddEmps/></>
      else if(compNumber===7)
        return<><UploadFilesDepartments/></>
      else if(compNumber===8)
        return<><UploadFilesJobPositions/></>
      else if(compNumber===9)
        return<><UploadFilesPapers/></>
    }

  return (
    <div className='uploadFiles'>
      <Navbar/>

        <div className='uploadFiles_container'>
           
            <div className="uploadFiles_container_left">
              
             {
               (changeComp)()
             }
        
            </div>

            <div className="uploadFiles_container_right">

                <span className='uploadFiles_container_right_title'>تحديث الملفات</span>
                
                {/* <span  onClick={ ()=>{ SetCompNumber(1) }}  className={ compNumber === 1 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف الرواتب</span> */}
                {/* <span  onClick={ ()=>{ SetCompNumber(2) }}  className={ compNumber === 2 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف التقييم</span> */}
                {/* <span  onClick={ ()=>{ SetCompNumber(4) }}  className={ compNumber === 4 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف المتابعة</span> */}
                {/* <span  onClick={ ()=>{ SetCompNumber(5) }}  className={ compNumber === 5 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف العقوبات</span> */}
                {/* <span  onClick={ ()=>{ SetCompNumber(6) }}  className={ compNumber === 6 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>اضافة موظفين</span> */}
                 {/* <span  onClick={ ()=>{ SetCompNumber(8) }}  className={ compNumber === 8 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف المناصب الوظيفية</span> */}
                
                {
                 checkPermissionAndThrow("update_insurances") && 
                 (<span  onClick={ ()=>{ SetCompNumber(3) }}  className={ compNumber === 3 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف التأمينات</span>  
                 )}

                 {
                 checkPermissionAndThrow("update_departments") && 
                 (  <span  onClick={ ()=>{ SetCompNumber(7) }}  className={ compNumber === 7 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف الأقسام</span>)}
                
                {
                 checkPermissionAndThrow("update_papers") && 
                 ( <span  onClick={ ()=>{ SetCompNumber(9) }}  className={ compNumber === 9 ? 'uploadFiles_container_right_btn activte_btn' : 'uploadFiles_container_right_btn'}>ملف الأوراق</span>)}
              
                
               

            </div>
        </div>
    </div>
  )
}

export default UploadFiles