import React, { useEffect, useState } from 'react'

import "./empInfoContact.scss"
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { BsPersonCheck } from 'react-icons/bs'

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice'
import { errorHandeling } from '../../../errorHandeling'

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function EmpInfoContact({empInfo}) {

  const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);



   console.log("the props we have it in the personal data are :",empInfo)
  
  const [fields,setFields] = useState({
    phone : empInfo.phone || "",
    mobile : empInfo.mobile || "",
    email : empInfo.email || "",
    internal_phone : empInfo.internal_phone || ""

  })

  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(null)

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)





  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };



  //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};




  const handleSubmit = async (e)=>{
    e.preventDefault();

   if(checkPermissionAndThrow("update_users")){

    console.log("we are submitting the data")

    let data = {
        ...empInfo,
        ...fields,


        department_id:empInfo.department ?  empInfo.department.id: 1,
        job_position_id:empInfo.job_position ?  empInfo.job_position.id : 1

    } 

    console.log("the data is after forming it:",data)
    

    try {
        setLoading(true)
    
        const response = await axios.put(
          `https://emp.almatingroup.com/api/V1/users/${empInfo.job_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response after submitting:', response.data);
        setLoading(false)
        setSaveChangesSuccessfully(true)
    
      } catch (error) {
        console.error('Error:', error);
        setLoading(false)
        setError(errorHandeling(error))
        setSaveChangesSuccessfully(false)
    
      }

}
}

    return (
    <div className='EmpInfoContact'>


<div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>

    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }

    <form onSubmit={handleSubmit}>
        
   <div className="EmpsMangment_search_result_table_component_items">

   
   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الهاتف</label>
      <input type="text" dir="rtl" value={fields.phone} name='phone' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الجوال</label>
      <input type="text" dir="rtl" value={fields.mobile} name='mobile' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">البريد الالكتروني</label>
      <input type="text" dir="rtl" value={fields.email} name='email' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم التلفون الداخلي</label>
      <input type="text" dir="rtl" value={fields.internal_phone} name='internal_phone' onChange={handleChange}/>
      </div>



    </div>

    {error &&  
      
        <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
        }


   {
    checkPermissionAndThrow("update_users") &&
    <button dir="rtl" type='submit' className='submitButton_changeUserIfno' onClick={(e)=>{handleSubmit(e)}} disabled={loading}>
        {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>حفظ</>
        }</button>
   }


   </form>
    </div>
  )
}

export default EmpInfoContact