import React, { useEffect, useState } from 'react'

import './addNewRole.scss'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { errorHandeling } from '../../../errorHandeling';
import { BsPersonCheck } from 'react-icons/bs';


function AddNewRole() {

    const token = useSelector(state => state.user.token)

    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [roleName, setRoleName] = useState('');

    const [loadingPermissions,setLoadingPermissions] = useState(false)
    const [loading, setLoading] = useState(false);

    const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)

    const [error,setError] = useState(null)


    useEffect(() => {
        // Fetch all permissions from the paginated API
        const fetchAllPermissions = async () => {
            let allPermissions = [];
            let currentPage = 1;
            let totalPages = 1;

            try {
                setLoadingPermissions(true)

                while (currentPage <= totalPages) {
                    const response = await axios.get(`https://emp.almatingroup.com/api/V1/permissions?page=${currentPage}`
                        , {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          });

                    const pageData = response.data.data;
                    allPermissions = [...allPermissions, ...pageData.data];
                    currentPage = pageData.current_page + 1;
                    totalPages = pageData.last_page;
                }

                setPermissions(allPermissions);
       
                setLoadingPermissions(false)

            } catch (error) {
                console.error('Error fetching permissions:', error);
                setLoadingPermissions(false);
                setError(errorHandeling(error));
            }
        };

        fetchAllPermissions();
    }, []);



    useEffect(()=>{
        console.log("the permissions are :",permissions)
    },[permissions])




    const handleCheckboxChange = (id) => {
        setSelectedPermissions(prevSelected => 
            prevSelected.includes(id) 
            ? prevSelected.filter(permissionId => permissionId !== id) 
            : [...prevSelected, id]
        );
    };





    const handleSubmit = async (e) => {
        e.preventDefault();

        if(roleName !=='' && selectedPermissions.length !== 0){

        // Map selected permission IDs to their corresponding names
        const selectedPermissionNames = permissions
            .filter(permission => selectedPermissions.includes(permission.id))
            .map(permission => permission.name);

        const payload = {
            name: roleName,
            permissions: selectedPermissionNames
        };

        console.log("the payload is :",payload)

        try {
            setLoading(true)
            const response = await axios.post('https://emp.almatingroup.com/api/V1/roles',
                 payload ,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  });
                  
            console.log('Response:', response.data);
           setLoading(false)
           setSaveChangesSuccessfully(true)
           setError(null)
        } catch (error) {
            console.error('Error submitting permissions:', error);
           setLoading(false)
           setError(errorHandeling(error));
           setSaveChangesSuccessfully(false)
        }
    }
    else{
        setError("*يرجى التأكيد من ملئ الحقول قبل حفظ البيانات");
        setSaveChangesSuccessfully(false)
    }
    };



  return (
    <div className='AddNewRole'>
        <span className='AddNewRole_header' dir='rtl'>اضافة صلاحية</span>


    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
   
   }

    {
     loadingPermissions
      ? 
      <div className='spinner_getPermissions'>
      <TailSpin
      visible={true}
      height="40"
      width="40"
       color="rgb(246,229,56)"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      />
    </div>
     :<>

        <div className='AddNewRole_container'>
            <div className='AddNewRole_item'>
                <input type="text" className='AddNewRole_item_input' dir='rtl' onChange={(e)=>{setRoleName(e.target.value)}}/>
                <span className='AddNewRole_item_label' dir='rtl'>اسم الصلاحية:</span>
            </div>

            <div className='AddNewRole_show_permissions_items'>
                {
                    loadingPermissions
                     ? 
                     <div className='spinner_getPermissions'>
                     <TailSpin
                     visible={true}
                     height="40"
                     width="40"
                      color="rgb(246,229,56)"
                     ariaLabel="tail-spin-loading"
                     radius="1"
                     wrapperStyle={{}}
                     wrapperClass=""
                     />
                   </div>
                    :
                    <>
                   {
                    permissions?.map(obj =>(
                        <div className='AddNewRole_show_permissions_item' key={obj.id} >

                            <span className='AddNewRole_show_permissions_item_label' >{obj.name}</span>

                            <input type="checkbox" 
                              value={obj.id} 
                              checked={selectedPermissions.includes(obj.id)}
                              onChange={() => handleCheckboxChange(obj.id)} 
                              className='AddNewRole_show_permissions_item_checkbox'/>

                     </div>
                    ))
                   }
                    
                    </>
                }


   
            </div>
        </div>

        {
            error && 
            <span style={{color:"red"}} dir='rtl'>{error}</span>
        }

        <button type='submit' className='AddNewRole_saveButton' dir='rtl' onClick={(e)=>{handleSubmit(e)}} disabled={loading | loadingPermissions}>
         {
            loading ? 
            <div className='spinner_submit_permissions'>
            <TailSpin
            visible={true}
            height="20"
            width="20"
             color="white"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            />
          </div>
            :
            <>حفظ</>
         }
</button>

</>
}

    </div>
  )
}

export default AddNewRole