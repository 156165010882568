import React, { useEffect, useState } from 'react'

import "./empInfoPersonalData.scss"
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BsPersonCheck } from 'react-icons/bs';

import { createSelector } from 'reselect';
import { errorHandeling } from '../../../errorHandeling';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function EmpInfoPersonalData({empInfo}) {

 

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);



   console.log("the props we have it in the personal data are :",empInfo)
  
  const [fields,setFields] = useState({
    name : empInfo.name,
    address : empInfo.address || "",
    father_name : empInfo.father_name || "" ,
    mother_name: empInfo.mother_name || "" ,
    national_id : empInfo.national_id || 0 ,
    birth_place : empInfo.birth_place || "" ,
    birth_date: empInfo.birth_date || "" ,
    gender: empInfo.gender ,
    blood_type: empInfo.blood_type || "A+" ,
    constraint: empInfo.constraint || "",
    passport_number: empInfo.passport_number || "",
    marital_status : empInfo.marital_status,
    kids_count : empInfo.kids_count ,
    religion : empInfo.religion || "" ,
    nationality:empInfo.nationality || "",
    civilian_record : empInfo.civilian_record || "" ,
    military_service :  empInfo.military_service,
    qualification : empInfo.qualification || ""
  })


 

  const [loading,setLoading] = useState(false)
 
  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)

  const [error,setError] = useState(null)


  


 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };



  //check for permissions 

  const hasPermission = (permission) => userPermissions.includes(permission);

  const checkPermissionAndThrow = (permission) => {
    if (!hasPermission(permission)) {
      
      return false;
    }
    else{
      return true;
    }
  };
  




  const handleSubmit = async (e)=>{
    e.preventDefault();

    if(checkPermissionAndThrow("update_users")){

    console.log("we are submitting the data")

    let data = {
        ...empInfo,
        ...fields,


        department_id:empInfo.department ?  empInfo.department.id: 1,
        job_position_id:empInfo.job_position ?  empInfo.job_position.id : 1

    } 

    console.log("the data is after forming it:",data)
    

    try {
        setLoading(true)
    
        const response = await axios.put(
          `https://emp.almatingroup.com/api/V1/users/${empInfo.job_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response after submitting:', response.data);
        setLoading(false)
        setSaveChangesSuccessfully(true)
    
      } catch (error) {
        console.error('Error:', error);
        setLoading(false)
        setSaveChangesSuccessfully(false)
        setError(errorHandeling(error))
      }


}

}

    return (
    <div className='EmpInfoPersonalData'>


<div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>


    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }


    <form onSubmit={handleSubmit}>
        
   <div className="EmpsMangment_search_result_table_component_items">

   <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">العنوان</label>
      <input type="text" dir="rtl"  value={fields.address} name='address' onChange={handleChange} />
      </div>

    <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الاسم</label>
      <input type="text" dir="rtl" value={fields.name} name='name' onChange={handleChange}/>
      </div>





      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم الجواز</label>
      <input type="text" dir="rtl" value={fields.passport_number} name='passport_number' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الرقم الوطني</label>
      <input type="text" dir="rtl" value={fields.national_id} name='national_id' onChange={handleChange}/>
      </div>




      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">اسم الأب</label>
      <input type="text" dir="rtl" value={fields.father_name} name='father_name' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">اسم الأم</label>
      <input type="text" dir="rtl" value={fields.mother_name} name='mother_name' onChange={handleChange}/>
      </div>


     {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">زمرة الدم</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.blood_type} name='blood_type' onChange={handleChange}>
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="A+" dir="rtl">A+</option>
      <option value="A-" dir="rtl">A-</option>
      <option value="B+" dir="rtl">B+</option>
      <option value="B-" dir="rtl">B-</option>
      <option value="O+" dir="rtl">O+</option>
      <option value="O-" dir="rtl">O-</option>
      <option value="AB+" dir="rtl">AB+</option>
      <option value="AB-" dir="rtl">AB-</option>
     </select>
      </div>



     {/* need to be changed ......................... ..........*/}
     <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الجنس</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.gender} name="gender" onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="MALE" dir="rtl">ذكر</option>
      <option value="FEMALE" dir="rtl">أنثى</option>
     </select>
      </div>




      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">مكان الولادة</label>
      <input type="text" dir="rtl" value={fields.birth_place} name='birth_place' onChange={handleChange}/>
      </div>

      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تاريخ الولادة</label>
      <input type="date" dir="rtl" value={fields.birth_date} name='birth_date' onChange={handleChange} />
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">سجل القيد</label>
      <input type="text" dir="rtl" value={fields.constraint} name='constraint' onChange={handleChange}/>
      </div>

      
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الجنسية</label>
      <input type="text" dir="rtl" value={fields.nationality} name='nationality' onChange={handleChange}/>
      </div>
    
    
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">عدد الأولاد</label>
      <input type="text" dir="rtl" value={fields.kids_count} name='kids_count' onChange={handleChange}/>
      </div>

        {/* need to be changed ......................... ..........*/}
     <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الوضع الاجتماعي</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.marital_status} name='marital_status' onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="SINGLE" dir="rtl">أعزب</option>
      <option value="MARRIED" dir="rtl">متزوج</option>
      <option value="DIVORCED" dir="rtl">مطلق</option>
      <option value="WIDOWED" dir="rtl">أرمل</option>

     </select>
      </div>

        {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الديانة</label>
      <input type="text" dir="rtl" value={fields.religion} name='religion' onChange={handleChange}/>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">سجل مدني</label>
      <input type="text" dir="rtl" value={fields.civilian_record} name='civilian_record' onChange={handleChange}/>
      </div>

      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">شعبة التجنيد</label>
      <input type="text" dir="rtl" value={militrayPlaceField} onChange={(e)=>{setMilitrayPlaceField(e.target.value)}}/>
      </div> */}

      {/* need to be changed ......................... ..........*/}
      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">خدمة العلم</label>
      <select dir="rtl" className='EmpsMangment_selection' value={fields.military_service} name='military_service' onChange={handleChange} >
      <option value="UNKNOWN" dir="rtl">غير محدد</option>
      <option value="PERFORMER" dir="rtl">مؤدي</option>
      <option value="EXEMPT" dir="rtl">معفي</option>
      <option value="ADMINISTRATIVE_POSTPONEMENT" dir="rtl">مؤجل اداري</option>
      <option value="STUDY_POSTPONEMENT" dir="rtl">مؤجل دراسيا</option>
      <option value="BREADWINNER_POSTPONEMENT" dir="rtl">مؤجل معيل</option>
      <option value="TEMPORARY_SOLE_PROVIDER" dir="rtl">وحيد مؤقت</option>
      <option value="PERMANENT_SOLE_PROVIDER" dir="rtl">وحيد دائم</option>
      <option value="UNDER_LEGAL_AGE" dir="rtl">تحت السن القانوني</option>
      <option value="CASH_REPLACEMENT" dir="rtl">بدل نقدي</option>

     </select>
      </div>


      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">المؤهلات</label>
      <input type="text" dir="rtl" value={fields.qualification} name='qualification' onChange={handleChange} />
      </div>


      
      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">صلاحية الموظف</label>
      <select dir="rtl" className='EmpsMangment_selection' value={empPermissionField} onChange={(e)=>{setEmpPermissionField(e.target.value)}} >
      <option value="Manger_Developer" dir="rtl">مطور النظام</option>
      <option value="General_Adminstration" dir="rtl">ادارة عامة</option>
      <option value="Head_Department_Hr" dir="rtl">رئيس قسم -Hr</option>
      <option value="Head_Department_IT" dir="rtl">رئيس قسم -IT</option>
      <option value="Head_Department" dir="rtl">رئيس قسم</option>
      <option value="Following_Admistrator" dir="rtl">مسؤول متابعة -Hr</option>
      <option value="Evaluation_Adminstartor" dir="rtl">مسؤول تقييم -Hr</option>
      <option value="Salary_Adminstartor" dir="rtl">مسؤول رواتب -Hr</option>
      <option value="Employing_Adminstrator" dir="rtl">مسؤول توظيف -Hr</option>
      <option value="Employee" dir="rtl">موظف</option>
     </select>
      </div> */}



    </div>

    {error &&  
      
      <span className='error_in_saving' style={{ color: 'red'}}>{error}</span>
      }

      {
        //check if the user has the permission to update the data for anothe user
        checkPermissionAndThrow("update_users") &&
        <button dir="rtl" type='submit' className='submitButton_changeUserIfno' onClick={(e)=>{handleSubmit(e)}} disabled={loading}>
        {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>حفظ</>
        }</button>

      }


   </form>
    </div>
  )
}

export default EmpInfoPersonalData