import React from 'react'
import Footer from '../components/Footer/Footer'
import './mainLayout.scss'
import { Outlet } from 'react-router-dom'

function MainLayout() {
  return (
    <div className='MainLayout'>
         <Outlet/>  
        
        <Footer/>
    </div>
  )
}

export default MainLayout