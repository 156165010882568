import Navbar from "../../../../components/Navbar/Navbar"
import "./evaluationEmpFromManager.scss"
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { TailSpin } from "react-loader-spinner"
import TabelForTheWaitingsEvaluations from "../TabelForTheWaitingsEvaluations/TabelForTheWaitingsEvaluations";
import {errorHandeling} from "../../../../errorHandeling"


function EvaluationEmpFromManager() {


  const token = useSelector(state => state.user.token)


  const [empsArr,setEmpsArr] = useState([])

  const [semiAnnualStatus , setSemiAnnualStatus] = useState(false);

  const [loadingResult,setLoadingResult] = useState(false)


  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)


  //fetch the the records for the statistcs status
  
  useEffect(() => {

    const fetchEmpRecords = async (page) => {


      try {
        setLoadingResult(true);
        const response = await axios.get(
          `https://emp.almatingroup.com/api/V1/evaluations/waitings?page=${page}`,
       
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data.data.data);
        setEmpsArr(response.data.data.data);
        setCurrentPage(response.data.data.current_page);
        setTotalPages(response.data.data.last_page);
        setLoadingResult(false);
      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(errorHandeling)
    
      }
    
    
    };
  

    fetchEmpRecords(currentPage);
 
   }, [token, currentPage ]);





  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };





// check for the semi annual begining of evaluations 
  useEffect(()=>{

    const fetchEvaluationSemiAnnualStatus = async()=> {
     
      try {
        setLoadingResult(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/users/evaluation-users/all',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data);

        setSemiAnnualStatus(response.data.data.SEMI_ANNUAL)
    
        setLoadingResult(false);
  
      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(errorHandeling(error))
      }
  
     };

     fetchEvaluationSemiAnnualStatus();

  },[token])


 
  const handelSemiAnnaulBegin = async()=> {
     
    try {
      setLoadingResult(true);
      const response = await axios.post(
        'https://emp.almatingroup.com/api/V1/evaluations/start-all',
        {

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data);
  
      setLoadingResult(false);
      setSemiAnnualStatus(false)

    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setError(errorHandeling(error))
    }

   };



  
  return (
    <div className='EvaluationEmpFromManager'>
        <Navbar/>

        <div className="EvaluationEmpFromManager_container">

              <span className="EvaluationEmpFromManager_container_header" dir="rtl">تقييم الموظفين</span>
        
              {
                error && 
                (
                  <span  style={{color:"red",marginTop:"1rem"}} dir="rtl">{error}</span>
                )
              }


              {
         loadingResult ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
        <> 

         {
            semiAnnualStatus && 
             ( <div className="semiAnnual_nofication_toStart">
              <span className="semiAnnual_nofication_toStart_text" dir="rtl" >موعد فترة التقييم النصف ثانوي اضفط ابدء لبدء التقييم</span>
                  
               <button  className="semiAnnual_nofication_toStart_btn" onClick={()=>{handelSemiAnnaulBegin()}} disabled={loadingResult} dir="rtl">ابدء</button>
              </div> )
           }
       
       <div className="EvaluationWaitingsAtEvaLWaitingsPage_container_showData">

 
          <TabelForTheWaitingsEvaluations  data={empsArr}/>

       </div>

       <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>

     </>
    }
           
           
           


        </div>
    </div>
  )
}

export default EvaluationEmpFromManager