import "./evaluationStartForTestPeriodEmps.scss"


import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { TailSpin } from "react-loader-spinner"
import Navbar from "../../../components/Navbar/Navbar"
import TabelForTheStartTestPeriodEvaluation from "../EvaluationStartForTestPeriodEmpsAll/TabelForTheStartTestPeriodEvaluation/TabelForTheStartTestPeriodEvaluation"



function EvaluationStartForTestPeriodEmps() {


  const token = useSelector(state => state.user.token)


  const [empsArr,setEmpsArr] = useState([])

  const [loadingResult,setLoadingResult] = useState(false)



  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)


  //fetch the the records for the statistcs status
  
  useEffect(() => {

    const fetchEmpRecords = async (page) => {
      try {
        setLoadingResult(true);
        const response = await axios.get(
          `https://emp.almatingroup.com/api/V1/users/evaluation-users/all?page=${page}`,
       
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data.data.TEST.data);
        setEmpsArr(response.data.data.TEST.data);
        setCurrentPage(response.data.data.TEST.current_page);
        setTotalPages(response.data.data.TEST.last_page);
        setLoadingResult(false);
   
      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(error.message)
    
      }
  
    
    };  

    fetchEmpRecords(currentPage);
 
   }, [token, currentPage ]);


  




  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };



  
  return (
    <div className='EvaluationStartForTestPeriodEmps'>
      <Navbar/>

      <div className='EvaluationStartForTestPeriodEmps_container'>
       
       <span className="EvaluationStartForTestPeriodEmps_container_header" dir="rtl">بدء تقييم الفترة التجريبية</span>

       {
          error &&
          (<span style={{color:"red"}}>{error}</span>)
         }
    

       {
         loadingResult ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :

         <>

       <div className="EvaluationStartForTestPeriodEmps_container_showData">

       <TabelForTheStartTestPeriodEvaluation  data={empsArr}/>

       </div>

       <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>

      </>
       }

      </div>

    </div>
  )
}

export default EvaluationStartForTestPeriodEmps