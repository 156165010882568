import React, { useState } from 'react'
import './manageRoles.scss'
import Navbar from '../../components/Navbar/Navbar'
import AddNewRole from '../../components/ManageRolesComps/AddNewRole/AddNewRole'
import ShowRolesAndDelete from '../../components/ManageRolesComps/ShowRolesAndDelete/ShowRolesAndDelete'

function ManageRoles() {

  const [showComp,setShowComp] = useState(1)
  return (
    <div className='ManageRoles'>
        <Navbar/>

     <div className='ManageRolesPage_container'>
      <span className='ManageRolesPage_container_header' dir='rtl'>ادارة الصلاحيات</span>
      
      <div className='ManageRolesPage_container_table'>
        <div className='ManageRolesPage_container_tableTopNavigation'>
            <span className={showComp === 1 ? 'ManageRolesPage_container_tableTopNavigation_button active_button_tableTopNavigation_ManageRoles' : 'ManageRolesPage_container_tableTopNavigation_button'} onClick={()=>{setShowComp(1)}} dir='rtl'>اضافة صلاحية جديدة</span>
            <span className={showComp === 2 ? 'ManageRolesPage_container_tableTopNavigation_button right_button_tableManageRoles active_button_tableTopNavigation_ManageRoles': 'ManageRolesPage_container_tableTopNavigation_button right_button_tableManageRoles' }  onClick={()=>{setShowComp(2)}}  dir='rtl'>عرض كل الصلاحيات</span>
        </div>
        <div className='ManageRolesPage_container_tableShowPart'>
          {
           showComp === 1 ? 
           <AddNewRole/>
           :
           <ShowRolesAndDelete/>
          }
         
        </div>
      </div>

     </div>
   </div>
  )
}

export default ManageRoles